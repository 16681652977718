import React, { useEffect, useState } from "react";
import EmailEditorComponent from "./EmailEditor";
import "./EmailFormRenderer.css";
import {
	Button,
	Chip,
	CircularProgress,
	Divider,
	IconButton,
	MenuItem,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { CloseFullscreen, Delete, Fullscreen, Info, Preview, Remove, Save } from "@mui/icons-material";
import { createTrigger, deleteTrigger, getEmailPreview, updateTrigger } from "../../../utilities/eventsApi";
import { toast } from "react-toastify";
import { renderConfirmationDialog } from "../../confirmation/ConfirmationDialog";
import { VARIABLES } from "../constants";
import { useSelector } from "react-redux";
import { CriteriaComponent } from "./CriteriaModalComponent";
import { InputComponent } from "./APIFormRenderer";
import PausePlayAction from "./PausePlayAction";

const EmailFormRenderer = ({
	openingId,
	fields = [],
	actionData,
	setActionData,
	loading = false,
	setLoading = () => {},
	onClickAddAction,
	showDivider = true,
	selectedEventId,
	selectedActionId,
	forCustomEvent = false,
	afterSave = () => {},
	afterUpdate = () => {},
	afterDelete = () => {},
}) => {
	const { selectedOpening } = useSelector((state) => state.openingDetails);

	const [showFullEmailEditor, setShowFullEmailEditor] = useState({
		index: null,
		open: false,
	});

	const [showPreviewModal, setShowPreviewModal] = useState({
		index: null,
		open: false,
	});

	const onClickSaveAction = async (e, index) => {
		let action = actionData[index];
		let column = action.column || "";

		if (selectedEventId == 3 && column == "") {
			toast.error("Column is required");
			return;
		}

		if (action.from === "" || action.to === "" || action.subject === "") {
			toast.error("From, To and Subject are required");
			return;
		}
		let fromEmails = action.from.split(",");
		let ccEmails = action.cc.split(",");
		let isValid = true;
		fromEmails.forEach((email) => {
			if (!validateEmail(email)) {
				isValid = false;
			}
		});
		ccEmails.forEach((email) => {
			email = email.trim();
			if (!validateEmail(email) && email !== "") {
				isValid = false;
				if (["admin", "candidate"].indexOf(email.toLowerCase()) > -1) {
					isValid = true;
				}
			}
		});
		if (!isValid) {
			toast.error("Invalid email address");
			return;
		}
		if (!action.id) {
			let body = {
				openingId: openingId,
				event: selectedEventId,
				action: selectedActionId,
				actionData: JSON.stringify(action),
			};
			try {
				let response = await createTrigger(body);
				if (response && response.data) {
					let apiFormCopy = [...actionData];
					apiFormCopy[index].id = response.data._id;
					setActionData(apiFormCopy);
					toast.success("Action created successfully");
					if (afterSave) afterSave(null, response.data._id);
				} else {
					console.log(response);
					toast.error("Error creating action");
				}
			} catch (error) {
				console.log(error);
				toast.error("Error creating action");
			}
		} else {
			let body = {
				triggerId: action.id,
				actionData: JSON.stringify(action),
			};
			try {
				let response = await updateTrigger(body);
				if (response && response.data) {
					toast.success("Action updated successfully");
					if (afterUpdate) afterUpdate();
				} else {
					toast.error("Error updating action");
				}
			} catch (error) {
				toast.error("Error updating action");
			}
		}
	};

	const deleteAction = async (e, apiIndex) => {
		let action = actionData[apiIndex];
		if (!openingId) {
			toast.error("Opening Id is required");
			return;
		}
		let actionId = action.id;
		let apiFormCopy = [...actionData];
		apiFormCopy.splice(apiIndex, 1);
		setActionData(apiFormCopy);

		if (actionId && actionId !== "") {
			try {
				let body = {
					triggerId: actionId,
				};
				let response = await deleteTrigger(body);
				if (response) {
					toast.success("Action deleted successfully");
					if (afterDelete) afterDelete(null, actionId);
				} else {
					toast.error("Error deleting action");
					let apiFormCopy = [...actionData];
					setActionData(apiFormCopy);
				}
			} catch (error) {
				let apiFormCopy = [...actionData];
				setActionData(apiFormCopy);
				toast.error("Error deleting action");
			}
		}
	};

	const validateEmail = (email) => {
		let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailPattern.test(email);
	};

	const onFieldsValueChange = (e, index, field) => {
		let newActionData = [...actionData];
		newActionData[index][field] = e.target.value;
		setActionData(newActionData);
	};

	const showPreview = (e, index) => {
		setShowPreviewModal({
			index: index,
			open: true,
		});
	};

	const InfoRenderer = () => {
		return (
			<div className="pl-3 text-sm">
				<ol>
					<li>{"To use a column just wrap the column name in '<=' and '=>'"}.</li>
					<li>{"Example: <=Interview date=>"}.</li>
					<li>
						Available variables:{" "}
						{[...VARIABLES, ...fields].map((el, index) => {
							return (
								<span key={index}>
									{" "}
									{el.label ? el.label : el.value}
									{index === fields.length - 1 ? "." : ","}{" "}
								</span>
							);
						})}
					</li>
					<li>
						Keep in mind the column names are case sensitive. Make sure to spell them correctly
						like given in the available variables/columns list.
					</li>
				</ol>
			</div>
		);
	};

	return (
		<>
			<div className="flex-1 overflow-y-auto rounded-md overflow-hidden my-3">
				{showPreviewModal.open && (
					<PreviewModal
						open={showPreviewModal.open}
						onClose={() =>
							setShowPreviewModal({
								index: null,
								open: false,
							})
						}
						action={actionData[showPreviewModal.index]}
						openingId={openingId}
					/>
				)}
				{actionData.length > 0 &&
					actionData.map((action, index) => {
						return (
							<>
								{showDivider && (
									<Divider>
										<Chip
											size="small"
											label={+index + 1}
											color="primary"
											variant="outlined"
										/>
									</Divider>
								)}
								<div className="flex gap-2 align-bottom justify-between items-end mb-4 mx-1">
									{selectedEventId == 3 && (
										<div>
											<Typography
												id="modal-modal-title"
												style={{
													fontSize: ".8rem",
												}}
												className="text-gray-500 ml-1"
											>
												Column
											</Typography>
											<InputComponent
												value={actionData[index].column || ""}
												fields={fields.filter(
													(el) =>
														el.type === "report" &&
														!el.disabled &&
														el.enabledFor.includes("eventType3"),
												)}
												onChange={(e) => {
													let actionCopy = [...actionData];
													actionCopy[index].column = e.target.value;
													setActionData(actionCopy);
												}}
												type={"Column"}
												key={index}
												styles={{ width: "12ch" }}
											/>
										</div>
									)}
									<div className="flex-1"></div>
									<CriteriaComponent
										action={action}
										fields={fields}
										onCriteriaSave={(data) => {
											let actionCopy = [...actionData];
											actionCopy[index].criteria = data;
											setActionData(actionCopy);
											onClickSaveAction(null, index);
										}}
									/>
								</div>
								{showFullEmailEditor.open && showFullEmailEditor.index == index && (
									<FullEmailEditorModal
										open={showFullEmailEditor.open}
										onClose={() =>
											setShowFullEmailEditor({
												index: null,
												open: false,
											})
										}
										action={action}
										index={index}
										setActionData={setActionData}
										InfoRenderer={InfoRenderer}
										showPreview={showPreview}
										onClickSaveAction={onClickSaveAction}
									/>
								)}
								<div className="flex flex-col m-1 gap-2">
									<TextField
										size="small"
										label="From"
										variant="outlined"
										fullWidth
										disabled
										value={action.from}
										onChange={(e) => onFieldsValueChange(e, index, "from")}
									/>
									<Tooltip
										// title="Write 'Admin' or 'Candidate' to use their email. Multipe emails should be comma separated"
										placement="bottom-start"
									>
										<TextField
											size="small"
											label="To"
											select
											variant="outlined"
											fullWidth
											value={action.to}
											onChange={(e) => onFieldsValueChange(e, index, "to")}
										>
											<MenuItem value="Admin">Admin</MenuItem>
											<MenuItem value="Candidate">Candidate</MenuItem>
											{selectedOpening?.supportEmail &&
												selectedOpening?.supportEmail !== "" && (
													<MenuItem value={selectedOpening.supportEmail}>
														Support Email
													</MenuItem>
												)}
										</TextField>
									</Tooltip>
									<Tooltip
										title="Write 'Admin' or 'Candidate' to use their email. Multipe emails should be comma separated"
										placement="bottom-start"
									>
										<TextField
											size="small"
											label="Cc"
											variant="outlined"
											fullWidth
											value={action.cc}
											onChange={(e) => onFieldsValueChange(e, index, "cc")}
										/>
									</Tooltip>

									<TextField
										size="small"
										label="Subject"
										variant="outlined"
										fullWidth
										value={action.subject}
										onChange={(e) => onFieldsValueChange(e, index, "subject")}
									/>
								</div>
								<div className="m-1 z-20 w-full relative pt-5">
									<div className="flex justify-end w-full gap-1 -top-3 pr-4 absolute align-middle">
										<span className="scale-75">
											<Tooltip title="Preview">
												<IconButton
													className="bg-whitecursor-pointer bg-orange-300"
													onClick={(e) => showPreview(e, index)}
												>
													<Preview />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<PausePlayAction
												action={action}
												index={index}
												setActionData={setActionData}
												allActions={actionData}
												icon={true}
											/>
										</span>
										<span className="scale-75">
											<Tooltip title="Save">
												<IconButton
													className="bg-whitecursor-pointer bg-green-400"
													onClick={(e) => {
														onClickSaveAction(e, index);
													}}
												>
													<Save />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title={<InfoRenderer />}>
												<IconButton className="bg-gray-200 cursor-pointer">
													<Info />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title="Maximize">
												<IconButton
													className="bg-sky-100 cursor-pointer"
													onClick={() => {
														setShowFullEmailEditor({
															index: index,
															open: true,
														});
													}}
												>
													<Fullscreen />
												</IconButton>
											</Tooltip>
										</span>
										<span className="scale-75">
											<Tooltip title="Delete">
												<IconButton
													className="bg-whitecursor-pointer text-white bg-red-400"
													onClick={(e) => {
														renderConfirmationDialog(
															"Are you sure you want to delete this action?",
															() => {},
															() => {
																deleteAction(e, index);
															},
															true,
														);
													}}
												>
													<Delete />
												</IconButton>
											</Tooltip>
										</span>
									</div>
									{showFullEmailEditor.open == false && (
										<EmailEditorComponent
											action={action}
											index={index}
											setActionData={setActionData}
											style={{
												border: "1px solid #E5E7EB",
												maxWidth: "99%",
												borderRadius: "5px",
											}}
										/>
									)}
								</div>
								<div className="relative flex w-full mb-10">
									<div className="flex justify-end items-center w-full gap-2 m-1 pr-3 hidden">
										<PausePlayAction
											action={action}
											index={index}
											setActionData={setActionData}
											allActions={actionData}
											icon={false}
										/>
										<Button
											variant="outlined"
											onClick={(e) => onClickSaveAction(e, index)}
										>
											{action.id ? "Update" : "Save"}
										</Button>
										<Button
											variant="outlined"
											color="warning"
											onClick={(e) => showPreview(e, index)}
										>
											Preview
										</Button>
										<Button
											variant="outlined"
											onClick={(e) => {
												renderConfirmationDialog(
													"Are you sure you want to delete this action?",
													() => {},
													() => {
														deleteAction(e, index);
													},
													true,
												);
											}}
											color="error"
										>
											Delete
										</Button>
									</div>
								</div>
							</>
						);
					})}
			</div>
		</>
	);
};

const FullEmailEditorModal = ({
	open,
	onClose,
	action,
	index,
	setActionData,
	InfoRenderer,
	showPreview = () => {},
	onClickSaveAction = () => {},
}) => {
	let icons = [
		{
			title: "Preview",
			onClick: (e) => showPreview(e, index),
			icon: <Preview />,
			className: "bg-orange-300",
		},
		{
			title: "Save",
			onClick: (e) => {
				onClickSaveAction(e, index);
				onClose();
			},
			icon: <Save />,
			className: "bg-green-600",
		},
		{
			title: <InfoRenderer />,
			onClick: () => {},
			icon: <Info />,
			className: "bg-white",
		},
		{
			title: "Minimize",
			onClick: onClose,
			icon: <CloseFullscreen />,
			className: "bg-gray-300",
		},
	];

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					height: "80vh",
					bgcolor: "background.paper",
					boxShadow: 24,
					backgroundColor: "white",
					borderRadius: "10px",
				}}
			>
				<div className="absolute flex flex-row justify-end items-center w-full -top-[2.4rem] gap-3">
					{icons.map((el, index) => {
						return (
							<Tooltip key={index} title={el.title}>
								<IconButton
									size="small"
									className={`scale-90 cursor-pointer ${el.className}`}
									onClick={el.onClick}
								>
									{el.icon}
								</IconButton>
							</Tooltip>
						);
					})}
				</div>
				<div className="py-2 px-3 rounded-md overflow-hidden overflow-y-auto flex max-h-[100%]">
					<EmailEditorComponent
						action={action}
						index={index}
						setActionData={setActionData}
						suffix="full"
						style={{ border: "none" }}
					/>
				</div>
			</div>
		</Modal>
	);
};

const PreviewModal = ({ open, onClose, action, openingId }) => {
	const [state, setState] = useState({
		loading: true,
		htmlTemplateString: "",
	});

	const { selectedCandidate } = useSelector((state) => state.candidates);

	const getEmailTemplate = async () => {
		try {
			setState({
				loading: true,
				htmlTemplateString: "",
			});
			let response = await getEmailPreview({
				action,
				openingId,
				candidateId: selectedCandidate?.join(","),
			});
			if (response && response.data) {
				if (response.data?.html?.html) {
					setState({
						loading: false,
						htmlTemplateString: response.data.html.html,
					});
				}
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
			setState({
				loading: false,
				htmlTemplateString: "",
			});
		}
	};

	useEffect(() => {
		getEmailTemplate();
	}, []);

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "60vw",
					bgcolor: "background.paper",
					boxShadow: 24,
					borderRadius: "10px",
					paddingBottom: "1rem",
				}}
				className="bg-white overflow-hidden"
			>
				<div className="w-full flex justify-between items-center bg-gray-600 text-white py-2 px-4">
					<Typography variant="h6">Email Preview</Typography>
					<IconButton size="small" onClick={onClose} className="text-white">
						<CloseFullscreen />
					</IconButton>
				</div>
				<div
					style={{
						maxHeight: "70vh",
						minHeight: "40vh",
						overflow: "auto",
						paddingBottom: "1rem",
					}}
					className="relative h-full w-full"
				>
					{state.loading ? (
						<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
							<CircularProgress />
						</div>
					) : (
						<div
							className="break-words py-2 my-4 mx-4 whitespace-pre-wrap"
							id="preview-modal"
							dangerouslySetInnerHTML={{ __html: state.htmlTemplateString }}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default EmailFormRenderer;
