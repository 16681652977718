import axios from "./axiosInstance";

const createOpening = async (openingData) => {
	try {
		// openingData={...openingData,or}
		const response = await axios.post("/api/openings", {
			data: openingData,
		});
		return response.data.opening;
	} catch (e) {
		console.log("failed creating opening");
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const fetchOpenings = async (organizationId) => {
	try {
		const body = {
			organizationId: organizationId,
		};
		const response = await axios.post("/api/openings/byorganizationID", body);
		if (response.status === 200) {
			return response.data.openings;
		}
	} catch (e) {
		console.log("failed fetching openings");
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const fetchOpenings2 = async (organizationId) => {
	try {
		const response = await axios.get(
			`/api/openings/oc?organizationId=${encodeURIComponent(organizationId || "")}`,
		);
		if (response.status === 200) {
			return {
				openings: response.data.openings,
			};
		}
	} catch (e) {
		console.log("Failed fetching openings");
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const fetchOpeningById = async (openingId) => {
	try {
		if (openingId) {
			const response = await axios.get(`/api/openings/opening/${openingId}`);
			return response.data.opening;
		} else {
			throw new Error("Invalid ID");
		}
	} catch (e) {
		console.log("failed fetching opening by Id", openingId);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const fetchOpeningByIdWithColors = async (openingId) => {
	try {
		if (openingId) {
			const response = await axios.get(`/api/openings/opening/${openingId}/oc`);
			return response.data.opening;
		} else {
			throw new Error("Invalid ID");
		}
	} catch (e) {
		console.log("failed fetching opening by Id colors", openingId);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const getXl = async (openingId) => {
	try {
		if (openingId) {
			const response = await axios({
				method: "post",
				url: `/api/openings/opening/${openingId}/xl`,
				responseType: "blob",
			});
			return response;
		} else {
			throw new Error("Invalid ID or candidate data");
		}
	} catch (e) {
		console.log("failed to create xl file", openingId);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const updateOpeningById = async (openingId, updatedData) => {
	try {
		if (openingId) {
			const response = await axios.put(`/api/openings/${openingId}`, updatedData);
			// need to make better validation and error displays in the UI based on response schema
			return response.data;
		} else {
			throw new Error("Invalid ID");
		}
	} catch (e) {
		console.log("failed updating openings");
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const deleteOpeningById = async (openingId) => {
	try {
		if (openingId) {
			const response = await axios.delete(`/api/openings/${openingId}`);
			return response.data;
		} else {
			throw new Error("Invalid ID");
		}
	} catch (e) {
		console.log("failed deleting opening by Id", openingId);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const getCustomFieldsOfOrganization = async () => {
	try {
		const response = await axios.get("/api/openings/all-custom-fields");
		return response.data;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const updateCustomFieldsOfOpening = async (data) => {
	try {
		const response = await axios.put("/api/openings/update-custom-fields", data);
		return response.data.opening;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const starOpening = async (data) => {
	try {
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await axios.post("/api/user/star", data, config);
		return response.data;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const fetchOpeningByIdForInterview = async (openingId) => {
	try {
		const response = await axios.get(`/api/openings/interview/opening/${openingId}`);
		return response.data.opening;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const createOpeningForSuperAdmin = async (openingData, organizationId) => {
	try {
		const response = await axios.post(
			`/api/superadmin/openings/${organizationId}/opening/create`,
			openingData,
		);
		return response.data.opening;
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const updateOpeningByIdForSuperAdmin = async (openingId, organizationId, updatedData) => {
	try {
		if (openingId) {
			const response = await axios.put(
				`/api/superadmin/openings/${organizationId}/opening/update/${openingId}`,
				updatedData,
			);
			return response.data;
		} else {
			throw new Error("Invalid ID");
		}
	} catch (e) {
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

const duplicateOpeningById = async (openingId) => {
	try {
		if (openingId) {
			const response = await axios.post(`/api/openings/clone/${openingId}`);
			return response.data;
		} else {
			throw new Error("Invalid ID");
		}
	} catch (e) {
		console.log("failed to create duplicate opening by Id", openingId);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
};

export {
	createOpening,
	fetchOpenings,
	fetchOpenings2,
	fetchOpeningById,
	fetchOpeningByIdWithColors,
	getXl,
	deleteOpeningById,
	updateOpeningById,
	getCustomFieldsOfOrganization,
	updateCustomFieldsOfOpening,
	starOpening,
	fetchOpeningByIdForInterview,
	createOpeningForSuperAdmin,
	updateOpeningByIdForSuperAdmin,
	duplicateOpeningById,
};
