import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const CurrentPlanCard = ({ organization, value }) => {
	console.log(value);
	return (
		<Card
			className="bg-white p-4 rounded-lg shadow-lg w-full mx-auto"
			style={{ position: "relative", overflow: "hidden" }}
		>
			{value.type === "Package" && (
				<CardContent>
					<div className="flex justify-between items-center">
						<Typography variant="h5" className="ml-2 text-gray-500">
							{value?.details?.name}
						</Typography>
					</div>
					<Typography className="ml-2 text-gray-500 mt-2">
							<strong>Type: </strong>{value?.type}
					</Typography>
					<Typography className="ml-2 text-gray-500 mt-2">
							<strong>Additional Interview Rate: </strong>{value?.details?.additionalInterviewRate}
					</Typography>
					<Typography className="ml-2 text-gray-500 mt-2">
							<strong>Next Billing Date: </strong> {value?.renewalDate ? new Date(value?.renewalDate).toDateString() : "-"}
					</Typography>
					<Typography className="ml-2 text-gray-500 mt-2">
						<strong>Interview Used: </strong>{value?.details?.interviewsUsed ?? 0} / {value?.details?.interviewsPerQuota ?? 0}
					</Typography>
					<Typography className="ml-2 text-gray-500 mt-2">
						<strong>Additional Interview used: </strong> {value?.details?.additionalInterviewsUsed ?? 0}
					</Typography>
				</CardContent>
			)}
			{value.type === "PayAsYouGo" && (
				<CardContent>
					<div className="flex justify-between items-center">
						<Typography variant="h5" className="ml-2 text-gray-500">
							{value?.details?.name}
						</Typography>
					</div>
					<Typography className="ml-2 text-gray-500 mt-2">
						<strong>Type: </strong>{value?.type}
					</Typography>
					<Typography className="ml-2 text-gray-500 mt-2">
						<strong>Interview Rate: </strong>{organization?.interviewRate ?? 0}
					</Typography>
				</CardContent>
			)}
		</Card>
	);
};

export default CurrentPlanCard;