import axios from "axios";
import store from "./../app/store";
import { logoutUser } from "../features/auth/authSlice";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
	withCredentials: true,
});
axiosInstance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const status = error.response.status;
		const message = error.response?.data?.message || error.message;
		if (status === 401) {
			store.dispatch(logoutUser());
			toast.warn(message);
		} else if (status === 403) {
			// handle forbidden
			toast.warn(message);
			setTimeout(() => {
				window.location = "/admin";
			}, 1000);
		}
		return Promise.reject(error);
	},
);
export default axiosInstance;
