import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Box } from "@mui/material";
import logo from "../assets/zi-favicon.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, reset } from "../features/auth/authSlice";
import { toast } from "react-toastify";

const initialUserData = {
	organizationName: "",
	contactInfo: "",
	userName: "",
	email: "",
	password: "",
	confirmPassword: "",
};

const SignUp = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
	const [userData, setUserData] = useState(initialUserData);
	const [isPwdVisible1, setIsPwdVisible1] = useState(false);
	const [isPwdVisible2, setIsPwdVisible2] = useState(false);

	useEffect(() => {
		if (isError && message) {
			toast.error(message);
		}
		dispatch(reset());
	}, [isError, isSuccess, message, dispatch]);

	useEffect(() => {
		if (user) {
			dispatch(reset());
			navigate("/admin", { replace: true });
		}
	}, [user, navigate, dispatch]);

	const isValidPassword = (password) => {
		const minLength = 6;
		if (password.length < minLength) {
			toast.warn(`Password must be at least ${minLength} characters long.`);
			return false;
		}
		if (password.includes(" ")) {
			toast.warn("Password cannot contain spaces.");
			return false;
		}
		return true;
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// console.log(userData);
		if (isLoading) {
			return;
		}
		const { organizationName, email, userName, password, confirmPassword, contactInfo = "" } = userData;
		const missingFields = {
			organizationName: !organizationName.trim(),
			email: !email.trim(),
			password: !password.trim(),
			userName: !userName.trim(),
			confirmPassword: !confirmPassword.trim(),
		};
		let warnCount = 0;
		for (let missingField in missingFields) {
			if (missingFields[missingField]) {
				toast.warn(`${missingField} can't be empty`);
				warnCount += 1;
			}
		}
		if (warnCount) {
			return;
		}
		if (!isValidEmail(email.trim())) {
			toast.warn(`Invalid Email`);
			return;
		}

		if (!isValidPassword(password) || !isValidPassword(confirmPassword)) {
			return;
		}

		if (confirmPassword.trim() !== password.trim()) {
			toast.error("Passwords don't match");
			return;
		}
		const formData = {
			organizationName,
			contactInfo,
			userName,
			email: email.toLowerCase().trim(),
			password,
		};
		dispatch(registerUser(formData));
	};

	const handleUserDataChange = (e) => {
		const { name, value } = e.target;
		setUserData((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const { organizationName, email, password, confirmPassword, userName, contactInfo } = userData;
	return (
		<Box className="bg-gray-100 h-screen flex flex-col justify-center items-center">
			<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3">
				<Box className="flex justify-center mb-6">
					<img src={logo} alt="Zinterview Logo" className="h-16" />
				</Box>

				<form>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box className="mb-4">
								<label htmlFor="org-name" className="block text-sm font-medium text-gray-600">
									Organization Name
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									color="black"
									autoComplete="email"
									fullWidth
									size="small"
									required
									type="text"
									id="organization-name"
									className="mt-1 w-full border rounded-md"
									name="organizationName"
									value={organizationName}
									onChange={handleUserDataChange}
									disabled={isLoading}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box className="mb-4">
								<label htmlFor="username" className="block text-sm font-medium text-gray-600">
									Username
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									autoComplete="username"
									required
									fullWidth
									id="username"
									name="userName"
									value={userName}
									onChange={handleUserDataChange}
									disabled={isLoading}
									color="black"
									size="small"
									type="text"
									className="mt-1 w-full border rounded-md"
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box className="mb-4">
								<label htmlFor="phone" className="block text-sm font-medium text-gray-600">
									Phone Number
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									autoComplete="phone-number"
									fullWidth
									id="contact-info"
									name="contactInfo"
									value={contactInfo}
									onChange={handleUserDataChange}
									disabled={isLoading}
									required
									color="black"
									size="small"
									type="text"
									className="mt-1 w-full border rounded-md"
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box className="mb-4">
								<label htmlFor="email" className="block text-sm font-medium text-gray-600">
									Email Address
								</label>
								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									required
									fullWidth
									id="email"
									autoComplete="email-address"
									name="email"
									value={email}
									onChange={handleUserDataChange}
									disabled={isLoading}
									color="black"
									size="small"
									type="text"
									className="mt-1 w-full border rounded-md"
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box className="relative mb-4">
								<label htmlFor="password" className="block text-sm font-medium text-gray-600">
									Password
								</label>

								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									required
									fullWidth
									type={isPwdVisible1 ? "text" : "password"}
									id="password"
									name="password"
									value={password}
									onChange={handleUserDataChange}
									disabled={isLoading}
									size="small"
									color="black"
									margin="normal"
									autoComplete="password"
									className="mt-1 w-full border rounded-md"
								/>

								<Box
									className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
									onClick={() => setIsPwdVisible1((prev) => !prev)}
								>
									{isPwdVisible1 ? (
										<i className="fas fa-eye-slash"></i>
									) : (
										<i className="fas fa-eye"></i>
									)}
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box className="relative mb-4">
								<label htmlFor="password" className="block text-sm font-medium text-gray-600">
									Confirm Password
								</label>

								<TextField
									sx={{
										"& fieldset": {
											border: "1px solid #F2F3F5",
										},
									}}
									required
									fullWidth
									type={isPwdVisible2 ? "text" : "password"}
									id="confirm-password"
									name="confirmPassword"
									value={confirmPassword}
									onChange={handleUserDataChange}
									disabled={isLoading}
									size="small"
									color="black"
									margin="normal"
									autoComplete="password"
									className="mt-1 w-full border rounded-md"
								/>

								<Box
									className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
									onClick={() => setIsPwdVisible2((prev) => !prev)}
								>
									{isPwdVisible2 ? (
										<i className="fas fa-eye-slash"></i>
									) : (
										<i className="fas fa-eye"></i>
									)}
								</Box>
							</Box>
						</Grid>
					</Grid>
					<Box
						onClick={handleSubmit}
						className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
							isLoading && "cursor-not-allowed opacity-50"
						}`}
						type="submit"
						fullWidth
						disabled={isLoading}
						component={Button}
					>
						{isLoading ? (
							<span>
								{"Registering User... "}
								<i className="fas fa-circle-notch fa-spin mr-1"></i>
							</span>
						) : (
							<span>Sign Up</span>
						)}
					</Box>
					<Box className="flex justify-center items-center mb-4 p-2">
						<NavLink to={"/login"} className="text-sm text-blue-500 no-underline hover:underline">
							{"Already have an account? Log in"}
						</NavLink>
					</Box>
				</form>
			</Box>
		</Box>
	);
};

export default SignUp;
