import * as React from "react";
import {
	Button,
	Divider,
	FormControl,
	IconButton,
	MenuItem,
	TextField,
	Typography,
	Select,
	Chip,
	Tooltip,
	Checkbox,
	Modal,
} from "@mui/material";
import { useState } from "react";
import { Add, Cancel, Info, Remove } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { JsonEditor } from "json-edit-react";
import { RESPONSE_HANDLER_FORM_FORMAT, SYSTEM_COLUMNS, MIDDLE_OPERATOR_TYPES, VARIABLES } from "../constants";

import { createTrigger, deleteTrigger, updateTrigger } from "../../../utilities/eventsApi";
import { renderConfirmationDialog } from "../../confirmation/ConfirmationDialog";
import { CriteriaComponent } from "./CriteriaModalComponent";
import PausePlayAction from "./PausePlayAction";

const APIFormRenderer = ({
	apiForm,
	setApiForm,
	loading,
	setLoading,
	selectedEventId,
	selectedActionId,
	fields,
	customFields,
	showDivider = true,
	handleResponse = false,
	disabledOperatorTypes = [],
	openingId,
	forCustomEvent = false,
	afterSave = () => null,
	afterDelete = () => null,
	afterUpdate = () => null,
}) => {
	const [openResponseHandlerModal, setOpenResponseHandlerModal] = useState({
		state: false,
		index: null,
	});

	const saveResponseHandler = async (responseHandler, index) => {
		try {
			let apiFormCopy = [...apiForm];
			apiFormCopy[index].processResponse = true;
			apiFormCopy[index].responseFormat = responseHandler;
			setApiForm(apiFormCopy);
			saveAction({ preventDefault: () => {} }, index);
		} catch (error) {
			toast.error("Error saving response handler");
		}
	};

	const saveAction = async (e, actionIndex) => {
		e?.preventDefault();
		setLoading({
			state: actionIndex,
			status: true,
		});

		let action = apiForm[actionIndex];
		if (!openingId) {
			toast.error("Opening Id is required");
			return;
		}

		if (selectedEventId == 3 && !action.column) {
			toast.error("Column is required");
			setLoading({
				state: "",
				status: false,
			});
			return;
		}

		if (action.name === "" || action.url === "" || action.method === "") {
			toast.error("All fields are required");
			setLoading({
				state: "",
				status: false,
			});
			return;
		}

		// if custom column names are changed

		// let mapCustomFieldsToId = customFields.reduce((acc, el) => {
		// 	acc[el.fieldName] = el._id;
		// 	return acc;
		// }, {});

		// ["headers", "body", "params"].forEach((key) => {
		// 	for (let i = 0; i < action.headers.length; i++) {
		// 		if (
		// 			action[key][i].type === "Column" &&
		// 			mapCustomFieldsToId[action[key][i].value] !== undefined
		// 		) {
		// 			action[key][i].value = mapCustomFieldsToId[action[key][i].value];
		// 		}
		// 	}
		// });

		if (action.id !== "") {
			let body = {
				triggerId: action.id,
				actionData: JSON.stringify(action),
			};

			try {
				let response = await updateTrigger(body);
				if (response && response.data) {
					toast.success("Action updated successfully");
					if (afterUpdate) afterUpdate();
				} else {
					toast.error("Error updating action");
				}
			} catch (error) {
				toast.error("Error updating action");
			} finally {
				setLoading({
					state: "",
					status: false,
				});
			}
		} else {
			let body = {
				openingId: openingId,
				event: selectedEventId,
				action: selectedActionId,
				actionData: JSON.stringify(action),
			};

			try {
				let response = await createTrigger(body);
				if (response && response.data) {
					let apiFormCopy = [...apiForm];
					apiFormCopy[actionIndex].id = response.data._id;
					setApiForm(apiFormCopy);
					toast.success("Action created successfully");
					if (afterSave) afterSave();
				} else {
					console.log(response);
					toast.error("Error creating action");
				}
			} catch (error) {
				console.log(error);
				toast.error("Error creating action");
			} finally {
				setLoading({
					state: "",
					status: false,
				});
			}
		}
	};

	const deleteAction = async (e, apiIndex) => {
		let action = apiForm[apiIndex];
		if (!openingId) {
			toast.error("Opening Id is required");
			return;
		}
		let actionId = action.id;
		let apiFormCopy = [...apiForm];
		apiFormCopy.splice(apiIndex, 1);
		setApiForm(apiFormCopy);

		if (actionId !== "") {
			try {
				let body = {
					triggerId: actionId,
				};
				let response = await deleteTrigger(body);
				if (response) {
					toast.success("Action deleted successfully");
					if (afterDelete) afterDelete();
				} else {
					toast.error("Error deleting action");
					let apiFormCopy = [...apiForm];
					setApiForm(apiFormCopy);
				}
			} catch (error) {
				let apiFormCopy = [...apiForm];
				setApiForm(apiFormCopy);
				toast.error("Error deleting action");
			}
		}
	};

	const InfoRenderer = () => {
		return (
			<div className="pl-3 text-sm">
				<ol>
					<li>Use JSON editor mode to add complex nested data.</li>
					<li>{"To use a column just wrap the column name in '<=' and '=>'"}.</li>
					<li>{"Example: <=Interview date=>"}.</li>
					<li>
						Available variables:{" "}
						{[...VARIABLES, ...fields].map((el, index) => {
							return (
								<span key={index}>
									{" "}
									{el.label ? el.label : el.value}
									{index === fields.length - 1 ? "." : ","}{" "}
								</span>
							);
						})}
					</li>
				</ol>
			</div>
		);
	};

	const Header = ({ label, onClickIcon, apiIndex = null, showAdd = true }) => {
		return (
			<div className="flex justify-between items-baseline mt-2">
				<Typography
					id="modal-modal-title"
					style={{
						fontSize: ".8rem",
					}}
				>
					{label}
				</Typography>
				<div className="flex justify-center items-center">
					{label === "Body" && (
						<>
							{apiForm[apiIndex].bodyType == "json" && (
								<Tooltip title={<InfoRenderer />}>
									<Info className="text-gray-500" />
								</Tooltip>
							)}
							<Tooltip
								title={
									apiForm[apiIndex]?.bodyType == "rows"
										? "Toggle body editor to JSON for more complex data"
										: "Toggle body editor to ROWS for normal key value pairs"
								}
							>
								<Button
									className="self-start text-sm"
									onClick={(e) => {
										let apiFormCopy = [...apiForm];
										if (apiFormCopy[apiIndex]?.bodyType) {
											apiFormCopy[apiIndex].bodyType =
												apiFormCopy[apiIndex].bodyType === "rows" ? "json" : "rows";
										} else {
											apiFormCopy[apiIndex].bodyType = "JSON";
										}
										setApiForm(apiFormCopy);
									}}
								>
									{apiForm[apiIndex]?.bodyType
										? apiForm[apiIndex]?.bodyType == "rows"
											? "JSON"
											: "ROWS"
										: "ROWS"}
								</Button>
							</Tooltip>
						</>
					)}
					{showAdd && (
						<IconButton
							size="small"
							onClick={onClickIcon}
							disabled={label === "Body" && apiForm[apiIndex]?.bodyType === "json"}
						>
							<Add
								color={
									label === "Body" && apiForm[apiIndex]?.bodyType === "json"
										? "disabled"
										: "primary"
								}
								size="small"
								className="text-base"
							/>
						</IconButton>
					)}
				</div>
			</div>
		);
	};

	const updateRowsInputs = (e, parent, apiIndex, index, key) => {
		let data = apiForm[apiIndex][parent];
		data[index][key] = e.target.value;
		let apiFormCopy = [...apiForm];
		apiFormCopy[apiIndex][parent] = data;
		setApiForm(apiFormCopy);
	};

	const onRowRemove = (e, parent, apiIndex, index) => {
		let data = apiForm[apiIndex][parent];
		data.splice(index, 1);
		let apiFormCopy = [...apiForm];
		apiFormCopy[apiIndex][parent] = data;
		setApiForm(apiFormCopy);
	};

	const onClickIcon = (e, parent, apiIndex) => {
		e.preventDefault();
		let data = apiForm[apiIndex][parent];
		data.push({ key: "", value: "", type: "Text" });
		let apiFormCopy = [...apiForm];
		apiFormCopy[apiIndex][parent] = data;
		setApiForm(apiFormCopy);
	};

	const updateJsonData = (e, data, apiIndex) => {
		let apiFormCopy = [...apiForm];
		apiFormCopy[apiIndex].bodyJSON = data;
		setApiForm(apiFormCopy);
	};

	return (
		<div className="height-[100%] overflow-auto w-full">
			{apiForm.map((form, apiIndex) => {
				if (!form) return null;
				return (
					<form
						key={apiIndex}
						onSubmit={(e) => {
							e.preventDefault();
							// console.log(apiForm);
						}}
						className="pr-2.5 pt-2 mt-4"
					>
						{showDivider && (
							<Divider className="mb-4">
								<Chip size="small" label={+apiIndex + 1} color="primary" variant="outlined" />
							</Divider>
						)}
						<div className="flex gap-2 align-bottom justify-between items-end mt-2 mb-4">
							{selectedEventId == 3 && (
								<div>
									<Typography
										id="modal-modal-title"
										style={{
											fontSize: ".8rem",
										}}
										className="text-gray-500 ml-1"
									>
										Column
									</Typography>
									<InputComponent
										value={apiForm[apiIndex].column || ""}
										fields={fields.filter(
											(el) =>
												el.type === "report" &&
												!el.disabled &&
												el.enabledFor.includes("eventType3"),
										)}
										onChange={(e) => {
											let actionCopy = [...apiForm];
											actionCopy[apiIndex].column = e.target.value;
											setApiForm(actionCopy);
										}}
										type={"Column"}
										key={apiIndex}
										styles={{ width: "12rem" }}
										placeholder=""
										label=""
									/>
								</div>
							)}
							<div className="flex-1"></div>
							<CriteriaComponent
								action={form}
								fields={fields}
								onCriteriaSave={(data) => {
									let actionCopy = [...apiForm];
									actionCopy[apiIndex].criteria = data;
									setApiForm(actionCopy);
									saveAction(null, apiIndex);
								}}
							/>
						</div>
						<div
							style={{
								display: "flex",
							}}
						>
							<FormControl>
								<Select
									size="small"
									value={apiForm[apiIndex].method}
									defaultValue="GET"
									style={{ width: "12ch" }}
									className="mr-1"
									onChange={(e) => {
										let apiFormCopy = [...apiForm];
										apiFormCopy[apiIndex].method = e.target.value;
										setApiForm(apiFormCopy);
									}}
								>
									<MenuItem value={"GET"}>GET</MenuItem>
									<MenuItem value={"POST"}>POST</MenuItem>
									<MenuItem value={"PUT"}>PUT</MenuItem>
									<MenuItem value={"DELETE"}>DELETE</MenuItem>
								</Select>
							</FormControl>
							<TextField
								size="small"
								type="text"
								placeholder="URL"
								name="url"
								value={apiForm[apiIndex].url}
								className="flex-1"
								onChange={(e) => {
									let apiFormCopy = [...apiForm];
									apiFormCopy[apiIndex].url = e.target.value;
									setApiForm(apiFormCopy);
								}}
							/>
						</div>

						<div>
							<Header
								label="Headers"
								onClickIcon={(e) => {
									onClickIcon(e, "headers", apiIndex);
								}}
								apiIndex={apiIndex}
							/>
							{apiForm[apiIndex].headers?.map((key, index) => {
								return (
									<div key={index} className="pt-1">
										<CriteriaRow
											parent="headers"
											index={index}
											fields={fields}
											onLHSChange={(e) => {
												updateRowsInputs(e, "headers", apiIndex, index, "key");
											}}
											onMiddleChange={(e) => {
												updateRowsInputs(e, "headers", apiIndex, index, "type");
											}}
											onRHSChange={(e) => {
												updateRowsInputs(e, "headers", apiIndex, index, "value");
											}}
											onRowRemove={(e) => {
												onRowRemove(e, "headers", apiIndex, index);
											}}
											setApiForm={setApiForm}
											apiForm={apiForm}
											apiFormIndex={apiIndex}
											disabledOperatorTypes={disabledOperatorTypes}
										/>
									</div>
								);
							})}
						</div>
						<div>
							<Header
								label="Body"
								onClickIcon={(e) => {
									onClickIcon(e, "body", apiIndex);
								}}
								apiIndex={apiIndex}
							/>
							{(apiForm[apiIndex].bodyType === "rows" || !apiForm[apiIndex].bodyType) &&
								apiForm[apiIndex].body?.map((key, index) => {
									return (
										<div key={index}>
											<CriteriaRow
												parent="body"
												index={index}
												fields={fields}
												onLHSChange={(e) => {
													updateRowsInputs(e, "body", apiIndex, index, "key");
												}}
												onMiddleChange={(e) => {
													updateRowsInputs(e, "body", apiIndex, index, "type");
												}}
												onRHSChange={(e) => {
													updateRowsInputs(e, "body", apiIndex, index, "value");
												}}
												onRowRemove={(e) => {
													onRowRemove(e, "body", apiIndex, index);
												}}
												setApiForm={setApiForm}
												apiForm={apiForm}
												apiFormIndex={apiIndex}
												disabledOperatorTypes={disabledOperatorTypes}
											/>
										</div>
									);
								})}
							{apiForm[apiIndex].bodyType === "json" && (
								<div>
									<JsonEditor
										data={apiForm[apiIndex].bodyJSON}
										setData={(data) => updateJsonData("", data, apiIndex)} // optional
										className="max-h-[60vh] overflow-y-auto"
										rootFontSize={14}
										restrictDrag={false}
									/>
								</div>
							)}
							<div>
								<Typography variant="caption" className="text-gray-500 mt-2">
									Convert body to formData
								</Typography>
								<Checkbox
									size="small"
									checked={apiForm[apiIndex].convertToFormData}
									onClick={() => {
										let apiFormCopy = [...apiForm];
										apiFormCopy[apiIndex].convertToFormData =
											!apiFormCopy[apiIndex].convertToFormData;
										setApiForm(apiFormCopy);
									}}
								/>
							</div>
						</div>
						<div>
							<Header
								label="Query Params"
								onClickIcon={(e) => {
									onClickIcon(e, "params", apiIndex);
								}}
							/>
							{apiForm[apiIndex].params?.map((key, index) => {
								return (
									<div key={index}>
										<CriteriaRow
											parent="params"
											index={index}
											fields={fields}
											onLHSChange={(e) => {
												updateRowsInputs(e, "params", apiIndex, index, "key");
											}}
											onMiddleChange={(e) => {
												updateRowsInputs(e, "params", apiIndex, index, "type");
											}}
											onRHSChange={(e) => {
												updateRowsInputs(e, "params", apiIndex, index, "value");
											}}
											onRowRemove={(e) => {
												onRowRemove(e, "params", apiIndex, index);
											}}
											setApiForm={setApiForm}
											apiForm={apiForm}
											apiFormIndex={apiIndex}
											disabledOperatorTypes={disabledOperatorTypes}
										/>
									</div>
								);
							})}
						</div>
						<div className="flex justify-between items-center mt-4 gap-3">
							<TextField
								size="small"
								type="text"
								placeholder="Action Name"
								value={apiForm[apiIndex].name}
								onChange={(e) => {
									let apiFormCopy = [...apiForm];
									apiFormCopy[apiIndex].name = e.target.value;
									setApiForm(apiFormCopy);
								}}
								className="flex-1"
							/>
							<PausePlayAction
								action={form}
								setActionData={setApiForm}
								index={apiIndex}
								allActions={apiForm}
							/>
							<Button
								variant="outlined"
								color="warning"
								onClick={(e) => {
									renderConfirmationDialog(
										"Are you sure you want to delete this action?",
										() => {},
										() => deleteAction(e, apiIndex),
										true,
									);
								}}
								disabled={loading.status && loading.state == apiIndex}
							>
								Delete
							</Button>
							<Button
								variant="outlined"
								color="primary"
								onClick={(e) => {
									saveAction(e, apiIndex);
								}}
								disabled={loading.status && loading.state == apiIndex}
							>
								{apiForm[apiIndex].id ? "Update" : "Save"}
							</Button>
							{handleResponse && (
								<Button
									variant="outlined"
									color="info"
									disabled={apiForm[apiIndex].id ? false : true}
									onClick={() => {
										setOpenResponseHandlerModal({
											state: true,
											index: apiIndex,
										});
									}}
								>
									Handle Response
								</Button>
							)}
							{handleResponse &&
								openResponseHandlerModal.state &&
								openResponseHandlerModal.index == apiIndex && (
									<ResponseHanlderModal
										open={openResponseHandlerModal.state}
										onClose={() => {
											setOpenResponseHandlerModal({
												state: false,
												index: null,
											});
										}}
										customFields={customFields}
										responseForm={
											apiForm[apiIndex].responseFormat
												? apiForm[apiIndex].responseFormat
												: null
										}
										saveResponseHandler={saveResponseHandler}
										index={apiIndex}
									/>
								)}
						</div>
					</form>
				);
			})}
		</div>
	);
};

export const ResponseHanlderModal = ({
	open,
	onClose,
	customFields,
	responseForm = null,
	saveResponseHandler,
	index,
}) => {
	const columns = [...SYSTEM_COLUMNS];
	const [jsonEditorState, setJsonEditorState] = useState({});

	customFields.forEach((el, index) => {
		// let found = columns.find((clm) => el.label == clm.fieldName);
		// if (found) return;
		columns.push({
			required: false,
			label: el.fieldName,
			position: index + 8,
			name: el._id,
			type: "Text",
		});
	});

	columns.sort((a, b) => {
		return a.position - b.position;
	});

	const [responseHandlerFormat, setResponseHandlerFormat] = useState(
		responseForm ? responseForm : { ...RESPONSE_HANDLER_FORM_FORMAT },
	);

	const validateFields = () => {
		for (let key in responseHandlerFormat.keyValuePairs) {
			if (
				responseHandlerFormat.keyValuePairs[key].rhs === "" &&
				columns.find((el) => el.name === key).required
			) {
				toast.error("First name , Last name and Email are required");
				return false;
			}
		}

		return true;
	};

	const onSave = () => {
		if (validateFields()) {
			saveResponseHandler(responseHandlerFormat, index);
			onClose();
		}
	};

	// const steps = ["JSON Parse", "Go into object"];

	const handleClose = () => {
		onClose();
	};

	const onFieldValueChange = (e, el, parent) => {
		let responseHandlerFormatCopy = { ...responseHandlerFormat };
		if (!responseHandlerFormatCopy.keyValuePairs[el.name]) {
			responseHandlerFormatCopy.keyValuePairs[el.name] = {
				operator: "",
				rhs: "",
			};
		}
		responseHandlerFormatCopy.keyValuePairs[el.name][parent] = e.target.value;
		setResponseHandlerFormat(responseHandlerFormatCopy);
	};

	const processEditorData = () => {
		let CandidateWrapper = responseHandlerFormat.type === "ARRAY" ? [] : {};
		let candidateObj = {};
		for (let key in responseHandlerFormat.keyValuePairs) {
			if (responseHandlerFormat.keyValuePairs[key].operator === "Key") {
				let splitKey = responseHandlerFormat.keyValuePairs[key].rhs.split(".");
				splitKey = splitKey.reverse();
				let val = "";
				if (splitKey.length == 1) {
					candidateObj[key] = responseHandlerFormat.keyValuePairs[key].rhs;
				} else {
					val = "VALUE";
					for (let i = 0; i < splitKey.length; i++) {
						if (splitKey[i] === "") continue;
						if (splitKey[i] == 0) {
							val = [val];
						} else {
							val = { [splitKey[i]]: val };
						}
					}
					if (candidateObj[key]) {
						candidateObj[key] = { ...candidateObj[key], ...val };
					} else {
						candidateObj[key] = val;
					}
				}
			}
		}
		let tempCandidate = {};
		for (let key in candidateObj) {
			if (typeof candidateObj[key] === "string" && candidateObj[key] !== "") {
				tempCandidate[candidateObj[key]] = "VALUE";
			} else if (typeof candidateObj[key] === "object") {
				for (let k in candidateObj[key]) {
					if (tempCandidate[k]) {
						tempCandidate[k] = { ...tempCandidate[k], ...candidateObj[key][k] };
					} else {
						tempCandidate[k] = candidateObj[key][k];
					}
				}
			}
		}
		if (responseHandlerFormat.type === "ARRAY") {
			tempCandidate = [tempCandidate];
		}

		let location = responseHandlerFormat.location.split(".");
		location = location.reverse();
		let finalObj = tempCandidate;
		for (let i = 0; i < location.length; i++) {
			if (location[i] === "") continue;
			if (location[i] == 0) {
				finalObj = [finalObj];
				continue;
			}
			finalObj = { [location[i]]: finalObj };
		}
		finalObj = { ...finalObj, ...CandidateWrapper };
		setJsonEditorState(finalObj);
	};

	useEffect(() => {
		processEditorData();
	}, [responseHandlerFormat]);

	if (!open) return null;

	return (
		<Modal open={open} onClose={handleClose}>
			<div
				className="bg-white p-6 rounded-lg shadow-lg flex"
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "80vw",
					minHeight: "50vh",
					maxHeight: "80vh",
					bgcolor: "background.paper",
					boxShadow: 24,
				}}
			>
				<div className="flex align-middle justify-end pl-2 absolute top-[-2.2rem] w-full left-0 rounded">
					<IconButton onClick={handleClose} className="text-white cursor-pointer">
						<Cancel />
					</IconButton>
				</div>
				<div className="flex flex-col w-full">
					<Typography variant="h4 my-1">Response Handler</Typography>
					<Divider className="m-2" />
					<div className="flex justify-start items-center my-2">
						<Typography className="m-2">Candidates Response Type</Typography>
						<Select
							size="small"
							value={responseHandlerFormat.type}
							style={{ width: "12ch" }}
							className="mr-1"
							onChange={(e) => {
								let responseHandlerFormatCopy = { ...responseHandlerFormat };
								responseHandlerFormatCopy.type = e.target.value;
								setResponseHandlerFormat(responseHandlerFormatCopy);
							}}
						>
							<MenuItem value="ARRAY">ARRAY</MenuItem>
							<MenuItem value="OBJECT">OBJECT</MenuItem>
						</Select>
						<div className="m-2">
							{responseHandlerFormat.type == "ARRAY" ? "  array path " : "object path"}
						</div>
						<TextField
							size="small"
							type="text"
							placeholder="Location"
							value={responseHandlerFormat.location}
							onChange={(e) => {
								let responseHandlerFormatCopy = { ...responseHandlerFormat };
								responseHandlerFormatCopy.location = e.target.value;
								setResponseHandlerFormat(responseHandlerFormatCopy);
							}}
						/>
					</div>
					<div className="flex w-full overflow-auto lg:flex-row md:flex-col sm:flex-col my-2">
						<div className="flex-1" id="response_form">
							<div className="mb-2 font-bold">Keys in loop :-</div>
							{columns.map((el, index) => (
								<div key={index} className="flex items-center justify-between m-2">
									<Typography className="w-[20%] text-sm">{el.label}</Typography>
									<div className="flex align-middle justify-center">
										<Select
											size="small"
											value={responseHandlerFormat.keyValuePairs[el.name]?.operator}
											style={{ width: "12ch" }}
											className="mr-2"
											onChange={(e) => onFieldValueChange(e, el, "operator")}
										>
											<MenuItem value="Text">Text</MenuItem>
											<MenuItem value="Key">Key</MenuItem>
										</Select>
										<TextField
											size="small"
											type="text"
											placeholder={el.label}
											name={el.name}
											value={responseHandlerFormat.keyValuePairs[el.name]?.rhs}
											onChange={(e) => onFieldValueChange(e, el, "rhs")}
										/>
									</div>
								</div>
							))}
						</div>
						<Divider orientation="vertical" className="mx-4 hidden lg:block lg:h-full" />
						<div className="flex-1 pr-1.5">
							<div className="mb-2 font-bold">
								Your Response Format According To Response Type And Keys:-
							</div>
							<div>
								<JsonEditor
									data={jsonEditorState}
									restrictEdit={true}
									className="overflow-y-auto"
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-end items-center">
						<Button variant="contained" color="primary" onClick={onSave}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export const CriteriaRow = ({
	parent,
	index,
	onLHSChange,
	onMiddleChange,
	onRHSChange,
	apiForm,
	apiFormIndex,
	onRowRemove,
	fields,
	disabledOperatorTypes = [],
}) => {
	return (
		<>
			<InputComponent
				type="text"
				value={apiForm[apiFormIndex][parent][index].key}
				fields={fields}
				onChange={onLHSChange}
				classes="p-1"
				styles={{ width: "20ch" }}
				label=""
				placeholder={parent}
			/>
			<InputComponent
				size="small"
				type="dropdown"
				value={apiForm[apiFormIndex][parent][index].type}
				onChange={onMiddleChange}
				fields={MIDDLE_OPERATOR_TYPES.filter((el) => !disabledOperatorTypes.includes(el.value))}
				classes="m-1"
				styles={{ width: "11ch" }}
				label=""
				placeholder="Type"
			/>
			<InputComponent
				type={apiForm[apiFormIndex][parent][index].type}
				value={apiForm[apiFormIndex][parent][index].value}
				onChange={onRHSChange}
				fields={fields}
				classes="m-1"
				styles={{ width: "20ch" }}
				label=""
			/>
			<IconButton onClick={onRowRemove}>
				<Remove color="warning" className="text-base" />
			</IconButton>
		</>
	);
};

export const InputComponent = ({
	type,
	value,
	onChange,
	fields,
	classes = "",
	styles = {},
	size = "small",
	placeholder = "Value",
	label = "Value",
}) => {
	switch (String(type).toLowerCase()) {
		case "text":
		case "string":
		case "number":
		case "date":
			return (
				<TextField
					size={size}
					type={type === "string" ? "text" : type}
					placeholder={placeholder}
					onChange={onChange}
					value={value}
					label={label}
					className={classes}
					style={styles}
				/>
			);
		case "dropdown":
		case "column":
			return (
				<Select
					size={size}
					value={value}
					onChange={onChange}
					style={styles}
					className={classes}
					placeholder={placeholder}
					label={label}
				>
					{Array.isArray(fields) && fields.length > 0 ? (
						fields.map((field, index) => {
							return !field.disabled ? (
								<MenuItem key={index} value={field.value}>
									{field.label ? field.label : field.value}
								</MenuItem>
							) : field.isDivider ? (
								<Divider key={index} />
							) : null;
						})
					) : (
						<MenuItem className="text-gray-500 flex justify-center">So Emptiness :(</MenuItem>
					)}
				</Select>
			);
		case "null":
			return null;
		default:
			return <div className={classes} style={styles}></div>;
	}
};

export default APIFormRenderer;
