import axios from "./axiosInstance";

const getOrganizationName = async function (organizationId) {
	try {
		const res = await axios.get(`/api/organization/name/${organizationId}`);
		if (res.data) {
			return res.data;
		}
	} catch (e) {
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};
const fetchOrganizations = async function () {
	try {
		const res = await axios.get("/api/organization/names");

		if (res.data) {
			return res.data.organizations;
		}
		throw new Error("Failed to fetch organization names");
	} catch (e) {
		console.error(e);
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
		// Handle error
	}
};
const organizationNameMap = {}; // In-memory map to store organization names

const getOrganizationNameByOpeningId = async function (openingId) {
	try {
		if (organizationNameMap[openingId]) {
			return organizationNameMap[openingId];
		}

		const res = await axios.get(`/api/organization/name/openingId/${openingId}`);

		if (res.data) {
			// Cache the organization name in the map
			organizationNameMap[openingId] = res.data;
			return res.data;
		}
	} catch (e) {
		const message = e?.response?.data?.message || e?.message;
		throw new Error(message);
	}
};

// function to fetch the organization details
async function fetchOrganizationDetailsAPI() {
	try {
		const response = await axios.get(`/api/organization/details`);
		if (response.status === 200) {
			return response.data.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while fetching organization details");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

async function createOrganizationAPI(data) {
	try {
		const response = await axios.post(`/api/auth/create`, data);
		if (response.status === 201) {
			return response.data;
		} else {
			return [];
		}
	} catch (e) {
		console.log("something went wrong while creating organization");
		console.log(e.message);
		const message = e?.response?.data?.message || e.message;
		throw new Error(message);
	}
}

export {
	getOrganizationName,
	fetchOrganizations,
	getOrganizationNameByOpeningId,
	fetchOrganizationDetailsAPI,
	createOrganizationAPI,
};
