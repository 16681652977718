import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import "./TabularOpeningEvaluations.css";
import { useDropzone } from "react-dropzone";
import { formatISO } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams, useNavigate, Link } from "react-router-dom";
import { fetchOpeningByIdWithColors, getXl, updateCustomFieldsOfOpening } from "../utilities/openingsApi";
import OpeningButton from "./CreateUpdateOpening/Button";

import {
	bulkUploadCVs,
	cancellation,
	fetchInterviewReportsByOpening,
	saveUserDetailsForAnInterivewByAdmin,
	updateCandidateDetails,
	updateScheduleForCandidate,
	updateUserResume,
	updateCustomDataForInterviewReport,
} from "../utilities/interviewReportsApi";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Modal,
	TextField,
	Tooltip,
	Typography,
	Select,
	MenuItem,
	Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { logoutUser } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
	checkIf48HoursPassed,
	getFormattedDateTime3,
	getFormattedDateTime4,
} from "../utilities/utilityFunctions";
import OpeningDataLoaderForEvaluations from "../components/Skeleton-Loaders/OpeningDataLoaderForEvaluations";
import ReportsLoader from "../components/Skeleton-Loaders/ReportsLoader";
import clsx from "clsx";
import { DropDownColorIdentifier } from "./CreateUpdateOpening/CustomFieldTypes";
import CustomFields from "./CreateUpdateOpening/CustomFields";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { openingCustomFieldsSchema } from "../schemas/opening.schema";
import SettingsIcon from "@mui/icons-material/Settings";
import EventsModal from "../components/EventsModal";
import ActionsMenu from "../components/ActionsMenu";
import {
	selectCandidate,
	unselectCandidate,
	unselectAllCandidate,
	selectAllCandidate,
} from "../app/actions/candidate";
import { setSelectedOpening } from "../features/createOpening/createOpeningSlice";

const intlInstance = new Intl.NumberFormat("en-IN", {
	maximumFractionDigits: 3,
});

const initialUserDetails = {
	firstName: "",
	lastName: "",
	preferredName: "",
	email: "",
	phoneNumber: "",
	experience: "",
	resumeFileNameInS3: "",
};

const TabularOpeningEvaluations = () => {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const params = useParams();
	const [openingData, setOpeningData] = useState();
	const [interviewReports, setInterviewReports] = useState([]);
	const [fetchingOpeningData, setFetchingOpeningData] = useState(false);
	const [loadingReports, setLoadingReports] = useState(false);
	const [copied, setCopied] = useState(false);
	const [openForCard, setOpenForCard] = useState(false);
	const [resume, setResume] = useState(null);
	const [loading, setLoading] = useState({});
	const [addCandidate, setAddCandidate] = useState(false);
	const [userDetails, setUserDetails] = useState(initialUserDetails);
	const [savingUserDeets, setSavingUserDeets] = useState(false);
	const [isResumeRequired, setIsResumeRequired] = useState(false);
	const [showDatePicker, setShowDatePicker] = useState({});
	const [startDate, setStartDate] = useState({});
	const [showShareOptions, setShowShareOptions] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [editingCandidateId, setEditingCandidateId] = useState(null);
	const [resumeModal, setResumeModal] = useState(false);
	const [bulkUploading, setBulkUploading] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [currentSortDir, setCurrentSortDir] = useState("off");
	const [searchName, setSearchName] = useState("");
	const sortDir = ["asc", "desc", "off"];
	const [loadingXl, setLoadingXl] = useState(false);
	const [showCompleted, setShowCompleted] = useState(false);
	const [openAddFieldModal, setOpenAddFieldModal] = useState(false);
	const [isUpdatingCustomFields, setIsUpdatingCustomFields] = useState(false);
	const [cancelStates, setCancelStates] = useState({});
	const [restricted, setRestricted] = useState(false);
	const [selectedReports, setSelectedReports] = useState([]);

	const handleTooltipToggle = (id) => {
		setCancelStates((prevState) => ({
			...prevState,
			[id]: {
				...prevState[id],
				tooltipOpen: !prevState[id]?.tooltipOpen,
			},
		}));
	};

	const handleCancelToggle = (id) => {
		setCancelStates((prevState) => ({
			...prevState,
			[id]: {
				...prevState[id],
				cancel: !prevState[id]?.cancel,
				reasonForCancellation: "",
			},
		}));
	};

	const handleReasonChange = (id, value) => {
		setCancelStates((prevState) => ({
			...prevState,
			[id]: {
				...prevState[id],
				reasonForCancellation: value,
			},
		}));
	};

	const handleCancelling = (id, value) => {
		setCancelStates((prevState) => ({
			...prevState,
			[id]: {
				...prevState[id],
				cancelling: value,
			},
		}));
	};
	const [searchType, setSearchType] = useState("firstName");

	const handleSearchTypeChange = (event) => {
		setSearchType(event.target.value);
	};

	const supportedFileTypes = [
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/msword",
	];

	// Modal Style
	const modalStyle = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
		overflowY: "auto", // Optional, for scrolling if the form is too long
		maxHeight: "90vh", // Optional, adjust as needed
		borderRadius: "10px",
	};
	const customFieldsModalStyles = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "95%",
		"@media (min-width:600px)": {
			width: "80%",
		},
		"@media (min-width:960px)": {
			width: "70%",
		},
		"@media (min-width:1280px)": {
			width: "45%",
		},
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
		overflowY: "auto",
		maxHeight: "90vh",
		borderRadius: "10px",
	};

	const getNumericScore = (evaluation) => {
		try {
			const parsed = JSON.parse(evaluation);
			// const score = parsed?.evaluation_report?.overall_score_in_percentage ?? 0;
			let score = 0;
			if (parsed?.evaluation_report?.overall_score_in_percentage) {
				score = parsed?.evaluation_report?.overall_score_in_percentage;
			} else if (parsed?.evaluation_report?.overall_score) {
				score = parsed?.evaluation_report?.overall_score * 20;
			}
			return typeof score === "number" ? score : 0;
		} catch (error) {
			return 0;
		}
	};

	const getProgressColor = (value) => {
		if (value > 80) return "green";
		if (value >= 60 && value <= 80) return "orange";
		return "red";
	};

	function CircularProgressWithLabel(props) {
		return (
			<Box sx={{ position: "relative", display: "inline-flex" }}>
				<CircularProgress size={40} variant="determinate" {...props} />
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: "absolute",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography variant="subtitle1" component="div" sx={{ fontWeight: 400 }}>
						{`${Math.round(props.value)}`}
					</Typography>
				</Box>
			</Box>
		);
	}

	CircularProgressWithLabel.propTypes = {
		value: PropTypes.number.isRequired,
	};

	const memoizedReports = useMemo(() => {
		let filteredReports;
		if (searchType === "firstName") {
			filteredReports = interviewReports.filter((report) =>
				report?.firstName?.toLowerCase()?.includes(searchName.toLowerCase()),
			);
		}
		if (searchType === "lastName") {
			filteredReports = interviewReports.filter((report) =>
				report?.lastName?.toLowerCase()?.includes(searchName.toLowerCase()),
			);
		}
		if (searchType === "email") {
			filteredReports = interviewReports.filter((report) =>
				report?.email?.toLowerCase()?.includes(searchName.toLowerCase()),
			);
		}
		if (searchType === "phoneNumber") {
			filteredReports = interviewReports.filter((report) =>
				report?.phoneNumber?.toLowerCase()?.includes(searchName.toLowerCase()),
			);
		}
		if (showCompleted) {
			filteredReports = filteredReports.filter(
				(report) => report.interviewCompleted && !report.clickedExitInterview,
			);
		}
		if (currentSortDir !== "off") {
			filteredReports = filteredReports.sort((a, b) => {
				const scoreA = getNumericScore(a.evaluation);
				const scoreB = getNumericScore(b.evaluation);
				return currentSortDir === "asc" ? scoreA - scoreB : scoreB - scoreA;
			});
		}

		return filteredReports;
	}, [interviewReports, searchName, currentSortDir, showCompleted, searchType]);

	const handleSchedule = async (candidateId) => {
		const interviewUrl = `${window.origin}/interview/${openingData?._id}/start/${candidateId}`;
		const formattedDate = formatISO(startDate[candidateId]);
		const res = await updateScheduleForCandidate(
			candidateId,
			formattedDate,
			interviewUrl,
			openingData?.title,
			"empty",
			false,
			openingData?.proctoring,
		);
		if (res?.message === "updated") {
			// await getInterviewReportsForOpening();
			// find the id from res?.interviewReportData?._id and match in interviewReports and update the entire object for that candidate
			setInterviewReports((prev) => {
				return prev.map((report) => {
					if (res?.interviewReportData?._id === report._id) {
						return res?.interviewReportData;
					}
					return report;
				});
			});
			toast.success("Interview scheduled successfully. An email has been sent to the candidate.");
		} else {
			toast.error("Failed to schedule interview. Please try again.");
		}
		setStartDate((prev) => ({
			...prev,
			[candidateId]: null,
		}));
		setShowDatePicker((prev) => ({
			...prev,
			[candidateId]: false,
		}));
	};

	const handleEditClick = (candidateId) => {
		const candidateToEdit = interviewReports.find((report) => report._id === candidateId);
		if (candidateToEdit) {
			setUserDetails({
				firstName: candidateToEdit.firstName || "",
				lastName: candidateToEdit.lastName || "",
				preferredName: candidateToEdit.preferredName || "",
				email: candidateToEdit.email || "",
				phoneNumber: candidateToEdit.phoneNumber || "",
				experience: candidateToEdit.experience || (candidateToEdit.experience === 0 ? "0" : ""),
				resumeFileNameInS3: candidateToEdit.resumeFileNameInS3 || "",
			});
			setEditingCandidateId(candidateId);
			setIsEditing(true);
			setAddCandidate(true); // Open the modal
		}
	};

	const handleUserDetailsChange = (e) => {
		const { name, value } = e.target;
		setUserDetails((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (selectedFiles.length >= 10) {
				alert("You have reached the maximum limit of 10 files.");
				return;
			}

			let filteredFiles = [];
			let rejectedFiles = [];
			let fileNames = new Set(selectedFiles.map((file) => file.name));

			const availableSlots = 10 - selectedFiles.length;

			acceptedFiles.every((file, index) => {
				if (index >= availableSlots) {
					return false;
				}

				if (supportedFileTypes.includes(file.type) && !fileNames.has(file.name)) {
					filteredFiles.push(file);
					fileNames.add(file.name);
				} else if (!supportedFileTypes.includes(file.type)) {
					rejectedFiles.push(file);
				}

				return true;
			});

			setSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);

			if (rejectedFiles.length > 0) {
				const rejectedFileNames = rejectedFiles.map((file) => file.name).join(", ");
				alert(
					`Some files were not accepted because they are not supported types: ${rejectedFileNames}`,
				);
			}

			if (acceptedFiles.length > availableSlots) {
				alert(`Some files were not added because you can only upload a maximum of 10 files.`);
			}
		},
		[selectedFiles],
	);

	const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		noClick: true,
		accept: "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
	});

	const removeFile = (fileName) => {
		setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
	};

	const bulkResumeUpload = async () => {
		if (selectedFiles.length === 0) {
			alert("Please select files to upload.");
			return;
		}
		setBulkUploading(true);
		const formData = new FormData();
		selectedFiles.forEach((file) => {
			formData.append("resume", file);
		});
		formData.append("openingId", params.openingId);

		const res = await bulkUploadCVs(formData);
		// just log both success and failure responses
		if (res?.message && res?.data) {
			console.log(res.message);
			console.log(res.data);
			console.log(JSON.stringify(res?.extractedDatas));
			toast.success(res.message);
			setBulkUploading(false);
			setSelectedFiles([]);
			setResumeModal(false);
			await getInterviewReportsForOpening();
		}
		if (res?.failed && res?.message) {
			console.log(res.message);
			toast.error(res.message);
			setBulkUploading(false);
			setSelectedFiles([]);
			setResumeModal(false);
		}
	};

	// Dynamically open the file dialog and handle file selection for a specific candidate
	const openResumeUploadDialog = (candidateId) => {
		// Create a new file input element
		const fileInput = document.createElement("input");
		fileInput.type = "file";
		fileInput.accept = "application/pdf";
		fileInput.onchange = (e) => {
			// Handle file selection
			handleResumeUpload(e, candidateId);
		};
		// Trigger the file input dialog
		fileInput.click();
	};

	const handleResumeUpload2 = (e) => {
		const file = e.target.files[0];
		if (file.type !== "application/pdf") {
			toast.error("Only PDF files are supported.");
			setResume(null);

			// Reset the file input by creating a new form and replacing the old input
			const oldInput = document.getElementById("resumeU");
			const newInput = document.createElement("input");

			newInput.type = "file";
			newInput.id = oldInput.id;
			newInput.name = oldInput.name;
			newInput.className = oldInput.className;
			newInput.accept = oldInput.accept;

			// Correctly assign the event handler
			newInput.addEventListener("change", handleResumeUpload2);

			// Replace the old input with the new one
			oldInput.parentNode.replaceChild(newInput, oldInput);

			return;
		}
		setResume(file);
	};

	// Adapted for use with the button-triggered file upload scenario
	const handleResumeUpload = async (e, id) => {
		const file = e.target.files[0];
		// console.log(`file and id:`, file, id);
		if (file) {
			setResume(file);
			if (!addCandidate) {
				try {
					const formData = new FormData();
					formData.append("resume", file);
					formData.append("candidateId", id);
					await sendResumeToServer(formData);
				} catch (error) {
					console.error("Error uploading resume:", error);
				}
			}
		}
	};

	const sendResumeToServer = async (formData) => {
		setLoading((prev) => ({
			...prev,
			[formData.get("candidateId")]: true,
		}));
		const res = await updateUserResume(formData);
		if (res?.message && res?.data) {
			const { message, data } = res;
			if (data?._id) {
				// find the existing data for this candidate and locally update the value so we do not have to fetch it again to update the CV values in the UI
				const updatedInterviewReports = interviewReports.map((report) => {
					if (report._id === data._id) {
						return {
							...report,
							resumeFileNameInS3: data.resumeFileNameInS3,
							resumeSummary: data.resumeSummary,
						};
					}
					return report;
				});
				setInterviewReports(updatedInterviewReports);
				toast.success("Resume uploaded successfully.");
			}
		}
		if (res?.failed && res?.message) {
			toast.error("Failed to upload resume. Please try again.");
		}
		setLoading((prev) => ({
			...prev,
			[formData.get("candidateId")]: false,
		}));
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	};

	const handleUserDetailsSubmit = async (e) => {
		e.preventDefault();
		setSavingUserDeets(true);
		if (
			userDetails.firstName.trim() !== "" &&
			userDetails.lastName.trim() !== "" &&
			userDetails.email.trim() !== ""
		) {
			if (userDetails.email.trim() === "" || !isValidEmail(userDetails.email)) {
				toast.error("Invalid email address");
				setSavingUserDeets(false);
				return;
			}

			const formData = new FormData();
			for (let key in userDetails) {
				formData.append(key, userDetails[key]);
			}

			if (resume !== null) {
				formData.append("resume", resume);
			}

			if (params.openingId) {
				formData.append("openingId", params.openingId);
			}

			let res;
			if (isEditing) {
				formData.append("candidateId", editingCandidateId);
				res = await updateCandidateDetails(formData);
			} else {
				// Existing logic to add a new candidate
				res = await saveUserDetailsForAnInterivewByAdmin(formData);
			}

			if (res?.message && res?.data) {
				toast.success(res.message ? res.message : "Details saved successfully.");
				setUserDetails(initialUserDetails);
				setResume(null);
				setAddCandidate(false);
				setIsEditing(false);
				setEditingCandidateId(null);
				// Refresh the list of interview reports to reflect the changes
				await getInterviewReportsForOpening();
			} else if (res?.failed && res?.message) {
				toast.error(res.message);
			}
		} else {
			toast.error("First Name, Last Name and Email are required.");
		}
		setSavingUserDeets(false);
	};

	async function getOpeningById() {
		try {
			if (params.openingId) {
				setFetchingOpeningData(true);
				const data = await fetchOpeningByIdWithColors(params.openingId);
				if (data && user && data?.organizationId !== user?.organizationId && user.role !== "1096") {
					throw new Error("Unauthorized");
				}
				if (user) {
					setRestricted(user?.restricted);
				}
				if (data.questionsBasedOnResume) setIsResumeRequired(true);
				setOpeningData(data);
				setFetchingOpeningData(false);
				dispatch(setSelectedOpening(data));
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function getInterviewReportsForOpening() {
		try {
			if (params.openingId) {
				setLoadingReports(true);
				const reports = await fetchInterviewReportsByOpening(params.openingId);
				setInterviewReports(reports);
				setLoadingReports(false);
			}
		} catch (e) {
			console.log(e);
		}
	}
	async function fetchRequirements() {
		await getOpeningById();
		await getInterviewReportsForOpening();
	}

	useEffect(() => {
		fetchRequirements();
	}, []);

	async function handleCopyInterviewLink(openingId, shareLink) {
		try {
			if (shareLink) {
				await navigator.clipboard.writeText(shareLink);
			} else {
				await navigator.clipboard.writeText(`${window.origin}/interview/${openingId}`);
			}

			!shareLink && setCopied(true);
			toast.success("Interview link copied to clipboard");
		} catch (e) {
			console.log("Failed to copy message to clipboard");
			console.log(e.message);
		}
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			setCopied(false);
		}, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [copied]);

	async function handleDownloadExcel() {
		setLoadingXl(true);
		try {
			// Sending the prepared data to the server
			// const response = await axios.post(`/api/openings/opening/${openingId}/xl`, { body });
			const response = await getXl(openingData?._id);
			if (response.data) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `zinterview_${openingData.title}.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (error) {
			console.error("Failed to download Excel file:", error);
			throw new Error(error.response?.data?.message || error.message);
		} finally {
			setLoadingXl(false);
		}
	}

	const methods = useForm({
		defaultValues: {
			customFields: [],
		},
		values: {
			customFields: openingData?.customFields ?? [],
		},
		resolver: zodResolver(openingCustomFieldsSchema),
	});

	const { handleSubmit, reset } = methods;

	useEffect(() => {
		if (!openAddFieldModal) {
			reset({
				customFields: openingData?.customFields ?? [],
			});
		}
	}, [openAddFieldModal, openingData, reset]);

	const handleCustomFieldsModalClose = () => {
		setOpenAddFieldModal(false);
	};

	const handleRowCheckBoxClick = (e, id) => {
		let isChecked = e.target.checked;
		if (id) {
			if (isChecked) {
				setSelectedReports([...selectedReports, id]);
				dispatch(selectCandidate(id));
			} else {
				setSelectedReports(selectedReports.filter((reportId) => reportId !== id));
				dispatch(unselectCandidate(id));
			}
		}
	};

	const onClickHeadCheckBox = (e) => {
		if (e.target.checked) {
			setSelectedReports(interviewReports.map((report) => report._id));
			dispatch(selectAllCandidate(interviewReports.map((report) => report._id)));
		} else {
			setSelectedReports([]);
			dispatch(unselectAllCandidate());
		}
	};

	const handleUpdateCustomFields = async (data) => {
		try {
			setIsUpdatingCustomFields(true);
			const payload = {
				openingId: params.openingId,
				customFields: data.customFields,
			};
			await updateCustomFieldsOfOpening(payload);
			await fetchRequirements();
			setIsUpdatingCustomFields(false);
			handleCustomFieldsModalClose();
		} catch (e) {
			setIsUpdatingCustomFields(false);
			toast.warn(e.message);
		}
	};

	const finalCustomFields =
		!fetchingOpeningData && openingData
			? openingData?.customFields?.filter((field) => {
					return !field.isDeleted;
				})
			: [];

	return restricted ? (
		<Box
			className={`flex flex-col items-center justify-center bg-gray-100`}
			style={{
				height: "calc(100vh - var(--navbar-height))",
			}}
		>
			<Box id="panel2" className="bg-white p-6 rounded-lg shadow-lg w-4/5 md:w-1/2 lg:w-1/2">
				<Typography
					variant="h4"
					className={`text-center font-bold mb-6 ${isMobile ? "text-2xl" : "text-4xl"}`}
				>
					Account Restricted
				</Typography>
				<Typography
					variant="h6"
					className={`text-justify font-medium ${isMobile ? "text-sm" : "text-lg"}`}
				>
					{user.canConductInterviewMessage}
				</Typography>
			</Box>
		</Box>
	) : (
		<Box className="bg-gray-100 min-h-screen">
			<Box className="container mx-auto p-6 mt-0">
				<Box
					onClick={() => navigate(-1)}
					className="text-blue-500 text-sm hover:underline cursor-pointer"
				>
					<i className="fas fa-arrow-left text-sm mr-2"></i>Back
				</Box>
			</Box>
			<Box className="container mx-auto p-6">
				{openingData ? (
					<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
						<Typography
							variant="p"
							href="opening-details.html"
							className="text-gray-500 font-bold text-xs mb-2 cursor-pointer"
							onClick={(e) => {
								setOpenForCard(!openForCard);
							}}
						>
							Job ID: {openingData?._id}
						</Typography>
						<Typography
							variant="h2"
							className="text-2xl font-semibold mb-4 cursor-pointer"
							onClick={(e) => {
								setOpenForCard(!openForCard);
							}}
						>
							{openingData?.title}
						</Typography>
						<Typography variant="p" className="text-gray-600 font-semibold mb-2 text-xs">
							Experience Level:{" "}
							{"minExperience" in openingData && "maxExperience" in openingData
								? `${openingData?.minExperience}-${openingData?.maxExperience} years`
								: `${openingData?.experience} years`}
						</Typography>

						<Box className="text-gray-500 text-xs mb-2">
							{`Created on: ${new Date(openingData?.createdAt).toLocaleString("default", {
								month: "short",
							})} ${new Date(openingData?.createdAt).getDate()}, ${new Date(
								openingData?.createdAt,
							).getFullYear()}`}
						</Box>
						<Box className="flex flex-wrap gap-4 mb-2">
							<Collapse in={openForCard} timeout={"auto"} unmountOnExit>
								<Box className="flex flex-row justify-start items-center gap-4">
									<RenderSpecialInstructions
										specialInstructions={openingData?.interviewGuidelines}
									/>
								</Box>
							</Collapse>
							{!openingData.isTechnical && openingData?.coreSkills?.length > 0 ? (
								<RenderCoreSkills
									coreSkills={openingData.coreSkills}
									colorSchemes={openingData?.colorSchemes}
								/>
							) : (
								<RenderSkillGroup
									skillsGroup={openingData?.skillsGroup}
									colorSchemes={openingData?.colorSchemes}
								/>
							)}
							{/* <span className='bg-blue-200 text-blue-700 '>
							Java
						</span>
						<span className='bg-yellow-200 text-yellow-700 px-2 py-1 rounded-full text-sm'>
							.NET
						</span>
						<span className='bg-green-200 text-green-700 px-2 py-1 rounded-full text-sm'>
							MongoDB
						</span> */}
						</Box>
						<Box className="flex justify-between items-center">
							<Box className="w-3/4">
								<input
									type="text"
									className="border rounded-md p-2 w-full bg-gray-200 text-sm"
									value={`${window.location.protocol}//${window.location.host}/interview/${openingData?._id}`}
									disabled
								/>
								{copied ? (
									<i className="fas fa-check text-green-500 text-sm ml-2"></i>
								) : (
									<i
										className="fas fa-copy text-gray-500 text-sm cursor-pointer ml-2"
										onClick={(e) => {
											e.stopPropagation();
											handleCopyInterviewLink(openingData?._id);
										}}
									></i>
								)}
								<p className="text-gray-500 text-xs mt-2">
									Copy and share this link with the candidate.
								</p>
							</Box>
						</Box>
					</Box>
				) : (
					<OpeningDataLoaderForEvaluations />
				)}
				{!(loadingReports || fetchingOpeningData) && (
					<Box className="flex justify-end mt-[1rem] mb-[1rem] px-0 py-0">
						<input
							type="search"
							name="candidateName"
							id="candidate-name-search"
							placeholder={`Search by ${
								searchType === "firstName"
									? "FirstName"
									: searchType === "lastName"
										? "LastName"
										: searchType === "email"
											? "Email"
											: "PhoneNo"
							} ${
								interviewReports?.length ? "from " + interviewReports.length + " reports" : ""
							}`}
							onChange={(e) => setSearchName(e.target.value)}
							className="p-2 mr-2 h-[40px] rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 flex-grow max-w-[243px]"
						/>

						{/* <InputLabel htmlFor="search-type-select">Search By:</InputLabel> */}
						<Select
							value={searchType}
							onChange={handleSearchTypeChange}
							inputProps={{
								name: "searchType",
								id: "search-type-select",
							}}
							className="normal-case h-[40px]  text-white px-0 py-0 rounded-lg flex items-center justify-between"
							sx={{
								backgroundColor: "#10B981",
								":hover": {
									backgroundColor: "#047857",
								},
							}}
						>
							<MenuItem value="firstName"> FirstName</MenuItem>
							<MenuItem value="lastName">LastName</MenuItem>
							<MenuItem value="email">Email</MenuItem>
							<MenuItem value="phoneNumber">Phone No</MenuItem>
						</Select>
					</Box>
				)}
				{loadingReports || fetchingOpeningData ? (
					<ReportsLoader />
				) : (
					<Box className="bg-white p-6 rounded-lg shadow-md mb-6">
						<Box className="flex justify-between items-center mb-4">
							<Typography variant="h3" className="text-lg font-semibold">
								Candidates
							</Typography>
							<Box className="flex items-center justify-end flex-wrap gap-2 text-sm ml-2">
								{(!fetchingOpeningData || !loadingReports) && (
									<>
										<Button
											className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
											onClick={() => {
												setOpenAddFieldModal(true);
											}}
											sx={{
												backgroundColor: "#3B82F6",
												":hover": {
													backgroundColor: "#1D4ED8",
												},
											}}
										>
											<SettingsIcon className="mr-1 h-5 w-5" />
											Add Custom Field
										</Button>
										<Modal
											open={openAddFieldModal}
											onClose={() => {
												if (!isUpdatingCustomFields) {
													handleCustomFieldsModalClose();
												}
											}}
											aria-labelledby="add-field-title"
											aria-describedby="add-custom-field"
											disableEscapeKeyDown={isUpdatingCustomFields}
										>
											<Box
												sx={customFieldsModalStyles}
												className="create-update-opening"
											>
												<Typography
													id="add-field-title"
													variant="h6"
													component="h2"
													textAlign="center"
													gutterBottom
												>
													{"Add Custom Fields"}
												</Typography>
												<FormProvider {...methods}>
													<form
														noValidate
														onSubmit={handleSubmit(handleUpdateCustomFields)}
													>
														<CustomFields />
														<div className="flex flex-row gap-2 justify-end items-center">
															<OpeningButton
																text="Close"
																handleClick={handleCustomFieldsModalClose}
																type="button"
																className="bg-gray-400 hover:bg-gray-500 focus:bg-gray-500"
																disabled={isUpdatingCustomFields}
															/>
															<OpeningButton
																text="Save"
																handleClick={handleSubmit(
																	handleUpdateCustomFields,
																)}
																type="submit"
																disabled={isUpdatingCustomFields}
															/>
														</div>
													</form>
												</FormProvider>
											</Box>
										</Modal>
									</>
								)}
								{(interviewReports && interviewReports.length) > 0 && (
									<Button
										className="normal-case  focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-lg flex items-center justify-between"
										// component={Button}
										disabled={loadingXl}
										onClick={handleDownloadExcel}
										sx={{
											backgroundColor: "#10B981",
											":hover": {
												backgroundColor: "#047857",
											},
										}}
									>
										{!loadingXl ? (
											<>
												<i className="fas fa-file-excel mr-2"></i>
												<div>Download Candidates</div>
											</>
										) : (
											<>
												<CircularProgress />
												<div>Downloading...</div>
											</>
										)}
									</Button>
								)}
								<Button
									className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
									// component={Button}
									// onClick={(e) => {
									// 	setAddCandidate(true);
									// }}
									onClick={() => {
										setUserDetails(initialUserDetails);
										setResume(null);
										setAddCandidate(true);
										setIsEditing(false);
									}}
									sx={{
										backgroundColor: "#3B82F6",
										":hover": {
											backgroundColor: "#1D4ED8",
										},
									}}
								>
									<i className="fas fa-plus mr-2"></i>
									Add Candidate
								</Button>
								<Modal
									open={addCandidate}
									onClose={() => {
										if (!savingUserDeets) {
											setAddCandidate(false);
											setIsEditing(false);
										}
									}}
									aria-labelledby="modal-title"
									aria-describedby="modal-description"
									disableEscapeKeyDown={savingUserDeets}
								>
									<Box sx={modalStyle}>
										<Typography
											id="modal-title"
											variant="h6"
											component="h2"
											textAlign="center"
										>
											{isEditing ? "Edit Candidate" : "Add Candidate"}
										</Typography>
										<form onSubmit={handleUserDetailsSubmit}>
											<Box className="flex space-x-4 mb-4">
												<div className="w-1/2">
													<label
														htmlFor="modal-firstName"
														className="block text-sm font-medium text-gray-600 mb-2"
													>
														First Name
													</label>
													<TextField
														sx={{
															"& fieldset": {
																border: "1px solid #E5E7EB",
															},
														}}
														color="black"
														size="small"
														required
														value={userDetails.firstName}
														onChange={handleUserDetailsChange}
														type="text"
														name="firstName"
														fullWidth
														disabled={savingUserDeets}
													/>
												</div>
												<div className="w-1/2">
													<label
														htmlFor="modal-lastName"
														className="block text-sm font-medium text-gray-600 mb-2"
													>
														Last Name
													</label>
													<TextField
														sx={{
															"& fieldset": {
																border: "1px solid #F2F3F5",
															},
														}}
														color="black"
														size="small"
														required
														value={userDetails.lastName}
														onChange={handleUserDetailsChange}
														type="text"
														name="lastName"
														fullWidth
														disabled={savingUserDeets}
													/>
												</div>
											</Box>
											<Box className="mb-4">
												<label
													htmlFor="modal-preferredName"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													Preferred Name
												</label>
												<TextField
													sx={{
														"& fieldset": {
															border: "1px solid #F2F3F5",
														},
													}}
													color="black"
													size="small"
													// required
													value={userDetails.preferredName}
													onChange={handleUserDetailsChange}
													type="text"
													name="preferredName"
													fullWidth
													disabled={savingUserDeets}
												/>
											</Box>
											<Box className="mb-4">
												<label
													htmlFor="modal-email"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													Email Address
												</label>
												<TextField
													sx={{
														"& fieldset": {
															border: "1px solid #F2F3F5",
														},
													}}
													color="black"
													size="small"
													required
													value={userDetails.email}
													onChange={handleUserDetailsChange}
													type="email"
													name="email"
													fullWidth
													disabled={savingUserDeets}
												/>
											</Box>
											<Box className="mb-4">
												<label
													htmlFor="modal-experience"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													Experience in Years
												</label>
												<TextField
													sx={{
														"& fieldset": {
															border: "1px solid #F2F3F5",
														},
													}}
													color="black"
													size="small"
													// required
													value={userDetails.experience}
													onChange={handleUserDetailsChange}
													type="number"
													name="experience"
													fullWidth
													disabled={savingUserDeets}
												/>
											</Box>
											<Box className="mb-4">
												<label
													htmlFor="modal-phoneNumber"
													className="block text-sm font-medium text-gray-600 mb-2"
												>
													Phone Number
												</label>
												<TextField
													sx={{
														"& fieldset": {
															border: "1px solid #F2F3F5",
														},
													}}
													color="black"
													size="small"
													// required
													value={userDetails.phoneNumber}
													onChange={handleUserDetailsChange}
													type="text"
													name="phoneNumber"
													fullWidth
													disabled={savingUserDeets}
												/>
											</Box>
											{isResumeRequired && (
												<Box className="mb-4">
													<label
														htmlFor="modal-resume"
														className="block text-sm font-medium text-gray-600 mb-2"
													>
														Upload Resume
													</label>
													<TextField
														sx={{
															"& .MuiInputBase-root": {
																padding: "6px",
																fontSize: "0.9rem",
															},
															"& .MuiInputBase-input": {
																padding: "4px",
															},
															"& fieldset": {
																border: "1px solid #F2F3F5",
															},
															"&:hover fieldset": {
																border: "1px solid #F2F3F5 !important",
															},
														}}
														color="black"
														size="small"
														// required={
														// 	isResumeRequired
														// }
														type="file"
														id="resumeU"
														accept="application/pdf"
														InputLabelProps={{
															shrink: true,
														}}
														onChange={handleResumeUpload2}
														name="resume"
														fullWidth
														disabled={savingUserDeets}
													/>
													{userDetails?.resumeFileNameInS3 && (
														<Typography
															variant="p"
															onClick={() => {
																const resumeFileName =
																	userDetails.resumeFileNameInS3;
																const hasValidExtension =
																	/\.(pdf|doc|docx)$/i.test(resumeFileName);
																const resumeUrl = hasValidExtension
																	? resumeFileName
																	: `${resumeFileName}.pdf`;

																const url = resumeUrl.endsWith(".pdf")
																	? `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${resumeUrl}`
																	: `${window.location.origin}/view/${resumeUrl}`;

																window.open(url, "_blank");
															}}
															rel="noopener noreferrer"
															className={`block text-sm font-medium text-gray-600 mb-2 ${!resume && "cursor-pointer"}`}
														>
															{resume
																? "This will replace the previously uploaded resume"
																: `Previously Uploaded Resume: ${userDetails?.resumeFileNameInS3}`}
														</Typography>
													)}
												</Box>
											)}
											<Box className="flex justify-end space-x-2 mt-4">
												<Box
													className={`normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${
														savingUserDeets && "cursor-not-allowed opacity-50"
													}`}
													component={Button}
													onClick={() => {
														!savingUserDeets && setAddCandidate(false);
														!savingUserDeets &&
															setUserDetails(initialUserDetails);
														setResume(null);
													}}
													disabled={savingUserDeets}
												>
													Cancel
												</Box>
												<Box
													className={`normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${
														savingUserDeets && "cursor-not-allowed opacity-50"
													}`}
													component={Button}
													type="submit"
													disabled={savingUserDeets}
												>
													{savingUserDeets ? "Saving..." : "Save"}
												</Box>
											</Box>
										</form>
									</Box>
								</Modal>
								<Button
									className="normal-case rounded-md focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-lg flex items-center justify-between"
									// component={Button}
									// onClick={(e) => {
									// 	console.log(e);
									// }}
									onClick={() => {
										selectedFiles.length !== 0 && setSelectedFiles([]);
										setResumeModal(true);
										document.body.style.overflow = "hidden";
									}}
									sx={{
										backgroundColor: "#F59E0B",
										":hover": {
											backgroundColor: "#B45309",
										},
									}}
								>
									<i className="fas fa-upload mr-2"></i>
									Upload Resumes
								</Button>
								<>
									<EventsModal
										openingId={params.openingId}
										customFields={openingData?.customFields}
									/>
									<ActionsMenu openingId={params.openingId} />
								</>
								<Modal
									open={resumeModal}
									onClose={() => {
										if (!bulkUploading) {
											setResumeModal(false);
											document.body.style.overflow = "auto";
										}
									}}
									aria-labelledby="bulk-resume"
									aria-describedby="bulk-resume-upload"
									disableEscapeKeyDown={bulkUploading}
									sx={{
										overflow: "auto",
										// scrollbarWidth: "none",
										// "&::-webkit-scrollbar": {
										// 	display: "none",
										// },
										// "-ms-overflow-style": "none",
									}}
								>
									<Box className="w-full max-w-md mx-auto mt-10 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]">
										<Box className="bg-white p-6 rounded-lg shadow-lg">
											<Typography
												component="h2"
												variant="h2"
												className="text-lg font-semibold mb-4"
											>
												Add Resumes
											</Typography>
											<Box
												className={`flex justify-center items-center w-full ${bulkUploading && "cursor-not-allowed"}`}
												{...(bulkUploading ? {} : getRootProps())}
											>
												<label
													htmlFor="file-upload"
													className={`flex flex-col justify-center items-center w-full h-32 border-2 border-gray-300 ${
														bulkUploading
															? "bg-gray-200 cursor-not-allowed"
															: `${
																	isFocused && "border-blue-600"
																} ${isDragAccept && "border-green-600"} ${
																	isDragReject && "border-red-600"
																} border-dashed cursor-pointer`
													} rounded-lg`}
												>
													<Box className="text-center">
														{isDragActive ? (
															<p className="mt-1 text-sm text-gray-500">
																Drop the files here...
															</p>
														) : (
															<p className="mt-1 text-sm text-gray-500">
																Drag and Drop here
																<br />
																or
																<br />
																<span
																	className={`${bulkUploading ? "text-gray-500" : "text-blue-600 hover:underline"}`}
																>
																	Browse files
																</span>
															</p>
														)}
														<input
															{...(bulkUploading ? {} : getInputProps())}
															id="file-upload"
															name="file-upload"
															type="file"
															className="hidden"
															multiple
															disabled={bulkUploading}
														/>
													</Box>
												</label>
											</Box>
											{/* <ul>
										{selectedFiles.map((file, index) => (
											<li key={index}>
												{file.name}{" "}
												<button onClick={() => removeFile(file.name)}>
													Cancel
												</button>
											</li>
										))}
									</ul> */}

											<List>
												{selectedFiles.map((file, index) => (
													<ListItem key={index} className="h-[30px]">
														<ListItemText primary={file.name} />
														<ListItemSecondaryAction>
															<IconButton
																disabled={bulkUploading}
																className={`
													${bulkUploading && "cursor-not-allowed opacity-50"}
													`}
																edge="end"
																aria-label="cancel"
																onClick={() => removeFile(file.name)}
															>
																<CancelIcon />
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												))}
											</List>

											<Typography
												variant="p"
												component="p"
												className="text-xs text-gray-500 mt-2"
											>
												Accepted File Types: .pdf,.doc and .docx only
											</Typography>
											<Box
												component={Button}
												disabled={bulkUploading}
												className={`normal-case mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 cursor-pointer focus:outline-none focus:border-none text-center ${
													bulkUploading && "cursor-not-allowed opacity-50"
												}`}
												onClick={bulkResumeUpload}
											>
												{/* {bulkUploading
											? `Uploading... ${(
													<i
														id=""
														className="fas fa-circle-notch fa-spin text-2xl"
													></i>
												)}`
											: "Upload"} */}
												{bulkUploading ? (
													<Box className="flex justify-center items-center">
														Uploading...
														<i
															id=""
															className="fas fa-circle-notch fa-spin text-base ml-2"
														></i>
													</Box>
												) : (
													"Upload"
												)}
											</Box>
										</Box>
									</Box>
								</Modal>
							</Box>
						</Box>
						<Box className="bg-gray-50 p-4 flex justify-between items-center rounded-lg mb-6">
							<label className="flex items-center space-x-3">
								<input
									// size="small"
									type="checkbox"
									id="showCompletedInterviews"
									onChange={(e) => {
										setShowCompleted(e.target.checked);
									}}
								/>
								<span className="text-sm">Show Completed Interviews</span>
							</label>
						</Box>
						<TableContainer component={Paper} className="mt-6" sx={{ height: "80vh" }}>
							<Table>
								<TableHead>
									<TableRow className="bg-gray-100 min-w-full border rounded-lg overflow-hidden text-sm">
										<TableCell className="flex justify-center align-middle">
											<Checkbox
												size="small"
												checked={
													selectedReports.length != 0 &&
													selectedReports?.length === interviewReports.length
												}
												onChange={onClickHeadCheckBox}
											/>
											{selectedReports.length !== 0 && (
												<Chip label={selectedReports?.length} />
											)}
										</TableCell>
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Interview Date
										</TableCell>
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Name
										</TableCell>
										<TableCell
											className="text-center cursor-pointer text-gray-500 uppercase tracking-wide text-xs font-medium"
											onClick={() => {
												const newSortDir =
													sortDir[(sortDir.indexOf(currentSortDir) + 1) % 3];
												setCurrentSortDir(newSortDir);
											}}
										>
											<Tooltip
												title={`${
													currentSortDir === "asc"
														? "Current Order is Ascending. Click again to sort by Descending."
														: currentSortDir === "desc"
															? "Current Order is Descending. Click again to return to default order."
															: "Click to sort by score"
												}`}
												arrow
											>
												<Box className="flex items-center justify-center">
													Score
													{currentSortDir === "asc" ? (
														<i className="fas fa-sort-amount-down-alt ml-1"></i>
													) : currentSortDir === "desc" ? (
														<i className="fas fa-sort-amount-down ml-1"></i>
													) : (
														<i className="fas fa-sort ml-1"></i>
													)}
												</Box>
											</Tooltip>
										</TableCell>
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											<Tooltip
												title="This score indicates how many times the candidate moved away from the tab. For example, a score of -3 means the candidate moved away from the tab 3 times."
												arrow
											>
												<>Trust Score</>
											</Tooltip>
										</TableCell>
										{/* <TableCell className="text-center">Meeting Link</TableCell> */}
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Interview Status
										</TableCell>
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											View Report
										</TableCell>
										{finalCustomFields.map((customField) => {
											const { type, fieldName, _id } = customField;
											return (
												<TableCell
													key={_id}
													className={clsx(
														"text-center text-gray-500 uppercase  text-xs font-medium",
													)}
													sx={{ width: "300px" }}
												>
													{fieldName}
												</TableCell>
											);
										})}
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Resume
										</TableCell>
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Interview Schedule
										</TableCell>
										<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
											Recording
										</TableCell>
										{user?.role === "1096" && (
											<TableCell className="text-center text-gray-500 uppercase tracking-wide text-xs font-medium">
												Logs
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{(interviewReports && interviewReports.length) > 0 ? (
										memoizedReports.map((interviewReport) => {
											let candidateScore = null;
											try {
												const { evaluation } = interviewReport;
												let parsedJSON = JSON.parse(evaluation);
												const { verdict, evaluation_report } = parsedJSON;
												const score = verdict?.score;
												const overall_score = evaluation_report?.overall_score;
												const overall_score_in_percentage =
													evaluation_report?.overall_score_in_percentage;

												if (score >= 0) {
													candidateScore = intlInstance.format(+score);
												}
												if (overall_score >= 0) {
													candidateScore =
														intlInstance.format(+overall_score) + "/5";

													candidateScore = Math.round(+overall_score * 20);
												}
												if (overall_score_in_percentage >= 0) {
													candidateScore =
														intlInstance.format(+overall_score_in_percentage);
												}
											} catch (error) {
												candidateScore = null;
											}
											return (
												<TableRow key={interviewReport._id}>
													<TableCell
														className="text-center"
														component="th"
														scope="row"
													>
														<div className="flex justify-center items-center min-w-[80px]">
															<Checkbox
																size="small"
																checked={selectedReports?.includes(
																	interviewReport?._id,
																)}
																onChange={(e) =>
																	handleRowCheckBoxClick(
																		e,
																		interviewReport?._id,
																	)
																}
															/>
															<IconButton
																onClick={(e) =>
																	handleEditClick(interviewReport?._id)
																}
																variant="text"
																className="inline-flex items-center justify-center h-8 w-8 bg-blue-100 text-blue-500 rounded-full hover:bg-blue-200"
															>
																<i className="fas fa-pen text-sm hover:text-blue-700"></i>
															</IconButton>
														</div>
													</TableCell>
													<TableCell className="text-center">
														{getFormattedDateTime3(interviewReport?.createdAt)}
													</TableCell>
													<TableCell className="text-left">
														<Box className="flex flex-col justify-between items-start">
															<Typography variant="p">
																{interviewReport?.firstName +
																	" " +
																	interviewReport?.lastName}
															</Typography>
															<Typography
																variant="p"
																className="text-sm text-gray-400"
															>
																{interviewReport?.phoneNumber.replace(
																	/(\d{3})(\d{3})(\d{4})/,
																	"$1-$2-$3",
																)}
															</Typography>
															<Typography
																variant="p"
																className="text-sm text-blue-400"
															>
																{interviewReport?.email}
															</Typography>
														</Box>
													</TableCell>

													<TableCell className="text-center">
														{candidateScore ? (
															<CircularProgressWithLabel
																color={getProgressColor(candidateScore)}
																value={candidateScore}
															/>
														) : interviewReport?.evaluationInProgress ? (
															<Tooltip title="Evaluation in Progress" arrow>
																<i className="fas fa-circle-notch fa-spin text-lg"></i>
															</Tooltip>
														) : (
															"-"
														)}
													</TableCell>
													<TableCell className="text-center">
														{interviewReport?.trustScore
															? interviewReport?.trustScore !== 0
																? interviewReport?.trustScore
																: "0"
															: "-"}
													</TableCell>
													<TableCell className="text-center">
														{interviewReport?.interviewCompleted ? (
															<span className="text-gray-400">
																{interviewReport?.clickedExitInterview ? (
																	<Tooltip
																		title="Candidate clicked the exit interview button."
																		arrow
																	>
																		<Chip
																			className="font-semibold bg-yellow-200 text-yellow-800"
																			label="Candidate Terminated Prematurely"
																		/>
																	</Tooltip>
																) : (
																	<Chip
																		label="Interview Completed"
																		className="bg-green-200 text-green-800 font-semibold"
																	/>
																)}
															</span>
														) : interviewReport?.cancelled ? (
															<Tooltip
																title="This interview has been cancelled. You can resume the interview or schedule it again."
																arrow
															>
																<Chip
																	label="Interview Cancelled"
																	className="font-semibold"
																/>
															</Tooltip>
														) : interviewReport?.schedule ? (
															<Box
																className={`flex flex-row items-center justify-center gap-1 relative group mr-5 ml-5 ${cancelStates[interviewReport?._id]?.showCancelIcon && "transition-opacity duration-500 ease-out"}`}
																onMouseEnter={() =>
																	setCancelStates((prevState) => ({
																		...prevState,
																		[interviewReport?._id]: {
																			...prevState[
																				interviewReport?._id
																			],
																			showCancelIcon: true,
																		},
																	}))
																}
																onMouseLeave={() =>
																	setCancelStates((prevState) => ({
																		...prevState,
																		[interviewReport?._id]: {
																			...prevState[
																				interviewReport?._id
																			],
																			showCancelIcon: false,
																		},
																	}))
																}
															>
																<Chip
																	label={`Scheduled: ${getFormattedDateTime4(interviewReport?.schedule)}`}
																	className="bg-blue-200 text-blue-800 font-semibold"
																/>
																<Tooltip
																	className="absolute left-full top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 group-hover:translate-x-3 transition-opacity transition-transform duration-500 ease-out"
																	arrow
																	title="Cancel the scheduled interview"
																	open={
																		cancelStates[interviewReport?._id]
																			?.tooltipOpen &&
																		!cancelStates[interviewReport?._id]
																			?.cancel
																	} // Show based on hover/focus and not show when cancel is true
																	onOpen={() =>
																		handleTooltipToggle(
																			interviewReport?._id,
																		)
																	}
																	onClose={() =>
																		handleTooltipToggle(
																			interviewReport?._id,
																		)
																	}
																	disableFocusListener={
																		cancelStates[interviewReport?._id]
																			?.cancel
																	} // Optional: disable focus events when dialog is open
																	disableHoverListener={
																		cancelStates[interviewReport?._id]
																			?.cancel
																	} // Optional: disable hover events when dialog is open
																	disableTouchListener={
																		cancelStates[interviewReport?._id]
																			?.cancel
																	} // Optional: disable touch events when dialog is open
																>
																	<IconButton
																		onClick={async () =>
																			handleCancelToggle(
																				interviewReport?._id,
																			)
																		}
																		onMouseEnter={() =>
																			handleTooltipToggle(
																				interviewReport?._id,
																			)
																		}
																		onMouseLeave={() =>
																			handleTooltipToggle(
																				interviewReport?._id,
																			)
																		}
																		onFocus={() =>
																			handleTooltipToggle(
																				interviewReport?._id,
																			)
																		}
																		onBlur={() =>
																			handleTooltipToggle(
																				interviewReport?._id,
																			)
																		}
																		variant="text"
																		className="inline-flex items-center justify-center h-8 w-8 bg-blue-200 text-blue-800 rounded-full hover:bg-blue-300"
																	>
																		<i className="fas fa-times-circle text-sm hover:text-blue-700"></i>
																	</IconButton>
																</Tooltip>
																<Modal
																	open={
																		cancelStates[interviewReport?._id]
																			?.cancel
																	}
																	onClose={() =>
																		handleCancelToggle(
																			interviewReport?._id,
																		)
																	}
																	aria-labelledby="cancel-interview-dialog-title"
																	aria-describedby="cancel-interview-dialog-description"
																	disableEscapeKeyDown={
																		cancelStates[interviewReport?._id]
																			?.cancelling
																	}
																>
																	<Box
																		sx={modalStyle}
																		className="flex flex-col space-y-4 p-6"
																	>
																		<Typography
																			variant="subtitle1"
																			className="text-lg"
																		>
																			Are you sure you want to cancel
																			the scheduled interview?
																		</Typography>
																		<Typography
																			variant="subtitle2"
																			className="text-sm text-gray-500"
																		>
																			You can resume the interview or
																			schedule it again if needed.
																		</Typography>
																		<Box className="mb-4">
																			<label
																				htmlFor="modal-firstName"
																				className="block text-sm font-medium text-gray-600 mb-2"
																			>
																				Reason for Cancellation
																				(optional)
																			</label>
																			<TextField
																				sx={{
																					"& fieldset": {
																						border: "1px solid #E5E7EB",
																					},
																				}}
																				color="black"
																				size="small"
																				required
																				value={
																					cancelStates[
																						interviewReport?._id
																					]
																						?.reasonForCancellation ||
																					""
																				}
																				onChange={(e) =>
																					handleReasonChange(
																						interviewReport?._id,
																						e.target.value,
																					)
																				}
																				type="text"
																				name="firstName"
																				fullWidth
																				disabled={
																					cancelStates[
																						interviewReport?._id
																					]?.cancelling
																				}
																			/>
																		</Box>
																		<Box className="flex justify-end space-x-2 mt-4">
																			<Button
																				className={`normal-case bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 focus:outline-none focus:border-none text-xs ${cancelStates[interviewReport?._id]?.cancelling && "cursor-not-allowed opacity-50"}`}
																				onClick={() =>
																					handleCancelToggle(
																						interviewReport?._id,
																					)
																				}
																				disabled={
																					cancelStates[
																						interviewReport?._id
																					]?.cancelling
																				}
																			>
																				No
																			</Button>
																			<Button
																				className={`normal-case bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs ${cancelStates[interviewReport?._id]?.cancelling && "cursor-not-allowed opacity-50"}`}
																				type="submit"
																				disabled={
																					cancelStates[
																						interviewReport?._id
																					]?.cancelling
																				}
																				onClick={async () => {
																					handleCancelling(
																						interviewReport?._id,
																						true,
																					);
																					const res =
																						await cancellation(
																							interviewReport?._id,
																							openingData?.title,
																							cancelStates[
																								interviewReport
																									?._id
																							]
																								?.reasonForCancellation,
																						);
																					if (
																						res?.message ===
																						"cancelled"
																					) {
																						setInterviewReports(
																							(prev) =>
																								prev.map(
																									(
																										report,
																									) =>
																										report._id ===
																										res
																											?.interviewReportData
																											?._id
																											? res?.interviewReportData
																											: report,
																								),
																						);
																						toast.success(
																							"Interview Cancelled. The candidate has been notified by email.",
																						);
																					} else {
																						toast.error(
																							"Error cancelling interview. Please try again later.",
																						);
																					}
																					handleCancelling(
																						interviewReport?._id,
																						false,
																					);
																					handleCancelToggle(
																						interviewReport?._id,
																					);
																				}}
																			>
																				{cancelStates[
																					interviewReport?._id
																				]?.cancelling
																					? "Cancelling..."
																					: "Yes"}
																			</Button>
																		</Box>
																	</Box>
																</Modal>
															</Box>
														) : interviewReport?.browserClosed ? (
															checkIf48HoursPassed(
																interviewReport?.lastActiveTimestamp,
															) ? (
																<Tooltip
																	title="Interview Closed Due to Inactivity: It has been more than 48 hours since the candidate closed the tab or browser."
																	arrow
																>
																	<Chip
																		label="Dropped"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															) : (
																<Tooltip
																	title="Browser Closed: Candidate closed the tab or browser."
																	arrow
																>
																	<Chip
																		label="Dropped"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															)
														) : openingData?.proctoring ? (
															interviewReport?.meetingId ? (
																interviewReport?.isLatestMeetingIdInvalid ? (
																	<Chip label="Previous Meeting Ended" />
																) : (
																	<Button
																		target="_blank"
																		component={Link}
																		to={`/admin/proctor/${interviewReport?._id}`}
																		variant="text"
																		color="primary"
																		className="normal-case"
																	>
																		Join Meeting
																	</Button>
																)
															) : interviewReport?.createdByAdmin ? (
																checkIf48HoursPassed(
																	interviewReport?.createdAt,
																) ? (
																	<Tooltip
																		title="It has been more than 48 hours since the candidate was added by admin. The candidate has not joined the interview yet."
																		arrow
																	>
																		<Chip
																			label="New"
																			className="bg-red-200 text-red-800 font-semibold"
																		/>
																	</Tooltip>
																) : (
																	<Tooltip
																		title="Candidate added by admin but has not joined the interview yet."
																		arrow
																	>
																		<Chip
																			label="New"
																			className="bg-red-200 text-red-800 font-semibold"
																		/>
																	</Tooltip>
																)
															) : (
																<Tooltip
																	title="Candidate was unable to proceed."
																	arrow
																>
																	<Chip
																		label="Dropped"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															)
														) : interviewReport?.activeSession ? (
															<Tooltip
																title="Interview is ongoing but proctoring is disabled."
																arrow
															>
																<Chip label="Ongoing" />
															</Tooltip>
														) : interviewReport?.disconnected ? (
															checkIf48HoursPassed(
																interviewReport?.lastActiveTimestamp,
															) ? (
																<Tooltip
																	title="Interview Closed Due to Inactivity: It has been more than 48 hours since the candidate got disconnected. This could have happened due to network issues on the candidate's system or their browser crashing."
																	arrow
																>
																	<Chip
																		label="Dropped"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															) : (
																<Tooltip
																	title="Candidate Disconnected: Candidate got disconnected. This can happen due to network issues on the candidate's system or their browser crashing."
																	arrow
																>
																	<Chip
																		label="Dropped"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															)
														) : interviewReport?.createdByAdmin ? (
															checkIf48HoursPassed(
																interviewReport?.createdAt,
															) ? (
																<Tooltip
																	title="It has been more than 48 hours since the candidate was added by admin. The candidate has not joined the interview yet."
																	arrow
																>
																	<Chip
																		label="New"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															) : (
																<Tooltip
																	title="Candidate added by admin but has not joined the interview yet."
																	arrow
																>
																	<Chip
																		label="New"
																		className="bg-red-200 text-red-800 font-semibold"
																	/>
																</Tooltip>
															)
														) : (
															<Tooltip
																title="Candidate was unable to proceed."
																arrow
															>
																<Chip
																	label="Dropped"
																	className="bg-red-200 text-red-800 font-semibold"
																/>
															</Tooltip>
														)}
													</TableCell>
													<TableCell className="text-center">
														<IconButton
															component={Link}
															to={`/admin/evaluation/${interviewReport?._id}?openingId=${openingData?._id}`}
															variant="text"
															className="inline-flex items-center justify-center h-8 w-8 bg-blue-100 text-blue-500 rounded-full hover:bg-blue-200"
														>
															<i className="fas fa-chevron-right text-sm hover:text-blue-700"></i>
														</IconButton>
													</TableCell>
													{finalCustomFields.map((customField) => {
														const interviewReportID = interviewReport._id;
														const { _id: customFieldId, type } = customField;
														const currentValue =
															interviewReport.customFieldsData?.[
																customField._id
															];

														return (
															<TableCell
																className="text-center"
																key={customFieldId}
																sx={{ width: "300px" }}
															>
																{type === 0 && (
																	<TextTypeRenderer
																		customField={customField}
																		interviewReportId={interviewReportID}
																		openingId={params.openingId}
																		currentValue={currentValue}
																		dispatch={dispatch}
																	/>
																)}
																{type === 1 && (
																	<DropDownTypeRenderer
																		customField={customField}
																		interviewReportId={interviewReportID}
																		openingId={params.openingId}
																		currentValue={currentValue}
																		dispatch={dispatch}
																	/>
																)}
															</TableCell>
														);
													})}
													<TableCell className="text-center">
														{loading[interviewReport?._id] ? (
															<i
																id=""
																className="fas fa-circle-notch fa-spin text-2xl"
															></i>
														) : interviewReport?.resumeFileNameInS3 ? (
															<IconButton
																disableRipple
																onClick={() => {
																	const resumeFileName =
																		interviewReport.resumeFileNameInS3;
																	const hasValidExtension =
																		/\.(pdf|doc|docx)$/i.test(
																			resumeFileName,
																		);
																	const resumeUrl = hasValidExtension
																		? resumeFileName
																		: `${resumeFileName}.pdf`;

																	const url = resumeUrl.endsWith(".pdf")
																		? `https://procturemeet.s3.ap-southeast-1.amazonaws.com/resumes/${resumeUrl}`
																		: `${window.location.origin}/view/${resumeUrl}`;

																	window.open(url, "_blank");
																}}
															>
																<i
																	className={`fas text-2xl ${
																		interviewReport?.resumeFileNameInS3.endsWith(
																			".doc",
																		) ||
																		interviewReport?.resumeFileNameInS3.endsWith(
																			".docx",
																		)
																			? "fa-file-word text-blue-500 hover:text-blue-700"
																			: "fa-file-pdf text-red-500 hover:text-red-700"
																	}`}
																></i>
															</IconButton>
														) : (
															<Box className="flex flex-row items-center justify-center">
																<Button
																	className="normal-case focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-lg flex items-center justify-between"
																	onClick={() =>
																		openResumeUploadDialog(
																			interviewReport._id,
																		)
																	}
																	sx={{
																		backgroundColor: "#10B981",
																		":hover": {
																			backgroundColor: "#047857",
																		},
																	}}
																>
																	<i className="fas fa-upload text-sm mr-2"></i>
																	Upload
																</Button>
															</Box>
														)}
													</TableCell>
													<TableCell className="text-center w-72 text-gray-400">
														{interviewReport?.interviewCompleted ? (
															"Interview Completed"
														) : (
															<Box className="flex flex-row items-center justify-center space-x-2">
																{interviewReport?.schedule ? (
																	<>
																		{!showShareOptions[
																			interviewReport._id
																		] && (
																			<>
																				{showDatePicker[
																					interviewReport._id
																				] && (
																					<Box>
																						<TextField
																							id="datetime-local"
																							// label="Next appointment"
																							type="datetime-local"
																							size="small"
																							onChange={(
																								date,
																							) => {
																								// alert(
																								// 	`Selected Date: ${date.target.value}`,
																								// );
																								setStartDate(
																									(
																										prev,
																									) => ({
																										...prev,
																										[interviewReport._id]:
																											date
																												.target
																												.value,
																									}),
																								);
																							}}
																						/>
																					</Box>
																				)}
																				<Button
																					className="normal-case focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-center flex-wrap "
																					onClick={(e) => {
																						if (
																							showDatePicker[
																								interviewReport
																									._id
																							]
																						) {
																							startDate[
																								interviewReport
																									._id
																							]
																								? handleSchedule(
																										interviewReport._id,
																									)
																								: setShowDatePicker(
																										(
																											prev,
																										) => ({
																											...prev,
																											[interviewReport._id]: false,
																										}),
																									);
																						} else {
																							setShowDatePicker(
																								(prev) => ({
																									...prev,
																									[interviewReport._id]: true,
																								}),
																							);
																						}
																					}}
																					sx={{
																						backgroundColor:
																							"#10B981",
																						":hover": {
																							backgroundColor:
																								"#047857",
																						},
																					}}
																				>
																					{showDatePicker[
																						interviewReport._id
																					] &&
																					!startDate[
																						interviewReport?._id
																					] ? (
																						<i className="fas fa-times text-sm mr-2"></i>
																					) : (
																						<i className="fas fa-calendar-alt text-sm mr-2"></i>
																					)}
																					{showDatePicker[
																						interviewReport._id
																					] &&
																					!startDate[
																						interviewReport?._id
																					]
																						? "Cancel"
																						: "Reschedule"}
																				</Button>
																			</>
																		)}
																		{!showDatePicker[
																			interviewReport._id
																		] &&
																			(showShareOptions[
																				interviewReport._id
																			] ? (
																				<Box className="flex flex-col items-center">
																					<Box className="flex flex-row items-center justify-between gap-2">
																						<Button
																							className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
																							sx={{
																								backgroundColor:
																									"#3B82F6",
																								":hover": {
																									backgroundColor:
																										"#1D4ED8",
																								},
																							}}
																							onClick={(e) => {
																								const urlToCopy =
																									interviewReport?.resumeToken
																										? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
																										: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
																								e.stopPropagation();
																								handleCopyInterviewLink(
																									openingData?._id,
																									urlToCopy,
																								);
																								setShowShareOptions(
																									(
																										prev,
																									) => ({
																										...prev,
																										[interviewReport._id]: false,
																									}),
																								);
																							}}
																						>
																							<i className="fas fa-copy text-sm mr-2"></i>
																							Copy Link
																						</Button>

																						<Button
																							className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
																							sx={{
																								backgroundColor:
																									"#3B82F6",
																								":hover": {
																									backgroundColor:
																										"#1D4ED8",
																								},
																							}}
																							onClick={() => {
																								const urlToShare =
																									interviewReport?.resumeToken
																										? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
																										: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
																								if (
																									navigator.share
																								) {
																									navigator
																										.share(
																											{
																												title: `Interview Link for ${interviewReport.firstName} ${interviewReport.lastName}`,
																												url: urlToShare,
																											},
																										)
																										.catch(
																											(
																												error,
																											) =>
																												console.log(
																													"Error sharing",
																													error,
																												),
																										);
																								} else {
																									handleCopyInterviewLink(
																										openingData?._id,
																										urlToShare,
																									);
																								}
																								setShowShareOptions(
																									(
																										prev,
																									) => ({
																										...prev,
																										[interviewReport._id]:
																											!prev[
																												interviewReport
																													._id
																											],
																									}),
																								);
																							}}
																						>
																							<i className="fas fa-share text-sm mr-2"></i>
																							Share
																						</Button>
																					</Box>
																				</Box>
																			) : (
																				<Button
																					className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
																					// component={Button}
																					// onClick={() => {
																					// 	// RESUME FIRST
																					// 	setShowShareOptions(
																					// 		(prev) => ({
																					// 			...prev,
																					// 			[interviewReport._id]:
																					// 				!prev[
																					// 					interviewReport
																					// 						._id
																					// 				],
																					// 		}),
																					// 	);
																					// }}
																					onClick={(e) => {
																						const urlToCopy =
																							interviewReport?.resumeToken
																								? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
																								: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
																						e.stopPropagation();
																						handleCopyInterviewLink(
																							openingData?._id,
																							urlToCopy,
																						);
																					}}
																					sx={{
																						backgroundColor:
																							"#3B82F6",
																						":hover": {
																							backgroundColor:
																								"#1D4ED8",
																						},
																					}}
																				>
																					<i className="fas fa-link text-sm mr-2"></i>
																					Share Link
																				</Button>
																			))}
																	</>
																) : (
																	<>
																		{!showShareOptions[
																			interviewReport._id
																		] && (
																			<>
																				{showDatePicker[
																					interviewReport._id
																				] && (
																					<TextField
																						id="datetime-local"
																						// label="Next appointment"
																						type="datetime-local"
																						size="small"
																						// defaultValue="2021-05-24T10:30"
																						// defaultValue={new Date()
																						// 	.toISOString()
																						// 	.slice(0, 16)}
																						// InputLabelProps={{
																						// 	shrink: true,
																						// }}
																						onChange={(date) => {
																							// alert(
																							// 	`Selected Date: ${date.target.value}`,
																							// );
																							setStartDate(
																								(prev) => ({
																									...prev,
																									[interviewReport._id]:
																										date
																											.target
																											.value,
																								}),
																							);
																						}}
																					/>
																				)}
																				<Button
																					// className="normal-case rounded-md focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"

																					// disabled={
																					// 	interviewReport?.email.trim() ===
																					// 		"" ||
																					// 	!isValidEmail(
																					// 		interviewReport?.email,
																					// 	)
																					// }
																					className={`normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between ${interviewReport?.email.trim() === "" || !isValidEmail(interviewReport?.email) ? "cursor-not-allowed opacity-50" : ""}`}
																					onClick={(e) => {
																						if (
																							interviewReport?.email.trim() ===
																								"" ||
																							!isValidEmail(
																								interviewReport?.email,
																							)
																						) {
																							// alert(
																							// 	"check for email here",
																							// );
																							console.log(
																								"email is invalid",
																							);
																						} else {
																							if (
																								showDatePicker[
																									interviewReport
																										._id
																								]
																							) {
																								if (
																									startDate[
																										interviewReport
																											._id
																									]
																								) {
																									handleSchedule(
																										interviewReport._id,
																									);
																								} else {
																									setShowDatePicker(
																										(
																											prev,
																										) => ({
																											...prev,
																											[interviewReport._id]: false,
																										}),
																									);
																									setShowShareOptions(
																										(
																											prev,
																										) => ({
																											...prev,
																											[interviewReport._id]: false,
																										}),
																									);
																								}
																							} else {
																								setShowDatePicker(
																									(
																										prev,
																									) => ({
																										...prev,
																										[interviewReport._id]: true,
																									}),
																								);
																							}
																						}
																					}}
																					sx={{
																						backgroundColor:
																							"#10B981",
																						":hover": {
																							backgroundColor:
																								"#047857",
																						},
																					}}
																				>
																					<Tooltip
																						title={
																							(interviewReport?.email.trim() ===
																								"" ||
																								!isValidEmail(
																									interviewReport?.email,
																								)) &&
																							"To schedule an interview, please provide a valid email address."
																						}
																						arrow
																					>
																						{showDatePicker[
																							interviewReport
																								._id
																						] &&
																						!startDate[
																							interviewReport
																								?._id
																						] ? (
																							<i className="fas fa-times text-sm mr-2"></i>
																						) : (
																							<i className="fas fa-calendar-alt text-sm mr-2"></i>
																						)}
																						{showDatePicker[
																							interviewReport
																								._id
																						] &&
																						!startDate[
																							interviewReport
																								?._id
																						]
																							? "Cancel"
																							: "Schedule"}{" "}
																					</Tooltip>
																				</Button>
																			</>
																		)}
																		{!showDatePicker[
																			interviewReport._id
																		] &&
																			(showShareOptions[
																				interviewReport._id
																			] ? (
																				<Box className="flex flex-col items-center">
																					<Box className="flex flex-row items-center justify-between gap-2">
																						{/* <input
																				id="uniqueInterviewUrl"
																				type="text"
																				className="border rounded-md p-2 w-44 bg-gray-200 text-xs"
																				value={`${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`}
																				disabled
																			/> */}

																						<Button
																							className="normal-case focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
																							sx={{
																								backgroundColor:
																									"#3B82F6",
																								":hover": {
																									backgroundColor:
																										"#1D4ED8",
																								},
																							}}
																							onClick={(e) => {
																								const urlToCopy =
																									interviewReport?.resumeToken
																										? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
																										: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
																								e.stopPropagation();
																								handleCopyInterviewLink(
																									openingData?._id,
																									urlToCopy,
																								);
																								setShowShareOptions(
																									(
																										prev,
																									) => ({
																										...prev,
																										[interviewReport._id]: false,
																									}),
																								);
																							}}
																						>
																							<i className="fas fa-copy text-sm mr-2"></i>
																							Copy Link
																						</Button>

																						<Button
																							className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
																							sx={{
																								backgroundColor:
																									"#3B82F6",
																								":hover": {
																									backgroundColor:
																										"#1D4ED8",
																								},
																							}}
																							onClick={() => {
																								const urlToShare =
																									interviewReport?.resumeToken
																										? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
																										: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
																								if (
																									navigator.share
																								) {
																									navigator
																										.share(
																											{
																												title: `Interview Link for ${interviewReport.firstName} ${interviewReport.lastName}`,
																												url: urlToShare,
																											},
																										)
																										.catch(
																											(
																												error,
																											) =>
																												console.log(
																													"Error sharing",
																													error,
																												),
																										);
																								} else {
																									handleCopyInterviewLink(
																										openingData?._id,
																										urlToShare,
																									);
																								}
																								setShowShareOptions(
																									(
																										prev,
																									) => ({
																										...prev,
																										[interviewReport._id]:
																											!prev[
																												interviewReport
																													._id
																											],
																									}),
																								);
																							}}
																						>
																							<i className="fas fa-share text-sm mr-2"></i>
																							Share
																						</Button>
																					</Box>
																				</Box>
																			) : (
																				<Button
																					className="normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
																					onClick={(e) => {
																						const urlToCopy =
																							interviewReport?.resumeToken
																								? `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}/${interviewReport?.resumeToken}`
																								: `${window.location.protocol}//${window.location.host}/interview/${openingData?._id}/start/${interviewReport._id}`;
																						e.stopPropagation();
																						handleCopyInterviewLink(
																							openingData?._id,
																							urlToCopy,
																						);
																					}}
																					sx={{
																						backgroundColor:
																							"#3B82F6",
																						":hover": {
																							backgroundColor:
																								"#1D4ED8",
																						},
																					}}
																				>
																					<i className="fas fa-link text-sm mr-2"></i>
																					Share Link
																				</Button>
																			))}
																	</>
																)}
															</Box>
														)}
													</TableCell>
													<TableCell className="text-center">
														{interviewReport?.meetingId &&
														interviewReport?.concatenationId ? (
															interviewReport?.interviewCompleted ||
															!interviewReport?.activeSession ? (
																<Button
																	component={Link}
																	// to={`/riktam/proctor/${interviewReport._id}`}
																	to={`https://procturemeet.s3.ap-southeast-1.amazonaws.com/recordings/${interviewReport?._id}/composited-video/${interviewReport?.concatenationId}.mp4`}
																	variant="text"
																	color="secondary"
																>
																	View Recording
																</Button>
															) : (
																"Recording will be available after the meeting ends"
															)
														) : (
															"Meeting has not started"
														)}
													</TableCell>
													{user?.role === "1096" && (
														<TableCell className="text-center">
															<Button
																component={Link}
																to={`/superadmin/logs/${interviewReport._id}`}
															>
																Logs
															</Button>
														</TableCell>
													)}
												</TableRow>
											);
										})
									) : (
										<TableRow>
											<TableCell colSpan={10} className="text-center">
												No Reports Found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default TabularOpeningEvaluations;

const RenderCoreSkills = ({ coreSkills, colorSchemes }) => {
	const colorClass = colorSchemes[coreSkills.length % colorSchemes.length];

	return (
		<Box className="mb-2">
			<Typography variant="h4" className="mb-1 text-sm font-semibold">
				Core Skills
			</Typography>
			<Box className="flex flex-wrap gap-2">
				{coreSkills &&
					coreSkills.length > 0 &&
					coreSkills.map((skill, skillIndex) => (
						<Box className={`${colorClass} rounded-full px-2 py-1 text-xs`} key={skillIndex}>
							{skill}
						</Box>
					))}
			</Box>
		</Box>
	);
};

const RenderSkillGroup = ({ skillsGroup, colorSchemes }) => {
	return (
		<Box>
			{skillsGroup &&
				Array.isArray(skillsGroup) &&
				skillsGroup.length > 0 &&
				skillsGroup.map((group, index) => {
					const { skillGroupName, skills } = group;
					const colorClass = colorSchemes[index % colorSchemes.length];

					return (
						<Box key={skillGroupName + index} className="mb-2">
							<Typography variant="h4" className="mb-1 text-sm font-semibold">
								{skillGroupName}
							</Typography>
							<Box className="flex flex-wrap gap-2">
								{skills &&
									Array.isArray(skills) &&
									skills.length > 0 &&
									skills.map((skill, skillIndex) => (
										<Box
											className={`${colorClass} rounded-full px-2 py-1 text-sm`}
											key={skill + skillIndex}
										>
											{skill}
										</Box>
									))}
							</Box>
						</Box>
					);
				})}
		</Box>
	);
};

const RenderSpecialInstructions = ({ specialInstructions }) => {
	if (specialInstructions) {
		return (
			<Box className="my-4 flex h-[190px] w-[50ch] flex-col items-center justify-start overflow-y-auto p-3 px-2 py-4 shadow-lg">
				<h5 className="text-lg font-semibold">Special Instructions</h5>
				<p className="whitespace-pre-line p-4 text-base font-normal">{specialInstructions}</p>
			</Box>
		);
	}
};

const DropDownTypeRenderer = ({ customField, interviewReportId, openingId, currentValue, dispatch }) => {
	const [isSaving, setIsSaving] = useState(false);
	const { _id, fieldName, config } = customField;
	const dropDownValues = config.dropDownValues ?? [];
	const isCurrentValueExistingOrDeleted = dropDownValues.find(
		(dropDownObj) => dropDownObj.id === currentValue,
	);
	const defaultValue =
		currentValue && isCurrentValueExistingOrDeleted ? currentValue : config?.defaultValue ?? "";
	const [newDropDownValueId, setNewDropDownValueId] = useState(defaultValue);
	const handleChange = async (e) => {
		setNewDropDownValueId(e.target.value);
		try {
			setIsSaving(true);
			const data = {
				interviewReportId,
				openingId,
				customFieldId: _id,
				value: e.target.value,
			};
			const response = await updateCustomDataForInterviewReport(data);
			setIsSaving(false);
		} catch (error) {
			setIsSaving(false);
			console.log("there is an error in saving custom drop down field");
			console.log(error);
			toast.warn(error?.message);
		}
	};

	return (
		<Select
			sx={{
				minWidth: "130px",

				"& .MuiOutlinedInput-notchedOutline": {
					border: "1px solid rgb(229, 231, 235)",
				},
				"& .MuiSelect-select": {
					padding: "6px 6px 6px 8px",
				},
				"&:hover .MuiOutlinedInput-notchedOutline": {
					border: "1px solid rgb(229, 231, 235)",
				},
			}}
			className="custom-select rounded-md "
			disabled={isSaving}
			renderValue={(value) => {
				const currentDropDownObject = dropDownValues.find(
					(dropDownObj) => dropDownObj.id === newDropDownValueId,
				);
				if (currentDropDownObject) {
					const { color, value, id } = currentDropDownObject;
					return (
						<div className="flex flex-row gap-2 justify-start items-center text-sm border-none outline-none ">
							{isSaving && <CircularProgress size={"1rem"} />}
							<DropDownColorIdentifier color={color} />
							{value}
						</div>
					);
				} else {
					return <div className="">{value ?? ""}</div>;
				}
			}}
			value={newDropDownValueId}
			onChange={handleChange}
		>
			{dropDownValues.map((dropDownOption) => {
				const { color, value, id } = dropDownOption;
				return (
					<MenuItem key={id} value={id} className="border-none outline-none">
						<div className="flex flex-row gap-2 justify-start items-center text-sm ">
							<DropDownColorIdentifier color={color} />
							{value}
						</div>
					</MenuItem>
				);
			})}
		</Select>
	);
};

const TextTypeRenderer = ({ customField, interviewReportId, openingId, currentValue, dispatch }) => {
	const [isSaving, setIsSaving] = useState(false);
	const { _id, fieldName, config } = customField;
	const defaultValue = currentValue ? currentValue : config?.defaultValue ?? "";
	const [newFieldName, setFieldName] = useState(defaultValue);
	const [latestSavedValue, setLatestSavedValue] = useState(defaultValue);

	const handleChange = (e) => {
		setFieldName(e.target.value);
	};

	const handleSaveOnBlur = async (e) => {
		try {
			if (newFieldName.trim() !== latestSavedValue.trim()) {
				setIsSaving(true);
				const data = {
					interviewReportId,
					openingId,
					customFieldId: _id,
					value: newFieldName.trim(),
				};
				const response = await updateCustomDataForInterviewReport(data);
				setLatestSavedValue(newFieldName.trim());
				setIsSaving(false);
			}
		} catch (error) {
			setIsSaving(false);
			console.log("there is an error in saving custom text field");
			console.log(error);
			toast.warn(error?.message);
		}
	};
	return (
		<>
			<div className="flex flex-row gap-2 justify-center items-center create-update-opening">
				{isSaving && <CircularProgress size={"1rem"} />}
				<input
					type="text"
					onKeyDown={(e) => {
						if (e.key.toLowerCase() === "enter") {
							handleSaveOnBlur();
						}
					}}
					className={clsx("rounded-md border p-2", isSaving ? "w-[150px]" : "w-[200px]")}
					value={newFieldName}
					placeholder={fieldName}
					onChange={handleChange}
					disabled={isSaving}
					onBlur={handleSaveOnBlur}
				/>
			</div>
		</>
	);
};
