import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import AlertMessage from "../components/AlertMessage";
import { useNavHeight } from "../custom-hooks/useNavHeight";
const BaseAdminNavPage = () => {
	useNavHeight("nav-bar");
	return (
		<>
			<Box
				className=" bg-gray-100"
				style={{
					paddingBottom: "var(--navbar-height, 78px)",
				}}
			>
				<Navbar />
			</Box>
			<AlertMessage />
			<Outlet />
		</>
	);
};

export default BaseAdminNavPage;
