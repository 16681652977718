import * as React from 'react';
import ReactDOM from 'react-dom/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Chip, IconButton } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';

const renderConfirmationDialog = (
    label,
    onClose,
    onConfirm,
    icons = true,
    timer = 0
) => {
    const element = document.getElementById("ConfirmationDialogContainer");
    if (element) {
        const root = ReactDOM.createRoot(element);
        root.render(<ConfirmationDialog
            label={label}
            onClose={onClose}
            onConfirm={onConfirm}
            icons={icons}
            timer={timer}
        />);
        return () => root.unmount();
    }
}

const ConfirmationDialog = ({
    label = "",
    onClose = () => { },
    onConfirm = () => { },
    icons = true,
    timer = 0
}) => {
    const [open, setOpen] = React.useState(true);
    const [count, setCount] = React.useState(timer);

    const onClickConfirm = () => {
        onConfirm();
        setOpen(false);
    };

    const onDiscard = () => {
        onClose();
        setOpen(false);
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCount((prev) => prev - 1000);
        }, 1000);
        if (count < 1000) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [count]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
            >
                <DialogTitle>
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {label}
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    {icons ?
                        <>
                            <IconButton onClick={onDiscard}>
                                <Cancel color="error" />
                            </IconButton>
                            <IconButton disabled={Number(count / 1000) > 0} onClick={onClickConfirm}>
                                {Number(count / 1000) > 0
                                    ?
                                    <Chip size="small" label={Number(count / 1000)} variant="outlined" />
                                    :
                                    <Check color="primary" />
                                }
                            </IconButton>
                        </>
                        :
                        <>
                            <Button autoFocus variant="outlined" color="error" onClick={onDiscard}>
                                Cancel
                            </Button>
                            <Button variant="outlined" disabled={Number(count / 1000) > 0} color="primary" onClick={onClickConfirm}>
                                {
                                    Number(count / 1000) > 0
                                        ?
                                        `Confirm (${Number(count / 1000)})`
                                        :
                                        "Confirm"
                                }
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export { renderConfirmationDialog };