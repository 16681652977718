import axios from "axios";
import { toast } from "react-toastify";

const generateApiKey = async () => {
    try {
        let resp = await axios.get("/api/user/generate-api-key");
        if (resp.data.apiKey) {
            navigator.clipboard.writeText(resp.data.apiKey);
            toast.success("Copied to clipboard");
        } else {
            toast.error("Error generating api key");
        }
    } catch (error) {
        toast.error("Error generating api key");
    }
};
const rotateApiKey = async () => {
    try {
        let resp = await axios.get("/api/user/reset-api-key");
        if (resp.data.apiKey) {
            navigator.clipboard.writeText(resp.data.apiKey);
            toast.success("Copied to clipboard");
        } else {
            toast.error("Error rotating api key");
        }
    } catch (error) {
        toast.error("Error rotating api key");
    }
};

export { generateApiKey, rotateApiKey };