import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import NotFound from "./components/NotFound";
import AdminPanel from "./pages/AdminPanel";
import Interview from "./pages/Interview";
import BaseAdminNavPage from "./pages/BaseAdminNavPage";
import InterviewEnd from "./pages/InterviewEnd";
import InterviewReport from "./pages/InterviewReport";
import Home from "./pages/Home";
import InterviewHomePage from "./pages/InterviewHomePage";
import UserDetails from "./pages/UserDetails";
import Proctor from "./pages/Proctor";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import RequireAuth from "./pages/RequireAuth";
import TabularOpeningEvaluations from "./pages/TabularOpeningEvaluations";
import OpeningType from "./components/OpeningType";
import ErrorBoundary from "./components/ErrorBoundary";
import BaseSuperAdminNavPage from "./pages/BaseSuperAdminNavPage";
import { Suspense, lazy } from "react";
import FileViewer from "./components/ReportsTable/FileViewer";
import CreateUpdateOpeningWithJD from "./pages/CreateUpdateOpening/CreateUpdateOpeningWithJD";
import CustomerAccountCreation from "./pages/CustomerAccountCreation";
import ResetPassword from "./components/ResetPassword";
import CreateMarkdownWithGPT from "./pages/CreateMarkdownWithGPT";
import store from "./app/store";
import { getAuthStatus } from "./features/auth/authSlice";

// import PlanPanel from "./pages/SuperAdmin/PlanPanel";
// import Dashboard from "./pages/SuperAdmin/Dashboard";
// import Organizations from "./pages/SuperAdmin/Organizations";
// import CreatePlan from "./pages/SuperAdmin/CreatePlan";
// import OrganizationDashboard from "./pages/SuperAdmin/OrganizationDashboard";
import BillingDashboard from "./pages/BillingDashboard";
import Loader from "./components/Loader";
// import Setting from "./pages/SuperAdmin/Setting";
import ConfirmationDialogContainer from "./components/confirmation/ConfirmationDialogContainer";
import QuestionBank from "./pages/QuestionBank";

const DisplayLogs = lazy(() => import("./pages/DisplayLogs"));
const TabularOpeningOnGoingInterviews = lazy(() => import("./pages/TabularOpeningOnGoingInterviews"));
const TabularOpeningRecentInterviews = lazy(() => import("./pages/TabularOpeningRecentInterviews"));
const PlanPanel = lazy(() => import("./pages/SuperAdmin/PlanPanel"));
const Dashboard = lazy(() => import("./pages/SuperAdmin/Dashboard"));
const Organizations = lazy(() => import("./pages/SuperAdmin/Organizations"));
const CreatePlan = lazy(() => import("./pages/SuperAdmin/CreatePlan"));
const OrganizationDashboard = lazy(() => import("./pages/SuperAdmin/OrganizationDashboard"));
const Setting = lazy(() => import("./pages/SuperAdmin/Setting"));

async function getAuthStatusOnAppLoad() {
	try {
		store.dispatch(getAuthStatus());
	} catch (error) {
		console.log(error);
	}
}

getAuthStatusOnAppLoad();

export default function App() {
	return (
		<>
			<ErrorBoundary interviewPage={false}>
				<StyledEngineProvider injectFirst>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/register" element={<SignUp />} />
							<Route path="/login" element={<Login />} />
							<Route path="/end" element={<InterviewEnd />} />
							<Route path="/preset" element={<ResetPassword />} />
							<Route path="/preset/:token" element={<ResetPassword />} />
							<Route path="/interview/:openingId" element={<InterviewHomePage />}>
								<Route index element={<UserDetails />} />
								<Route path="start/:interviewReportId" element={<Interview />} />
								<Route path="start/:interviewReportId/:resumeToken" element={<Interview />} />
								<Route path="start/:interviewReportId/sd/:sd" element={<Interview />} />

								{/* <Route path='userDetails' element={<UserDetails />} /> */}
								{/* <Route path='demo' element={<Interview />} /> */}
							</Route>
							<Route path="view/:fileNameInS3" element={<FileViewer />} />
							<Route
								path="/superadmin"
								element={
									<RequireAuth superAdmin={true}>
										<BaseSuperAdminNavPage />
									</RequireAuth>
								}
							>
								<Route
									index
									element={
										<Suspense fallback="loading">
											<Dashboard />
										</Suspense>
									}
								/>
								<Route
									path="plans"
									element={
										<Suspense fallback={<Loader />}>
											<PlanPanel />
										</Suspense>
									}
								/>
								<Route
									path="organizations"
									element={
										<Suspense fallback={<Loader />}>
											<Organizations />
										</Suspense>
									}
								/>
								<Route
									path="organization/dashboard/:organizationId"
									element={
										<Suspense fallback={<Loader />}>
											<OrganizationDashboard />
										</Suspense>
									}
								/>
								<Route
									path="create"
									element={
										<Suspense fallback={<Loader />}>
											<CustomerAccountCreation />
										</Suspense>
									}
								/>
								<Route
									path="create-plans"
									element={
										<Suspense fallback={<Loader />}>
											<CreatePlan />
										</Suspense>
									}
								/>
								<Route
									path="setting"
									element={
										<Suspense fallback={<Loader />}>
											<Setting />
										</Suspense>
									}
								/>

								{/** SuperAdmin login as Admin Routes */}
								<Route path="admin/:organizationId" element={<AdminPanel />} />
								<Route
									path="admin/:organizationId/choose-opening-type"
									element={<OpeningType />}
								/>
								<Route
									path="admin/:organizationId/create-opening-from-jd/:openingType"
									element={<CreateUpdateOpeningWithJD />}
								/>
								<Route
									path="admin/:organizationId/create-opening-from-jd/update/:openingType/:openingId"
									element={<CreateUpdateOpeningWithJD updateOpening={true} />}
								/>
								<Route
									path="admin/:organizationId/evaluations/:openingId"
									element={<TabularOpeningEvaluations />}
								/>
								<Route
									path="onGoing"
									element={
										<Suspense fallback="Loading...">
											<TabularOpeningOnGoingInterviews />
										</Suspense>
									}
								/>
								<Route
									path="recentInterviews"
									element={
										<Suspense fallback="Loading...">
											<TabularOpeningRecentInterviews />
										</Suspense>
									}
								/>
								<Route
									path="logs/:interviewReportId"
									element={
										<Suspense fallback="Loading...">
											<DisplayLogs />
										</Suspense>
									}
								/>
							</Route>
							<Route path="/admin" element={<BaseAdminNavPage />}>
								<Route
									index
									element={
										<RequireAuth>
											<AdminPanel />
										</RequireAuth>
									}
								/>
								<Route
									path="evaluations/:openingId"
									element={
										<RequireAuth>
											<TabularOpeningEvaluations />
										</RequireAuth>
									}
								/>
								{/* <Route
								path='messages/:interviewReportId'
								element={<InterviewMessages />}
							/> */}
								<Route
									path="proctor/:interviewReportId"
									element={
										<RequireAuth>
											<Proctor />
										</RequireAuth>
									}
								/>
								<Route
									path="choose-opening-type"
									element={
										<RequireAuth>
											<OpeningType />
										</RequireAuth>
									}
								/>
								<Route
									path="billing/"
									element={
										<RequireAuth>
											<BillingDashboard />
										</RequireAuth>
									}
								/>
								<Route
									path="create-opening-from-jd/:openingType"
									element={
										<RequireAuth>
											<CreateUpdateOpeningWithJD />
										</RequireAuth>
									}
								/>
								<Route
									path="create-opening-from-jd/update/:openingType/:openingId"
									element={
										<RequireAuth>
											<CreateUpdateOpeningWithJD updateOpening={true} />
										</RequireAuth>
									}
								/>

								<Route
									path="create-markdown-tables"
									element={
										<RequireAuth>
											<CreateMarkdownWithGPT />
										</RequireAuth>
									}
								/>
								<Route
									path="question-bank"
									element={
										<RequireAuth>
											<QuestionBank />
										</RequireAuth>
									}
								/>
							</Route>
							<Route
								path="/admin/evaluation/:interviewReportId"
								element={
									<RequireAuth>
										<InterviewReport />
									</RequireAuth>
								}
							/>
							<Route path="*" element={<NotFound />} />
						</Routes>
					</BrowserRouter>
				</StyledEngineProvider>
				<ToastContainer theme="light" />
				<ConfirmationDialogContainer />
			</ErrorBoundary>
		</>
	);
}
