import * as React from "react";
import { Button, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import { Cancel, Event } from "@mui/icons-material";

import SystemEventsRenderer from "./events/SystemEventsRenderer";
import CustomEventsRenderer from "./events/CustomEventsRenderer";

const EventsModal = ({ openingId, customFields = [] }) => {
	const [modalState, setModalState] = useState({
		openEventModal: false,
		panel: 0,
	});
	const [fields, _] = useState([
		{
			value: "CandidateId",
			disabled: false,
			type: "report", // used while showing columns in criteria modal in actions
			variant: "text", // used while rendering input fields in criteria modal in actions
			operatorType: "text", // used while rendering operator dropdown in criteria modal in actions
			enabledFor: ["system", "custom"],
		},
		{
			value: "Name",
			disabled: false,
			type: "report",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom", "criteria", "eventType3"],
		},
		{
			value: "Email",
			disabled: false,
			type: "report",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom", "criteria", "eventType3"],
		},
		{
			value: "PhoneNumber",
			disabled: false,
			type: "report",
			variant: "number",
			operatorType: "number",
			enabledFor: ["system", "custom", "criteria", "eventType3"],
		},
		{
			value: "Experience",
			disabled: false,
			type: "report",
			variant: "number",
			operatorType: "number",
			enabledFor: ["system", "custom", "criteria", "eventType3"],
		},
		{
			value: "Score",
			disabled: false,
			type: "report",
			variant: "number",
			operatorType: "number",
			enabledFor: ["system", "custom", "criteria"],
		},
		{
			value: "TrustScore",
			disabled: false,
			type: "report",
			variant: "number",
			operatorType: "number",
			enabledFor: ["system", "custom", "criteria"],
		},
		{
			value: "InterviewDate",
			disabled: false,
			type: "report",
			variant: "date",
			operatorType: "date",
			enabledFor: ["system", "custom"],
		},
		{
			value: "ScheduleAsString",
			disabled: false,
			type: "report",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "ScheduleAsDate",
			disabled: false,
			type: "report",
			variant: "date",
			operatorType: "date",
			enabledFor: ["system", "custom", "criteria"],
		},
		{
			value: "InterviewStatus",
			disabled: false,
			type: "report",
			variant: "dropdown",
			operatorType: "text",
			enabledFor: ["system", "custom", "criteria", "eventType3"],
			options: [
				{
					value: "Candidate Terminated Prematurely",
					disabled: false,
				},
				{
					value: "Interview Completed",
					disabled: false,
				},
				{
					value: "Interview Scheduled",
					disabled: false,
				},
				{
					value: "Interview Not Started",
					disabled: false,
				},
				{
					value: "Interview Cancelled",
					disabled: false,
				},
			],
		},
		{
			value: "CreatedByAdmin",
			disabled: false,
			type: "report",
			variant: "boolean",
			operatorType: "boolean",
			enabledFor: ["system", "custom", "criteria"],
			options: [
				{
					value: "true",
					disabled: false,
				},
				{
					value: "false",
					disabled: false,
				},
			],
		},
		{
			value: "Recording",
			disabled: false,
			type: "report",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "EvaluationUrl",
			disabled: false,
			type: "report",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "Resume",
			disabled: false,
			type: "report",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom", "criteria", "eventType3"],
		},
		...customFields.map((el) => {
			let variant = "text";
			let options = [];
			switch (Number(el.type)) {
				case 0:
					variant = "text";
					break;
				case 1:
					variant = "dropdown";
					el.config?.dropDownValues?.forEach((val) => {
						options.push({
							value: val.id,
							id: val.id,
							label: val.value,
							disabled: false,
						});
					});
					break;
				default:
					variant = "unknown";
					break;
			}
			let field = {
				value: el._id,
				label: el.fieldName,
				disabled: false,
				id: el._id,
				type: "report",
				variant: variant,
				operatorType: "text",
				enabledFor: ["system", "custom", "criteria", "eventType3"],
			};
			if (variant === "dropdown") {
				field.options = options;
			}
			return field;
		}),
		{ value: "Divider", disabled: true, isDivider: true, enabledFor: ["system", "custom"] },
		{
			value: "OpeningId",
			disabled: false,
			type: "opening",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "InterviewUrl",
			disabled: false,
			type: "opening",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "OpeningTitle",
			disabled: false,
			type: "opening",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "supportName",
			disabled: false,
			type: "opening",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "SupportEmail",
			disabled: false,
			type: "opening",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "SupportPhone",
			disabled: false,
			type: "opening",
			variant: "number",
			operatorType: "number",
			enabledFor: ["system", "custom"],
		},
		{ value: "Divider", disabled: true, isDivider: true, enabledFor: ["system", "custom"] },
		{
			value: "AdminEmail",
			disabled: false,
			type: "global",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "AdminUsername",
			disabled: false,
			type: "global",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "OrganizationName",
			disabled: false,
			type: "global",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
		{
			value: "CurrentDateTime",
			disabled: false,
			type: "global",
			variant: "text",
			operatorType: "text",
			enabledFor: ["system", "custom"],
		},
	]);

	const eventPanelRenderer = () => {
		switch (modalState.panel) {
			case 0:
				return (
					<SystemEventsRenderer openingId={openingId} fields={fields} customFields={customFields} />
				);
			case 1:
				return (
					<CustomEventsRenderer openingId={openingId} fields={fields} customFields={customFields} />
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Button
				className="normal-case rounded-md focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between"
				sx={{
					backgroundColor: "#10B981",
					":hover": {
						backgroundColor: "#047857",
					},
				}}
				onClick={() => {
					setModalState({
						openEventModal: true,
						panel: 0,
					});
				}}
			>
				<Event className="pr-2" />
				Add Event
			</Button>
			{modalState.openEventModal && (
				<Modal
					open={modalState.openEventModal}
					onClose={() => {
						setModalState({
							openEventModal: false,
							panel: 0,
						});
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<div
						className="bg-white p-6 rounded-lg shadow-lg flex"
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "85vw",
							height: "85vh",
							bgcolor: "background.paper",
							boxShadow: 24,
						}}
					>
						<div className="flex align-middle justify-between pl-2 absolute top-[-2.2rem] w-full left-0 rounded">
							<div className="flex justify-center align-middle gap-1">
								<Button
									size="small"
									variant="contained"
									className={modalState.panel != "0" ? "bg-gray-400" : "scale-110 z-10"}
									onClick={() =>
										setModalState({
											openEventModal: true,
											panel: 0,
										})
									}
								>
									System Events
								</Button>
								<Button
									size="small"
									variant="contained"
									className={modalState.panel != "1" ? "bg-gray-400" : "scale-110 z-10"}
									onClick={() =>
										setModalState({
											openEventModal: true,
											panel: 1,
										})
									}
								>
									Custom Events
								</Button>
							</div>
							<IconButton
								onClick={() => {
									setModalState({
										openEventModal: false,
										panel: 0,
									});
								}}
								className="text-white cursor-pointer"
							>
								<Cancel />
							</IconButton>
						</div>
						{eventPanelRenderer()}
					</div>
				</Modal>
			)}
		</>
	);
};

export default EventsModal;
