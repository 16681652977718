import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";

const MeetingLobby = (props) => {
	const [isCameraOn, setIsCameraOn] = useState(false);
	const [isMicOn, setIsMicOn] = useState(false);

	return (
		<Box className="flex flex-col justify-center items-center">
			{props.isMeetingActive ? (
				<Box className="flex flex-col justify-center items-center">
					{props.candidateName && props.openingTitle && (
						<Box className="flex flex-col justify-center items-center">
							<Box className="flex flex-row justify-center items-center gap-[5px]">
								<Typography variant="subtitle1" className="text-center  font-semibold">
									Candidate's Name:
								</Typography>
								<Typography
									variant="subtitle1"
									className="text-center font-semibold text-blue-600"
								>
									{props.candidateName}
								</Typography>
							</Box>
							<Box className="flex flex-row justify-center items-center gap-[5px]">
								<Typography variant="subtitle1" className="text-center  font-semibold">
									Role:
								</Typography>
								<Typography
									variant="subtitle1"
									className="text-center font-semibold text-blue-600"
								>
									{props.openingTitle}
								</Typography>
							</Box>
						</Box>
					)}
					<Box className="flex flex-row justify-center gap-[5px] items-center">
						<Typography variant="subtitle1" className="text-center  font-semibold">
							Meeting Status:
						</Typography>
						<Typography variant="subtitle1" className="text-center text-blue-600 font-semibold">
							Active
						</Typography>
					</Box>
				</Box>
			) : (
				<Typography variant="subtitle1" className="text-center text-blue-600 font-semibold">
					No meeting in progress.
				</Typography>
			)}
			{props.isMeetingActive && (
				<Box className="flex flex-col justify-center items-center w-full">
					<Box className="flex justify-center items-center my-2">
						<Button
							className={`px-4 py-2 mx-1 rounded-lg bg-blue-600 text-white hover:bg-blue-500 hover:text-white`}
							onClick={() => setIsCameraOn(!isCameraOn)}
						>
							{isCameraOn ? (
								<VideocamIcon fontSize="small" />
							) : (
								<VideocamOffIcon fontSize="small" />
							)}
						</Button>
						<Button
							className={`px-4 py-2 mx-1 rounded-lg bg-blue-600 text-white hover:bg-blue-500 hover:text-white`}
							onClick={() => setIsMicOn(!isMicOn)}
						>
							{isMicOn ? <MicIcon fontSize="small" /> : <MicOffIcon fontSize="small" />}
						</Button>
					</Box>
					<Button
						className="px-4 py-2 mx-auto block bg-blue-600 text-white rounded-lg hover:bg-blue-500 text-center normal-case"
						onClick={() => props.onJoinClick(isCameraOn, isMicOn)}
					>
						Join Meeting
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default MeetingLobby;
