import React from "react";
import { clientLogger } from "../utilities/loggingApi";
import RefreshIcon from "@mui/icons-material/Refresh";
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log("Some error was caught here");
		console.log(error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.log("An error occured");
		console.log(error);
		console.log(errorInfo.componentStack);
		const url = new URL(window.location.href);
		console.log(url.pathname.split("/").at(-1));
		const path = url?.pathname?.split("/")?.at(-1);
		clientLogger(error.message, {
			level: "error",
			logStreamName: path ?? "Error_logs",
			componentStack: errorInfo.componentStack?.toString(),
			error: error.toString(),
		});
	}

	render() {
		if (this.state.hasError) {
			return <ErrorFallback interviewPage={this.props.interviewPage} />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;

const ErrorFallback = ({ interviewPage }) => {
	return (
		<main className="min-h-screen bg-gray-200 flex flex-row justify-center items-center">
			<div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3 mt-10 mx-auto flex flex-col items-center gap-3">
				<h1 className="text-center font-medium text-2xl">
					Attention: {interviewPage ? "Interview Session Interrupted" : "Something went wrong"}
				</h1>
				{interviewPage && (
					<h2 className="text-center text-xl font-semibold">
						Oops! It seems something unexpected has occurred during your interview session. Not to
						worry though, your progress is safely saved. Please attempt to refresh your browser to
						resolve the issue. If the problem persists, feel free to reach out to the support email
						address provided in your email or to support@zinterview.ai for further assistance.
					</h2>
				)}
				<button
					onClick={() => {
						window.location.reload();
					}}
					className="cursor-pointer gap-1 flex flex-row justify-center items-center mx-auto bg-blue-500 text-white font-normal text-sm px-4 py-2 rounded outline-none border-none  focus:bg-blue-600 hover:bg-blue-600"
				>
					Refresh <RefreshIcon className="size-6 text-white m-0 p-0" />
				</button>
			</div>
		</main>
	);
};
