import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./SuperAdmin/Navbar";
import { useNavHeight } from "../custom-hooks/useNavHeight";

const BaseSuperAdminNavPage = () => {
	useNavHeight("super-admin-nav-bar");
	return (
		<>
			<Box
				className=" bg-gray-100"
				style={{
					paddingBottom: "var(--navbar-height, 78px)",
				}}
			>
				<Navbar />
			</Box>
			<Outlet />
		</>
	);
};

export default BaseSuperAdminNavPage;
