import React, { useEffect, useState } from "react";
import customEvent3 from "../../assets/customEvent3.svg";
import sourceCode from "../../assets/source_code.svg";
import {
	getCustomEventsAndActions,
	saveCustomEvent,
	updateCustomEvent,
	deleteCustomEvent,
	getCustomTriggers,
	executeCustomEvent,
} from "../../utilities/eventsApi.js";
import { toast } from "react-toastify";
import {
	Button,
	Chip,
	CircularProgress,
	Divider,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { API_FORM_FORMAT, EMAIL_TEMPLATE } from "./constants.js";
import APIFormRenderer from "./actions/APIFormRenderer.jsx";
import EmailFormRenderer from "./actions/EmailFormRenderer.jsx";
import { renderConfirmationDialog } from "../confirmation/ConfirmationDialog.js";

const apiFormFormat = { ...API_FORM_FORMAT };

const CustomEventsRenderer = ({ openingId, fields = [], customFields = [] }) => {
	const [eventTemplate, setEventTemplate] = useState({
		eventName: "",
		eventLabel: "",
	});
	const [loading, setLoading] = useState(false);
	const [apiFormLoading, setApiFormLoading] = useState({
		state: "",
		status: false,
	});

	const [loadingActions, setLoadingActions] = useState(false);

	const [openCustomEventModal, setOpenCustomEventModal] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [selectedAction, setSelectedAction] = useState(null);
	const [actionData, setActionData] = useState([]);
	const [customEventsAndActions, setCustomEventsAndActions] = useState({
		customEvents: [],
		actions: [],
	});
	const [allCustomActions, setAllCustomActions] = useState({});

	const selectCustomEvent = (event) => {
		setSelectedEvent(event);
		getAndPopulateActions(event._id);
	};

	const processCustomActions = (events = null) => {
		let actions = events.reduce((acc, el) => {
			acc[el.action] = acc[el.action] || [];
			acc[el.action].push(el);
			return acc;
		}, {});
		setAllCustomActions(actions);
	};

	const populateFields = (action) => {
		let actions = allCustomActions[action.type_id];
		switch (Number(action.type_id)) {
			case 1:
			case 3:
			case 2:
				setActionData(actions || []);
				break;
			default:
				setActionData([]);
				break;
		}
	};

	const getAndPopulateActions = async (eventId) => {
		setActionData([]);
		try {
			setLoadingActions(true);
			let response = await getCustomTriggers({ keyword: eventId, openingId, event: eventId });
			if (response && response.data) {
				let allActionForAnEvent = response.data.map((el) => {
					let action = JSON.parse(el.actionData);
					action.id = el._id;
					action.isPaused = el.isPaused;
					action.action = el.actionTypeId;
					return action;
				});
				processCustomActions(allActionForAnEvent);
			}
		} catch (error) {
			console.log(error);
			toast.error("Error fetching custom actions");
		} finally {
			setLoadingActions(false);
		}
	};

	const getAndSetCustomEventsAndAction = async () => {
		setLoading(true);
		try {
			let response = await getCustomEventsAndActions({ openingId });
			if (response && response.data) {
				let events = response.data.events?.map((el) => {
					return el;
				});
				let actions = response.data.actions?.map((el) => {
					return {
						value: el.actionName,
						id: el._id,
						type_id: el.typeId,
						isDisabled: el.isDisabled
							? true
							: el.enabledFor?.includes("CUSTOM_EVENTS")
								? false
								: true,
					};
				});
				setCustomEventsAndActions({
					customEvents: events,
					actions: actions,
				});
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const hanldeOpenAddCustomEventModal = () => {
		setOpenCustomEventModal(true);
		setSelectedAction(null);
	};

	const onActionSelection = (action) => {
		setSelectedAction(action);
		populateFields(action);
	};

	const saveCustomEventHanlder = async () => {
		if (eventTemplate.eventName.length === 0) {
			toast.error("Event name is required");
			return;
		}
		if (eventTemplate.eventLabel.length === 0) {
			toast.error("Event label is required");
			return;
		}
		try {
			let resp = await saveCustomEvent({
				eventName: eventTemplate.eventName,
				eventLabel: eventTemplate.eventLabel,
				openingId: openingId,
				additionalData: "",
			});
			if (resp.data?.event) {
				setCustomEventsAndActions((prev) => {
					return {
						customEvents: [...prev.customEvents, resp.data.event],
						actions: prev.actions,
					};
				});
				onModalClose();
				toast.success("Custom event saved successfully");
			} else {
				toast.error("Error saving custom event");
			}
		} catch (error) {
			toast.error("Error saving custom event");
			console.log(error);
		}
	};

	const updateCusomtEventHandler = async () => {
		if (eventTemplate.eventName.length === 0) {
			toast.error("Event name is required");
			return;
		}
		if (eventTemplate.eventLabel.length === 0) {
			toast.error("Event label is required");
			return;
		}
		try {
			let resp = await updateCustomEvent({
				customEventId: eventTemplate._id,
				eventName: eventTemplate.eventName,
				eventLabel: eventTemplate.eventLabel,
			});
			if (resp.data?.event) {
				let updatedEvents = customEventsAndActions.customEvents.map((event) => {
					if (event._id === eventTemplate._id) {
						return resp.data.event;
					}
					return event;
				});
				setCustomEventsAndActions((prev) => {
					return {
						customEvents: updatedEvents,
						actions: prev.actions,
					};
				});
				onModalClose();
				toast.success("Custom event saved successfully");
			} else {
				toast.error("Error saving custom event");
			}
		} catch (error) {
			toast.error("Error updating custom event");
			console.log(error);
		}
	};

	const onDeleteCustomEvent = async (customEventId, index) => {
		try {
			let resp = await deleteCustomEvent({ customEventId });
			let copy = customEventsAndActions.customEvents.find((event) => event._id == customEventId);
			if (resp.data?.message) {
				let updatedEvents = customEventsAndActions.customEvents.filter(
					(event) => event._id !== customEventId,
				);
				setCustomEventsAndActions((prev) => {
					return {
						customEvents: updatedEvents,
						actions: prev.actions,
					};
				});
				toast.success("Custom event deleted successfully");
			} else {
				let eventsCopy = [...customEventsAndActions.customEvents];
				eventsCopy.splice(index, 1, copy);
				setCustomEventsAndActions((prev) => {
					return {
						customEvents: eventsCopy,
						actions: prev.actions,
					};
				});
				toast.error("Error deleting custom event");
			}
		} catch (error) {
			toast.error("Error deleting custom event");
			console.log(error);
		}
	};

	const onEdit = (event, index) => {
		setEventTemplate(event);
		setOpenCustomEventModal(true);
	};

	const onModalClose = () => {
		setEventTemplate({
			eventName: "",
			eventLabel: "",
		});
		setOpenCustomEventModal(false);
	};

	const executeEvent = async (eventId) => {
		try {
			let response = await executeCustomEvent({ openingId, event: eventId });
			if (response && response.data) {
				toast.success("Event executed successfully");
			} else {
				toast.error("Error executing event");
			}
		} catch (error) {
			toast.error("Error executing event");
		}
	};

	const handleAddAction = () => {
		switch (selectedAction.type_id) {
			case 3:
			case 1:
				setActionData([...actionData, JSON.parse(JSON.stringify(apiFormFormat))]);
				break;
			case 2:
				setActionData([...actionData, JSON.parse(JSON.stringify(EMAIL_TEMPLATE))]);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		getAndSetCustomEventsAndAction();
	}, []);

	const addCustomEventModalRenderer = () => {
		if (!openCustomEventModal) return null;
		return (
			<Modal
				open={openCustomEventModal}
				onClose={onModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div
					className="bg-white p-6 rounded-lg shadow-lg flex"
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						// width: "85vw",
						// height: "85vh",
						bgcolor: "background.paper",
						boxShadow: 24,
					}}
				>
					<div className="flex w-full flex-col">
						<Typography variant="h6" id="modal-modal-title">
							Add Custom Event
						</Typography>
						<Divider className="w-full mt-2 mb-2" />
						<div className="flex justify-between items-center ">
							<div className="flex flex-col gap-2 min-w-[15ch]">
								<TextField
									size="small"
									label="Event Name"
									variant="outlined"
									value={eventTemplate.eventName}
									onChange={(e) => {
										setEventTemplate({ ...eventTemplate, eventName: e.target.value });
									}}
								/>
								<TextField
									size="small"
									label="Event Label"
									variant="outlined"
									value={eventTemplate.eventLabel}
									onChange={(e) => {
										if (e.target.value.length > 30) {
											toast.error("Event label should be less than 30 characters");
											return;
										}
										setEventTemplate({ ...eventTemplate, eventLabel: e.target.value });
									}}
								/>
							</div>
							<Divider orientation="vertical" className="pl-2 mr-2" flexItem />
							<div className="flex justify-center items-center w-full">
								Preview:{" "}
								<Button variant="contained" className="min-w-[10ch] ml-1">
									{eventTemplate.eventLabel.length > 0 ? eventTemplate.eventLabel : "_"}
								</Button>
							</div>
						</div>
						<Divider className="w-full mt-2 mb-2" />
						<div className="flex justify-end mt-1">
							<Button
								variant="contained"
								onClick={
									eventTemplate._id ? updateCusomtEventHandler : saveCustomEventHanlder
								}
							>
								{eventTemplate._id ? "Update" : "Save"}
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	};

	const getComponentForSelectedAction = () => {
		switch (selectedAction.type_id) {
			case 1:
				return (
					<APIFormRenderer
						apiForm={actionData}
						setApiForm={setActionData}
						apiFormFormat={apiFormFormat}
						selectedEventId={selectedEvent._id}
						selectedActionId={selectedAction.type_id}
						loading={apiFormLoading}
						setLoading={setApiFormLoading}
						fields={fields}
						customFields={customFields}
						// handleResponse={true}
						// disabledOperatorTypes={["Column"]}
						openingId={openingId}
						forCustomEvent={true}
					/>
				);
			case 3:
				return (
					<APIFormRenderer
						apiForm={actionData}
						setApiForm={setActionData}
						apiFormFormat={apiFormFormat}
						selectedEventId={selectedEvent._id}
						selectedActionId={selectedAction.type_id}
						loading={apiFormLoading}
						setLoading={setApiFormLoading}
						fields={fields}
						customFields={customFields}
						handleResponse={true}
						// disabledOperatorTypes={["Column"]}
						openingId={openingId}
						forCustomEvent={true}
					/>
				);
			case 2:
				return (
					<EmailFormRenderer
						openingId={openingId}
						fields={fields}
						// loading={loading}
						// setLoading={setLoading}
						actionData={actionData}
						setActionData={setActionData}
						onClickAddAction={handleAddAction}
						selectedEventId={selectedEvent._id}
						selectedActionId={selectedAction.type_id}
						forCustomEvent={true}
						afterDelete={() => {}}
						afterSave={() => {}}
						afterUpdate={() => {}}
					/>
				);
			default:
				return null;
		}
	};

	const noActionFoundBanner = () => {
		return (
			<div className="flex flex-col h-full w-full justify-center items-center">
				<img
					src={sourceCode}
					alt="Create Custom Events"
					className="max-h-[50%] max-w-[40%] object-contain"
				/>
				<p className="text-gray-500 mt-4">
					<Button
						variant="text"
						className="p-1 font-semibold cursor-pointer hover:scale-110 transition-all mt-4"
						size="medium"
						onClick={() => handleAddAction()}
					>
						Add action
					</Button>
				</p>
			</div>
		);
	};

	const selectedActionRenderer = () => {
		return (
			<div className="flex flex-col w-full h-[100%]">
				<div className="flex">
					<p className="text-sky-700 text-sm">
						<span
							className="cursor-pointer"
							onClick={() => {
								setSelectedAction(null);
							}}
						>
							{selectedEvent.eventName}
						</span>{" "}
						{" > "} <span className="underline">{selectedAction.value}</span>
					</p>
				</div>
				<Divider className="mt-2 mb-2" />

				{actionData?.length > 0 ? getComponentForSelectedAction() : noActionFoundBanner()}
			</div>
		);
	};

	const customEventsHeader = () => {
		return (
			<>
				{addCustomEventModalRenderer()}
				<div className="flex justify-between align-middle w-full">
					<Typography variant="h5" className="mb-2">
						Custom Events
					</Typography>
					<div>
						<Button
							variant="text"
							onClick={selectedAction ? handleAddAction : hanldeOpenAddCustomEventModal}
						>
							{selectedAction ? "Add Another Action" : "Add Custom Event"}
						</Button>
					</div>
				</div>
				<Divider className="mt-2 mb-2" />
			</>
		);
	};

	if (loading) {
		return (
			<div className="flex h-full w-full justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	if (customEventsAndActions.customEvents.length === 0) {
		return (
			<>
				<div className="flex flex-col h-full w-full justify-center items-center">
					<img
						src={customEvent3}
						alt="Create Custom Events"
						className="max-h-[60%] object-contain"
					/>
					<h1 className="text-2xl mt-4 ">No custom events found</h1>
					<p className="text-gray-500">
						<Button
							variant="text"
							className="p-1 font-semibold"
							size="small"
							onClick={hanldeOpenAddCustomEventModal}
						>
							Create
						</Button>
						custom events to see them here
					</p>
				</div>
				{addCustomEventModalRenderer()}
			</>
		);
	}

	return (
		<div className="w-full">
			{customEventsHeader()}
			<div className="flex h-[90%] overflow-auto">
				{selectedAction ? (
					selectedActionRenderer()
				) : (
					<>
						<div className="flex flex-col overflow-auto flex-1">
							{customEventsAndActions.customEvents.map((event, index) => {
								return (
									<EventRow
										key={index}
										event={event}
										index={index}
										onEdit={onEdit}
										onDeleteCustomEvent={onDeleteCustomEvent}
										selectedEvent={selectedEvent}
										selectCustomEvent={selectCustomEvent}
										executeEvent={executeEvent}
									/>
								);
							})}
						</div>
						<Divider orientation="vertical" className="ml-2 mr-2" />
						<div className="flex-1">
							<CustomEventActions
								selectedEvent={selectedEvent}
								selectedAction={selectedAction}
								onActionSelection={onActionSelection}
								actions={customEventsAndActions.actions}
								allCustomActions={allCustomActions}
								loadingActions={loadingActions}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const EventRow = ({
	event,
	index,
	onEdit,
	onDeleteCustomEvent,
	selectCustomEvent,
	selectedEvent,
	// executeEvent,
}) => {
	const [isIconVisible, setIsIconVisible] = useState(false);

	return (
		<div
			key={index}
			className="flex justify-start p-2"
			onMouseEnter={() => setIsIconVisible(true)}
			onMouseLeave={() => setIsIconVisible(false)}
		>
			<Tooltip title={event.eventLabel.length > 23 ? event.eventLabel : ""}>
				<Button
					id={event._id}
					variant="contained"
					className="mr-2 max-w-[25ch] text-ellipsis overflow-hidden"
					disabled={selectedEvent?._id === event._id}
					onClick={() => {
						selectCustomEvent(event);
					}}
				>
					{event.eventLabel.length > 23 ? event.eventLabel.slice(0, 20) + "..." : event.eventLabel}
				</Button>
			</Tooltip>
			{/* <IconButton
				size="small"
				className={isIconVisible ? "opacity-100" : "opacity-0"}
				onClick={(e) => {
					e.stopPropagation();
					executeEvent(event._id);
				}}
			>
				<Tooltip title="Execute Event">
					<Check color="primary" />
				</Tooltip>
			</IconButton> */}
			<IconButton
				size="small"
				className={isIconVisible ? "opacity-100" : "opacity-0"}
				onClick={(e) => {
					e.stopPropagation();
					onEdit(event, index);
				}}
			>
				<Edit color="primary" />
			</IconButton>
			<IconButton
				size="small"
				className={isIconVisible ? "opacity-100" : "opacity-0"}
				onClick={(e) => {
					e.stopPropagation();
					renderConfirmationDialog(
						"Are you sure you want to delete this event?",
						() => {},
						() => onDeleteCustomEvent(event._id, index),
						true,
					);
				}}
			>
				<Delete color="error" />
			</IconButton>
		</div>
	);
};

const CustomEventActions = ({
	selectedEvent,
	selectedAction,
	onActionSelection,
	actions,
	allCustomActions = [],
	loadingActions,
}) => {
	if (!selectedEvent) {
		return <></>;
	}

	return (
		<div className="flex flex-col items-start">
			{actions.map((action, index) => {
				let count = allCustomActions[action.type_id]?.length || 0;
				return (
					<div key={index} className="flex gap-1 justify-start items-center">
						<Button
							key={index}
							variant="text"
							disabled={action.isDisabled || loadingActions}
							className="my-1"
							size="small"
							onClick={() => {
								onActionSelection(action);
							}}
						>
							{action.value}
						</Button>
						{count != 0 && <Chip size="small" label={count} />}
					</div>
				);
			})}
		</div>
	);
};

export default CustomEventsRenderer;
