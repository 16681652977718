import html2pdf from "html2pdf.js";
import { downloadEvaluationReportDocx } from "../../utilities/evaluation-report-api";
import { useState } from "react";
import { toast } from "react-toastify";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Button, Menu, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";

export const DownloadEvaluationReportMenu = ({ isDownloading, setIsDownloading, fileName }) => {
	const { interviewReportId } = useParams();
	const [anchorEl, setAnchorEl] = useState(null);
	const isOpen = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const downloadPdf = () => {
		handleClose();
		let s = fileName + ".pdf";
		const options = {
			margin: 10,
			filename: s,
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
		};

		const Root = document.getElementById("root");
		html2pdf().set({
			pagebreak: { mode: ["avoid-all", "css", "legacy"] },
		});
		setIsDownloading(true);
		setTimeout(() => {
			html2pdf(Root, options);
			setIsDownloading(false);
		}, 500);
	};

	const downloadDocx = async () => {
		try {
			handleClose();
			setIsDownloading(true);
			await downloadEvaluationReportDocx(interviewReportId, fileName + ".docx");
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setIsDownloading(false);
		}
	};

	return (
		<>
			{!isDownloading && (
				<Button
					id="download-menu-btn"
					aria-controls={isOpen ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={isOpen ? "true" : undefined}
					onClick={handleClick}
				>
					<SimCardDownloadIcon style={{ color: "#581C87", fontSize: "20px" }} />
				</Button>
			)}

			<Menu
				id="download-menu"
				anchorEl={anchorEl}
				open={isOpen}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "download-menu-btn",
				}}
			>
				<MenuItem onClick={downloadPdf}>Download as Pdf</MenuItem>
				<MenuItem onClick={downloadDocx}>Download as Docx</MenuItem>
			</Menu>
		</>
	);
};
