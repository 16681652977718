import { Pause, PlayArrow } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { pauseTrigger } from "../../../utilities/eventsApi";
import { toast } from "react-toastify";

const PausePlayAction = ({ action, allActions, setActionData, index, icon = true }) => {
	if (!action.id) {
		return null;
	}

	const onPlayClick = async (apiIndex) => {
		let apiFormCopy = [...allActions];
		apiFormCopy[apiIndex].isPaused = false;
		setActionData(apiFormCopy);
		try {
			let resp = await pauseTrigger({ triggerId: action.id });
			if (resp && resp.data) {
				toast.success("Action Resumed");
			}
		} catch (error) {
			console.log(error);
			toast.error("Error resuming action");
			setActionData(allActions);
		}
	};

	const onPauseClick = async (apiIndex) => {
		let apiFormCopy = [...allActions];
		apiFormCopy[apiIndex].isPaused = true;
		setActionData(apiFormCopy);
		try {
			let resp = await pauseTrigger({ triggerId: allActions[apiIndex].id });
			if (resp && resp.data) {
				toast.success("Action Paused");
			}
		} catch (error) {
			console.log(error);
			toast.error("Error pausing action");
			setActionData(allActions);
		}
	};

	return action.isPaused ? (
		<Tooltip title="Resume Action Execution">
			{icon ? (
				<IconButton
					onClick={() => {
						onPlayClick(index);
					}}
					className="bg-sky-100"
				>
					<PlayArrow color="primary" />
				</IconButton>
			) : (
				<Button
					variant="outlined"
					onClick={() => {
						onPlayClick(index);
					}}
					className="bg-sky-100"
				>
					Resume
				</Button>
			)}
		</Tooltip>
	) : (
		<Tooltip title="Pause Action Execution">
			{icon ? (
				<IconButton
					onClick={() => {
						onPauseClick(index);
					}}
					className="bg-orange-100"
				>
					<Pause color="warning" />
				</IconButton>
			) : (
				<Button
					color="warning"
					variant="outlined"
					onClick={() => {
						onPauseClick(index);
					}}
					className="bg-orange-100"
				>
					Pause
				</Button>
			)}
		</Tooltip>
	);
};

export default PausePlayAction;
