import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./app/store";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material";
import ErrorBoundary from "./components/ErrorBoundary";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
	gtmId: "GTM-T82HWDGZ",
};

TagManager.initialize(tagManagerArgs);
const rootElement = document.getElementById("root");

const theme = createTheme({
	components: {
		MuiPopover: {
			defaultProps: {
				container: rootElement,
			},
		},
		MuiPopper: {
			defaultProps: {
				container: rootElement,
			},
		},
		MuiDialog: {
			defaultProps: {
				container: rootElement,
			},
		},
		MuiModal: {
			defaultProps: {
				container: rootElement,
			},
		},
	},
	palette: {
		green: {
			main: "#10B981",
		},
		black: {
			main: "#111827",
		},
		purple: {
			main: "#553c9a",
		},
		orange: {
			main: "#F59E0B",
		},
		red: {
			main: "#EF4444",
		},
		white: {
			main: "#ffffff",
		},
	},

	typography: {
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
	<ErrorBoundary>
		<Provider store={store}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</StyledEngineProvider>
		</Provider>
	</ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
