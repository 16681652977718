import { useEffect, useState } from "react";
import { Button, TextField, Box, Checkbox, CircularProgress } from "@mui/material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, reset } from "../features/auth/authSlice";
import { toast } from "react-toastify";
import logo from "../assets/zi-favicon.png";
import OTPModal from "./OTPModal";

const initialUserData = {
	email: "",
	password: "",
};

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
	const [userData, setUserData] = useState(initialUserData);
	const [isPwdVisible, setIsPwdVisible] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [isOtpOpen, setIsOtpOpen] = useState(false);

	useEffect(() => {
		if (isError) {
			if (message === "OTP-REQUIRED") {
				setIsOtpOpen(true);
			} else {
				toast.error(message);
			}
		}
	}, [message, isError, dispatch, isSuccess]);

	useEffect(() => {
		if (user) {
			dispatch(reset());
			const a = location.state?.path;
			const b = location.state?.search;
			const c = a && b ? `${a}${b}` : a ? `${a}` : "/admin";
			navigate(c);
		}
	}, [user, navigate, dispatch, location]);

	const isValidEmail = (email) => {
		const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
		return emailRegex.test(email);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (isLoading) {
			return;
		}
		const { email, password } = userData;
		const missingFields = {
			email: !email.trim(),
			password: !password.trim(),
		};
		let warnCount = 0;
		for (let missingField in missingFields) {
			if (missingFields[missingField]) {
				toast.warn(`${missingField} can't be empty`);
				warnCount += 1;
			}
		}
		if (!isValidEmail(email.trim())) {
			toast.warn(`Invalid Email`);
			return;
		}
		if (warnCount) {
			return;
		}

		dispatch(
			loginUser({
				email: email.toLowerCase().trim(),
				password,
				rememberMe,
			}),
		);
	};

	const handleUserDataChange = (e) => {
		const { name, value } = e.target;
		setUserData((prev) => {
			return { ...prev, [name]: value };
		});
	};

	const { email, password } = userData;
	if (isLoading) {
		return (
			<Box className="bg-gray-100 h-screen flex flex-col justify-center items-center">
				<CircularProgress />
			</Box>
		);
	}
	return (
		<Box className="bg-gray-100 h-screen flex flex-col justify-center items-center">
			{isOtpOpen === false ? (
				<Box className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 lg:w-1/3">
					<Box className="flex justify-center mb-6">
						<img src={logo} alt="Zinterview Logo" className="h-16" />
					</Box>
					<form>
						<Box className="mb-4">
							<label htmlFor="email" className="block text-sm font-medium text-gray-600">
								Email
							</label>
							<TextField
								sx={{
									"& fieldset": {
										border: "1px solid #F2F3F5",
									},
								}}
								color="black"
								autoComplete="email"
								size="small"
								required
								type="text"
								id="email"
								name="email"
								className="mt-1 w-full border rounded-md"
								value={email}
								onChange={handleUserDataChange}
								disabled={isLoading}
							/>
						</Box>
						<Box className="relative">
							<label htmlFor="password" className="block text-sm font-medium text-gray-600">
								Password
							</label>

							<TextField
								sx={{
									"& fieldset": {
										border: "1px solid #F2F3F5",
									},
								}}
								size="small"
								color="black"
								margin="normal"
								required
								fullWidth
								name="password"
								type={isPwdVisible ? "text" : "password"}
								id="password"
								autoComplete="current-password"
								value={password}
								onChange={handleUserDataChange}
								disabled={isLoading}
								className="mt-1 w-full border rounded-md"
							/>

							<Box
								className="absolute cursor-pointer top-[60%] right-2 transform -translate-y-1/2"
								onClick={(e) => {
									e.preventDefault();
									setIsPwdVisible((prev) => !prev);
								}}
							>
								{isPwdVisible ? (
									<i className="fas fa-eye-slash"></i>
								) : (
									<i className="fas fa-eye"></i>
								)}
							</Box>
						</Box>
						<Box className="flex flex-row items-center justify-between">
							<Box className="flex flex-row items-center">
								<Checkbox
									value={rememberMe}
									color="primary"
									size="small"
									onChange={(e) => setRememberMe(e.target.checked)}
								/>
								<label
									htmlFor="remember-me"
									className="block text-sm font-medium text-gray-600"
								>
									Remember me
								</label>
							</Box>
							{/* <Link href="/preset" className="text-sm text-blue-500 no-underline hover:underline">
							Forgot Password?
						</Link> */}
						</Box>
						<Box
							onClick={handleSubmit}
							className={`w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 mt-2 normal-case ${
								isLoading && "cursor-not-allowed opacity-50"
							}`}
							type="submit"
							fullWidth
							disabled={isLoading}
							component={Button}
						>
							{isLoading ? (
								<span>
									{"Logging in... "}
									<i className="fas fa-circle-notch fa-spin mr-1"></i>
								</span>
							) : (
								<span>Log In</span>
							)}
						</Box>
						<Box className="flex justify-between items-center mb-4 p-2">
							<NavLink
								to={"/register"}
								className="text-sm text-blue-500 no-underline hover:underline"
							>
								{"Create an account"}
							</NavLink>
							<NavLink
								to={"/preset"}
								className="text-sm text-blue-500 no-underline hover:underline pl-4"
							>
								{"Forgot Password?"}
							</NavLink>
						</Box>
					</form>
				</Box>
			) : (
				<>
					<OTPModal
						open={isOtpOpen}
						handleClose={() => setIsOtpOpen(false)}
						email={email}
						rememberMe={rememberMe}
					/>
				</>
			)}
		</Box>
	);
};

export default Login;
