import { SELECT_ALL_CANDIDATE, SELECT_CANDIDATE, UNSELECT_ALL_CANDIDATE, UNSELECT_CANDIDATE } from "./types";

export const selectCandidate = (candidate) => (dispatch) => {
    dispatch({
        type: SELECT_CANDIDATE,
        payload: candidate,
    });
}

export const unselectCandidate = (candidate) => (dispatch) => {
    dispatch({
        type: UNSELECT_CANDIDATE,
        payload: candidate,
    });
}

export const selectAllCandidate = (candidates) => (dispatch) => {
    dispatch({
        type: SELECT_ALL_CANDIDATE,
        payload: candidates,
    });
}

export const unselectAllCandidate = () => (dispatch) => {
    dispatch({
        type: UNSELECT_ALL_CANDIDATE,
        payload: [],
    });
}