import clsx from "clsx";
import React from "react";
const colors = {
	emerald: "bg-emerald-500 hover:bg-emerald-700 focus-visible:bg-emerald-700 text-white",
	blue: "bg-blue-500 hover:bg-blue-700 focus-visible:bg-blue-700 text-white",
	red: "bg-red-500 hover:bg-red-700 focus-visible:bg-red-700 text-white",
	gray: "bg-gray-200 text-gray-800",
	"text-red": "text-red-600 hover:text-red-800",
	"text-blue": "text-blue-600 hover:text-blue-800",
};

const Button = ({
	onClick,
	children,
	colorVariant,
	type = "button",
	selected = false,
	disabled = false,
	highlighted = false,
	className = "",
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			className={clsx(
				"px-3 py-2 text-xs rounded-lg outline-none border-none cursor-pointer ",
				!highlighted && !selected ? colors[colorVariant] : "",
				disabled && "disabled:opacity-50",
				selected ? "bg-blue-500 text-white" : highlighted ? "bg-blue-400 text-white" : "",
				className,
			)}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export const TextButton = ({
	onClick,
	children,
	colorVariant,
	type = "button",
	disabled = false,
	className = "",
	title = "",
}) => {
	return (
		<button
			{...(title && { title: title })}
			onClick={onClick}
			type={type}
			className={clsx(
				"text-xs bg-transparent cursor-pointer outline-none border-none p-2",
				colors[colorVariant],
				disabled && "disabled:opacity-50",
				className,
			)}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export default Button;
