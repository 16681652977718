import React, { useEffect, useRef, useState } from "react";
import "./videoTiles.css";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import StopScreenShareIcon from "@mui/icons-material/StopScreenShare";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
	ConsoleLogger,
	DefaultDeviceController,
	LogLevel,
	DefaultMeetingSession,
	MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";

import { useParams } from "react-router-dom";
import {
	updateMeetingId,
	updateConcatenationId,
	updateMeetingIds,
	updateIsLatestMeetingIdInvalid,
	updateSecondDevice,
} from "../../utilities/interviewReportsApi";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import NetworkModal from "./NetworkModal";
import { gptVision } from "../../Common";
// import { gptVision } from '../../Common';

const VideoTiles = (props) => {
	// let meetingEvents = [];
	// const { meetingEvents, setMeetingEvents } = props;
	const [isScreenShared, setIsScreenShared] = useState(false);
	const [isMicOn, setIsMicOn] = useState(false);
	const [isVideoOn, setIsVideoOn] = useState(false);
	const [isTabAudioMuted, setIsTabAudioMuted] = useState(false);
	const params = useParams();
	const [meetingSession, setMeetingSession] = useState(null);
	const [meetingId, setMeetingId] = useState("");
	const [attendeeId, setAttendeeId] = useState("");
	const [concatenationId, setConcatenationId] = useState("");
	const [attendeesSet, setAttendeesSet] = useState(new Set());
	const [meetingAlreadyStarted, setMeetingAlreadyStarted] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalAlreadyShown, setModalAlreadyShown] = useState(false);
	const [isPositionAccurate, setIsPositionAccurate] = useState(false);
	const captureIntervalId = useRef(null);
	const captureTimeoutId = useRef(null);
	const remSecondsIntervalId = useRef(null);
	const [remSecondsForSd, setRemSecondsForSd] = useState(null);

	// const attemptReconnection = async () => {
	// 	if (!meetingSession) {
	// 		console.log("No active meeting session available for reconnection");
	// 		return;
	// 	} else {
	// 		console.log("Attempting to reconnect...");
	// 		return;
	// 	}

	// 	// try {
	// 	// 	await meetingSession.audioVideo.chooseAudioInputDevice(props.audioInputDeviceId);
	// 	// 	await meetingSession.audioVideo.chooseVideoInputDevice(props.videoInputDeviceId);
	// 	// 	meetingSession.audioVideo.restartLocalVideo(() => {
	// 	// 		console.log("Local video restarted successfully");
	// 	// 	});
	// 	// 	meetingSession.audioVideo.start();
	// 	// } catch (error) {
	// 	// 	console.error("Failed to reconnect: ", error);
	// 	// 	props.setSnackbarMessage("Failed to reconnect. Please check your devices and network settings.");
	// 	// 	props.setSnackbarOpen(true);
	// 	// }
	// };

	// const reestablishStreams = async () => {
	// 	if (props.micStream && props.videoStream) {
	// 		// await meetingSession.audioVideo.startAudioInput(props.micStream);
	// 		// await meetingSession.audioVideo.startVideoInput(props.videoStream);
	// 		// console.log("Audio and video streams restarted.");
	// 		console.log("props.micStream and props.videoStream available to restart.");
	// 	} else {
	// 		console.log("No local streams available to restart.");
	// 	}
	// };

	useEffect(() => {
		const updateAudioInput = async () => {
			if (meetingSession && meetingSession.audioVideo && props.micStream) {
				try {
					// Using startAudioInput to start using the new mic stream
					await meetingSession.audioVideo.startAudioInput(props.micStream);
					// console.log("Chime audio input updated with new mic stream.");
					props.clientLogger("Chime audio input updated with new mic stream.");
				} catch (error) {
					// console.error("Error updating Chime with new mic stream:", error);
					props.clientLogger(`Error updating Chime with new mic stream: ${error}`);
				}
			}
		};

		updateAudioInput();
	}, [props.micStream]);

	const toggleMute = () => {
		// Toggle the isMuted state
		setIsTabAudioMuted(!isTabAudioMuted);

		// Get the audio element and toggle its muted property based on the isMuted state
		const audioElement = document.getElementById("audio");
		if (audioElement) {
			audioElement.muted = !isTabAudioMuted;
		}

		// Get all video elements and toggle their muted property based on the isMuted state
		const videoElements = document.querySelectorAll("video");
		videoElements.forEach((video) => {
			video.muted = !isTabAudioMuted;
		});
	};

	const handleVideo = async () => {
		if (isVideoOn) {
			await meetingSession.audioVideo.stopVideoInput();
			await meetingSession.audioVideo.stopLocalVideoTile();
			setIsVideoOn(false);
		} else {
			const videoInputs = await meetingSession.audioVideo.listVideoInputDevices();

			await meetingSession.audioVideo.startVideoInput(videoInputs[0].deviceId);
			await meetingSession.audioVideo.startLocalVideoTile();
			setIsVideoOn(true);
		}
	};

	const handleMic = async () => {
		if (isMicOn) {
			await meetingSession.audioVideo.stopAudioInput();
			await meetingSession.audioVideo.realtimeMuteLocalAudio();
			setIsMicOn(false);
		} else {
			const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
			await meetingSession.audioVideo.startAudioInput(audioInputs[0].deviceId);
			await meetingSession.audioVideo.realtimeUnmuteLocalAudio();
			setIsMicOn(true);
		}
	};

	// const getPOSTLogger = (meetingSessionConfiguration, appName, url, logLevel) => {
	// 	const options = {
	// 		url,
	// 		logLevel,
	// 		metaData: {
	// 			appName,
	// 			meetingId: meetingSessionConfiguration.meetingId,
	// 			attendeeId: meetingSessionConfiguration.credentials.attendeeId,
	// 		},
	// 	};
	// 	// console.log(`options: ${JSON.stringify(options)}`);
	// 	return new POSTLogger(options);
	// };

	// const createLogStream = async (configuration) => {
	// 	const body = JSON.stringify({
	// 		// action: 'MEETING_EVENTS',
	// 		meetingId: configuration.meetingId,
	// 		attendeeId: configuration.credentials.attendeeId,
	// 		// meetingEvents: meetingEvents,
	// 		source: `${
	// 			props.preferredName === 'Admin'
	// 				? 'Admin'
	// 				: props.preferredName === 'secondDevice'
	// 				? "Candidate's Second Device"
	// 				: 'Candidate'
	// 		}`,
	// 	});
	// 	try {
	// 		const response = await fetch(requestPath, {
	// 			method: 'POST',
	// 			body,
	// 		});
	// 		if (response.status === 200) {
	// 			console.log('log stream created');
	// 		}
	// 	} catch (error) {
	// 		//   fatal(error);
	// 		//   this.log(error.message);
	// 		console.error('Error during createLogStream:', error);
	// 	}
	// };

	// const logMeetingEvents = async () => {
	// 	if (meetingEvents.length > 0) {
	// 		try {
	// 			const response = await fetch(requestPath, {
	// 				method: 'POST',
	// 				headers: new Headers(),
	// 				body: JSON.stringify({
	// 					action: 'MEETING_EVENTS',
	// 					meetingEvents: meetingEvents,
	// 					source: `${
	// 						props.preferredName === 'Admin'
	// 							? 'Admin'
	// 							: props.preferredName === 'secondDevice'
	// 							? "Candidate's Second Device"
	// 							: 'Candidate'
	// 					}`,
	// 				}),
	// 			});
	// 			const data = await response.json();
	// 			console.log('Meeting events logs sent:', data);
	// 		} catch (error) {
	// 			console.error('Failed to send meeting events:', error);
	// 		}
	// 		setMeetingEvents([]);
	// 	} else {
	// 		console.log('meetingEvents.length <= 0');
	// 	}
	// };

	// useEffect(() => {
	// 	if (props.interviewEnded) {
	// 		// logMeetingEvents();
	// 		stopMeeting();
	// 	}
	// }, [props.interviewEnded]);

	const clearCaptureTimers = () => {
		if (captureIntervalId.current) {
			clearInterval(captureIntervalId.current);
			captureIntervalId.current = null;
		}
		if (captureTimeoutId.current) {
			clearTimeout(captureTimeoutId.current);
			captureTimeoutId.current = null;
		}

		if (remSecondsIntervalId.current) {
			clearInterval(remSecondsIntervalId.current);
			remSecondsIntervalId.current = null;
		}
	};

	useEffect(() => {
		// stopMeeting();
		// props.isMobile &&
		// 	alert(
		// 		`VideoTiles useEffect called with props:
		// 		JSON.stringify(props) : ${JSON.stringify(props)} props: ${props}`
		// 	);
		// console.log(
		// 	`>>>>>> inside first useEffect of videoTiles : ${JSON.stringify(
		// 		props
		// 	)} `
		// );
		// console.log(`>>>props.micStream:`, props.micStream);
		// console.log(`>>>props.videoStream:`, props.videoStream);
		// console.log(`>>>props.screenStream:`, props.screenStream);

		// // Optionally log specific properties
		// if (props.micStream) {
		// 	console.log(`>>>Mic Stream Active Tracks:`, props.micStream.active);
		// }
		// if (props.videoStream) {
		// 	console.log(
		// 		`>>>Video Stream Active Tracks:`,
		// 		props.videoStream.active
		// 	);
		// }
		// if (props.screenStream) {
		// 	console.log(
		// 		`>>>Screen Stream Active Tracks:`,
		// 		props.screenStream.active
		// 	);
		// }

		// const handleMeetingEnd = async () => {
		// 	// console.log('>>>> inside handleMeetingEnd');
		// 	const interviewReportData = await fetchInterviewReportById(params.interviewReportId);
		// 	if (interviewReportData.meetingId) {
		// 		// console.log(
		// 		// 	`>>> inside handleMeetingEnd with if meetingId exists >>> ${interviewReportData.meetingId} just before stopMeeting`
		// 		// );
		// 		stopMeeting(interviewReportData.meetingId);
		// 		// stopMeeting();
		// 	}
		// };

		if (meetingSession) {
			if (props.preferredName === "Admin" && !props.existingMeetingId) {
				// logMeetingEvents();
				exitMeeting();
				return;
			}
			props.preferredName !== "Admin" && !props.isMobile && share();
			const audioVideo = async (meetingSession) => {
				if (props.micStream && props.videoStream) {
					// console.log('props.mic.vid stream true');
					await meetingSession.audioVideo.startAudioInput(props.micStream);
					await meetingSession.audioVideo.startVideoInput(props.videoStream);
				} else {
					if (props.preferredName !== "Admin") {
						const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
						const videoInputs = await meetingSession.audioVideo.listVideoInputDevices();
						await meetingSession.audioVideo.startAudioInput(audioInputs[0].deviceId);
						await meetingSession.audioVideo.startVideoInput(videoInputs[0].deviceId);
					}
				}
				const observer = {
					videoTileDidUpdate: (tileState) => {
						if (!tileState.boundAttendeeId) {
							return;
						}
						if (!(meetingSession === null)) {
							updateTiles(meetingSession);
						}
					},
					connectionDidBecomePoor: () => {
						console.log("Connection is poor");
						// props.setSnackbarMessage("Your connection is poor. Attempting to reconnect...");
						// props.setSnackbarOpen(true);

						props.clientLogger("Chime Meeting Observer: connectionDidBecomePoor", {
							source: "Chime Meeting Observer",
						});
					},
					connectionDidSuggestStopVideo: () => {
						console.log("Suggested to stop video to maintain the connection");
						props.clientLogger(
							"Chime Meeting Observer: connectionDidSuggestStopVideo. Chime suggested to to stop video to maintain the connection.",
							{
								source: "Chime Meeting Observer",
							},
						);
					},
					connectionDidBecomeGood: () => {
						console.log("Connection is good again");
						// props.setSnackbarMessage("Connection has stabilized.");
						// props.setSnackbarOpen(true);
						props.clientLogger("Chime Meeting Observer: connectionDidBecomeGood", {
							source: "Chime Meeting Observer",
						});
						// reestablishStreams();
					},
				};
				const eventObserver = {
					eventDidReceive: (name, attributes) => {
						const { meetingHistory, ...otherAttributes } = attributes;
						switch (name) {
							case "audioInputFailed":
							case "videoInputFailed":
							case "deviceLabelTriggerFailed":
							case "meetingStartFailed":
							case "meetingFailed":
								// Send the last 5 minutes of events.
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: {
								// 			...otherAttributes,
								// 			MeetingHistory: meetingHistory.filter(({ timestampMs }) => {
								// 				return Date.now() - timestampMs < 5 * 60 * 1000;
								// 			}),
								// 		},
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: {
										OtherAttributes: otherAttributes,
										MeetingHistory: meetingHistory.filter(({ timestampMs }) => {
											return Date.now() - timestampMs < 5 * 60 * 1000;
										}),
									},
								});
								break;
							case "sendingAudioFailed":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								if (!modalAlreadyShown) {
									setShowModal(true);
									setModalAlreadyShown(true);
								}
								break;
							case "signalingDropped":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								if (!modalAlreadyShown) {
									setShowModal(true);
									setModalAlreadyShown(true);
								}
								// attemptReconnection();
								break;
							case "meetingEnded":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	}),
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								cleanup();
								props.setRefresh && props.setRefresh(!props.refresh);
								if (props.preferredName === "secondDevice" && props.isMobile) {
									props.setMeetingEndedForSecondDevice(true);
								}
								break;
							case "meetingStartSucceeded":
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	})
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								// props.clientLogger(
								// 	`meetingStartSucceeded: maxVideoTileCount: ${otherAttributes.maxVideoTileCount}`,
								// );
								break;
							default:
								// meetingEventPOSTLogger?.info(
								// 	JSON.stringify({
								// 		MeetingEvent: name,
								// 		Attributes: otherAttributes,
								// 	})
								// );
								props.clientLogger(`Chime Meeting Event: ${name}`, {
									source: "Chime Meeting Events",
									Attributes: otherAttributes,
								});
								break;
						}
					},
				};
				meetingSession.audioVideo.addObserver(observer);
				meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeeObserver);
				meetingSession.eventController.addObserver(eventObserver);
				const audioOutputElement = document.getElementById("audio");
				meetingSession.audioVideo.bindAudioElement(audioOutputElement);
				//ToDo: Mute the audio output if preferredName is 'secondDevice'. This is to mute all playing audio on secondDevice.
				if (props.preferredName === "secondDevice") {
					audioOutputElement.muted = true;
					meetingSession.audioVideo?.realtimeMuteLocalAudio();
				} else {
					audioOutputElement.muted = false;
				}
				meetingSession.audioVideo.start();
				meetingSession.audioVideo.startLocalVideoTile();
				if (props.preferredName === "Admin") {
					// console.log(
					// 	'inside if preferredName is Admin so mute audio and video with cameraEnabled and micEnabled props: ',
					// 	props.cameraEnabled,
					// 	props.micEnabled
					// );

					if (!props.cameraEnabled) {
						await meetingSession.audioVideo.stopLocalVideoTile();
						setIsVideoOn(false);
					} else {
						const videoInputs = await meetingSession.audioVideo.listVideoInputDevices();

						await meetingSession.audioVideo.startVideoInput(videoInputs[0].deviceId);
						await meetingSession.audioVideo.startLocalVideoTile();
						setIsVideoOn(true);
					}

					if (!props.micEnabled) {
						await meetingSession.audioVideo.realtimeMuteLocalAudio();
						setIsMicOn(false);
					} else {
						const audioInputs = await meetingSession.audioVideo.listAudioInputDevices();
						await meetingSession.audioVideo.startAudioInput(audioInputs[0].deviceId);
						await meetingSession.audioVideo.realtimeUnmuteLocalAudio();
						setIsMicOn(true);
					}
				}
			};
			audioVideo(meetingSession);
			if (
				props.preferredName !== "Admin" &&
				meetingId !== "" &&
				meetingId !== null &&
				meetingId !== "null" &&
				meetingId !== undefined &&
				meetingId !== "undefined"
			) {
				updateMeetingId(meetingId, params.interviewReportId);
				updateMeetingIds(meetingId, params.interviewReportId);
				updateIsLatestMeetingIdInvalid("false", params.interviewReportId);
			}
			if (
				props.preferredName !== "Admin" &&
				concatenationId !== "" &&
				concatenationId !== null &&
				concatenationId !== "null" &&
				concatenationId !== undefined &&
				concatenationId !== "undefined"
			) {
				updateConcatenationId(concatenationId, params.interviewReportId);
			}
			if (props.preferredName === "secondDevice" && props.isMobile) {
				props.setChimeMeetingStartedForSecondDevice(true);
			}
			if (
				props.preferredName === "secondDevice" &&
				props.isSecondaryCameraRequired &&
				!isPositionAccurate
			) {
				try {
					setRemSecondsForSd(10);
					captureTimeoutId.current = setTimeout(() => {
						// captureFrame();
						remSecondsIntervalId.current = setInterval(() => {
							setRemSecondsForSd((prevSeconds) => {
								if (prevSeconds === 0) {
									return 0;
								} else {
									return prevSeconds - 1;
								}
							});
						}, 1000);
						captureIntervalId.current = setInterval(() => {
							captureFrame();
						}, 10000);
					}, 6000);
				} catch (error) {
					console.log(`Error in doMeeting during captureFrame: ${error}`);
				}
			}
		} else {
			if (props.preferredName === "Admin" || props.preferredName === "firstDevice") {
				console.log("inside if admin or first device when no meeting session");
				// if (props.preferredName === 'Admin') {
				// 	console.log('inside if admin when no meeting session');
				props.existingMeetingId &&
					!meetingAlreadyStarted &&
					doMeeting(
						props.preferredName,
						props.existingMeetingId,
						props.isRecordingEnabledRef?.current,
						props.uniqueExtId,
					);
				// } else if (props.preferredName === 'firstDevice') {
				// 	console.log(
				// 		'inside if firstDevice when no meeting session'
				// 	);
				// 	props.existingMeetingId &&
				// 		!meetingAlreadyStarted &&
				// 		doMeeting(
				// 			props.preferredName,
				// 			props.existingMeetingId,
				// 			props.isRecordingEnabled
				// 		);
				// }
			} else {
				!meetingAlreadyStarted &&
					doMeeting(
						props.preferredName,
						props.existingMeetingId,
						props.isRecordingEnabledRef?.current,
					);
			}
		}
	}, [meetingSession, props.existingMeetingId]);

	const MEETING_SERVICE_TEST =
		"https://aa8fcdmb46.execute-api.ap-southeast-1.amazonaws.com/zinterview_test";

	const MEETING_SERVICE = "https://y4w5cjah2f.execute-api.ap-southeast-1.amazonaws.com/zinterview-chime";

	const requestPath = MEETING_SERVICE;

	const logger = new ConsoleLogger("ChimeMeetingLogs", LogLevel.OFF);

	const deviceController = new DefaultDeviceController(logger);

	const doMeeting = async (preferredName, existingMeetingId, isRecordingEnabled, uniqueExtId) => {
		if (preferredName === "Admin") preferredName = uniqueExtId;
		// console.log(
		// 	`all props: preferredName: ${preferredName}, uniqueExtId: ${uniqueExtId}, existingMeetingId: ${existingMeetingId}, isRecordingEnabled: ${isRecordingEnabled}`
		// );

		// if meeting session already exists, return, already in progress
		if (meetingSession) {
			return;
		}
		try {
			let response = await fetch(requestPath, {
				method: "POST",
				headers: new Headers(),
				body: JSON.stringify({
					action: "DO_MEETING",
					MEETING_ID: `${existingMeetingId}`,
					USERNAME: `${preferredName}`,
					MEETING_TOKEN: `${params.interviewReportId}`,
					RECORDING_ENABLED: isRecordingEnabled,
					CANDIDATE_NAME: `${props.candidateName}`,
					OPENING_TITLE: `${props.openingTitle}`,
					PREFERRED_NAME: `${props.preferredName}`,
				}),
			});
			const data = await response.json();

			if (!data.hasOwnProperty("Info")) {
				const message = "Meeting ended or does not exist";
				console.log(`Error in doMeeting, data doesn't have own property INFO: ${message}`);
				return;
			}
			if (data?.Logs) {
				// console.log(`data.Logs: ${JSON.stringify(data.Logs)}`);
				props.clientLogger("Chime Meeting Started/Joined", {
					source: "Chime Meeting Logs - Lambda",
					Logs: data.Logs,
				});
			}
			setConcatenationId(data.Info.concatenatedFileName);
			setMeetingId(data.Info.Meeting.Meeting.MeetingId);
			setAttendeeId(data.Info.Attendee.Attendee.AttendeeId);

			const configuration = new MeetingSessionConfiguration(
				data.Info.Meeting.Meeting,
				data.Info.Attendee.Attendee,
			);
			// await createLogStream(configuration);
			// meetingEventPOSTLogger = getPOSTLogger(
			// 	configuration,
			// 	'meetingEventPOSTLogger',
			// 	requestPath,
			// 	LogLevel.INFO
			// );
			// setMeetingEventPOSTLogger(
			// 	getPOSTLogger(configuration, "meetingEventPOSTLogger", requestPath, LogLevel.INFO),
			// );
			setMeetingSession(new DefaultMeetingSession(configuration, logger, deviceController));
			setMeetingAlreadyStarted(true);
			props.setChimeStarted?.(true);
		} catch (err) {
			console.log(`Error in doMeeting: ${err}`);
		}
	};

	const updateTiles = (meetingSession) => {
		const tiles = meetingSession.audioVideo.getAllVideoTiles();
		tiles.forEach((tile) => {
			let tileId = tile.tileState.tileId;
			let divElement = document.getElementById(`div-${tileId}`);
			let divElementName = document.getElementsByName(`div-${tile.tileState.boundAttendeeId}`)[0];
			if (!divElement && !divElementName) {
				divElement = document.createElement("div");
				divElement.id = `div-${tileId}`;
				divElement.classList.add("video-container");
				divElement.setAttribute("name", `div-${tile.tileState.boundAttendeeId}`);
				divElement.style.display = "inline-block";
				divElement.style.padding = "5px";

				// ToDo: remove LET when it is initialized elsewhere
				let videoElement = document.createElement("video");
				videoElement.id = `video-${tileId}`;
				videoElement.setAttribute("name", `video-${tile.tileState.boundAttendeeId}`);
				videoElement.controls = false;
				// videoElement.setAttribute('controls', false);

				// Disable right-click context menu on the video element
				videoElement.addEventListener("contextmenu", (event) => {
					event.preventDefault();
				});

				// Disable Picture-in-Picture
				videoElement.disablePictureInPicture = true;
				videoElement.setAttribute("disablePictureInPicture", "");

				// if (props.isSecondDevice) {
				// 	videoElement.style.transform = 'rotateZ(90deg)';
				// }

				if (
					props.preferredName !== "Admin" &&
					tile.tileState.boundAttendeeId === attendeeId &&
					tile.tileState.boundAttendeeId.indexOf("content") === -1
				) {
					if (props.isSecondaryCameraRequired) {
						videoElement.setAttribute(props.preferredName, "true");
					} else {
						videoElement.setAttribute("capture-frame", "true");
					}
				}
				// p element if need to display name of attendee
				try {
					divElement.append(videoElement);
				} catch (error) {
					console.log(`Error in divElement.append(videoElement): ${error}`);
				}

				videoElement.style.background = "black";
				videoElement.style.objectFit = "contain";

				if (props.preferredName === "Admin") {
					// if (props.vidH && props.vidW) {
					// 	videoElement.style.height = props.vidH;
					// 	videoElement.style.width = props.vidW;
					// }
					videoElement.style.height = "100%";
					videoElement.style.width = "100%";
					// videoElement.style.objectFit = 'contain';
					// videoElement.style.background = 'black';
					let adminVideoList = document.getElementById("video-list");
					adminVideoList
						? (adminVideoList.id = "admin-video-list")
						: (adminVideoList = document.getElementById("admin-video-list"));
					divElement.classList.add("admin-video-container");
					divElement.style.position = "relative";
					videoElement.addEventListener("loadedmetadata", () => {
						const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
						// Set the height of the container based on the width and the aspect ratio of the video
						divElement.style.width = "100%"; // or any specific width you want
						divElement.style.height = `${divElement.clientWidth / aspectRatio}px`;
					});
					// adminVideoList.appendChild(divElement);
					try {
						adminVideoList.appendChild(divElement);
					} catch (error) {
						console.log(`Error in adminVideoList.appendChild(divElement): ${error}`);
					}
					const fullscreenButton = document.createElement("button");
					fullscreenButton.innerHTML = '<i class="fas fa-expand"></i>';
					fullscreenButton.classList.add("fullscreen-button");

					fullscreenButton.onclick = function () {
						if (!document.fullscreenElement) {
							divElement
								.requestFullscreen()
								.then(() => {
									fullscreenButton.innerHTML = '<i class="fas fa-compress"></i>';
								})
								.catch((err) => {
									console.error(
										`Error attempting to enable full-screen mode: ${err.message}`,
									);
								});
						} else {
							if (document.exitFullscreen) {
								document.exitFullscreen().then(() => {
									fullscreenButton.innerHTML = '<i class="fas fa-expand"></i>';
									// Optionally, revert the button's position/style adjustments here
								});
							}
						}
					};

					// Append the fullscreen icon to the divElement (video container)
					// divElement.append(fullscreenButton);
					try {
						divElement.append(fullscreenButton);
					} catch (error) {
						console.log(`Error in divElement.append(fullscreenButton): ${error}`);
					}
					// const fullscreenIcon = document.createElement('i');
					// fullscreenIcon.className = 'fas fa-expand fullscreen-icon';

					// fullscreenIcon.setAttribute('role', 'button');
					// fullscreenIcon.setAttribute(
					// 	'aria-label',
					// 	'Toggle Fullscreen'
					// ); // Accessibility: Descriptive label for screen readers

					// fullscreenIcon.onclick = function () {
					// 	if (!document.fullscreenElement) {
					// 		divElement
					// 			.requestFullscreen()
					// 			.then(() => {
					// 				fullscreenIcon.className =
					// 					'fas fa-compress fullscreen-icon'; // Change icon to 'compress' when in fullscreen
					// 			})
					// 			.catch((err) => {
					// 				console.error(
					// 					`Error attempting to enable full-screen mode: ${err.message}`
					// 				);
					// 			});
					// 	} else {
					// 		if (document.exitFullscreen) {
					// 			document.exitFullscreen().then(() => {
					// 				fullscreenIcon.className =
					// 					'fas fa-expand fullscreen-icon'; // Change icon back to 'expand' when exiting fullscreen
					// 			});
					// 		}
					// 	}
					// };

					// // Append the fullscreen icon to the divElement (video container)
					// divElement.append(fullscreenIcon);
				}
				// props.preferredName === "Admin"
				// 	? document.getElementById("admin-video-list").append(divElement)
				// 	: document.getElementById("video-list").append(divElement);
				try {
					props.preferredName === "Admin"
						? document.getElementById("admin-video-list").append(divElement)
						: document.getElementById("video-list").append(divElement);
				} catch (error) {
					console.log(`Error in appending divElement to video-list: ${error}`);
				}
				//ToDO: This actually mutes the input from the device and NOT the incoming audio.
				// if (props.preferredName === 'secondDevice') {
				// 	meetingSession.audioVideo.realTimeMuteLocalAudio();
				// }

				meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
			} else {
				// divElementName &&
				// 	console.log(
				// 		`divElementName exists: ${JSON.stringify(
				// 			divElementName
				// 		)}`
				// 	);
				// divElement && console.log(`divElement exists: ${divElement}`);
				let videoElement = document.getElementsByName(`video-${tile.tileState.boundAttendeeId}`)[0];
				meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
				// videoElement &&
				// 	console.log(
				// 		`videoElement exists: ${JSON.stringify(videoElement)}`
				// 	);
			}
		});
	};

	const attendeeObserver = (attendeeId, present, externalUserId, dropped, posInFrame) => {
		//Get Attendee User Name from externalUserId where it was set while joining meeting
		let attendeeUserName = externalUserId.substring(0, externalUserId.indexOf("#"));

		if (present) {
			// attendees.add(attendeeUserName);
			setAttendeesSet((prevSet) => new Set([...prevSet, attendeeUserName]));
		} else {
			// remove attendee's video tile div
			const elements = document.getElementsByName(`div-${attendeeId}`);
			// console.log(
			// 	`{name, right before removing the element because present is false with attendeeUserName: ${attendeeUserName} and attendeeId: ${attendeeId}} and elements[0]: ${elements[0]}`
			// );
			elements[0]?.remove();

			// For screen share attendeeId comes with #content suffix.
			// Do not remove user from attendees if this is screen share closure update
			if (!(attendeeId.indexOf("#content") >= 0)) {
				// attendees.delete(attendeeUserName);
				setAttendeesSet((prevSet) => {
					const newSet = new Set(prevSet);
					newSet.delete(attendeeUserName);
					return newSet;
				});
			}
		}
	};

	// Refresh attendee list in UI view
	// const refreshAttendeesDisplay = () => {};

	const stopMeeting = async (fetchedMeetingId) => {
		const idToUse = fetchedMeetingId || meetingId;
		try {
			let response = await fetch(requestPath, {
				method: "POST",
				headers: new Headers(),
				body: JSON.stringify({
					action: "END_MEETING",
					MEETING_ID: `${idToUse}`,
					CANDIDATE_NAME: `${props.candidateName}`,
					OPENING_TITLE: `${props.openingTitle}`,
					PREFERRED_NAME: `${props.preferredName}`,
					EVENT_NAME: `${props.eventName ? props.eventName : ""}`,
				}),
			});
			const data = await response.json();
			if (data?.Logs) {
				// console.log(`data.Logs: ${JSON.stringify(data.Logs)}`);
				props.clientLogger("Chime Meeting Ended", {
					source: "Chime Meeting Logs - Lambda",
					Logs: data.Logs,
				});
			}
			console.log("NOTE: END MEETING RESPONSE " + JSON.stringify(data));
			updateIsLatestMeetingIdInvalid("true", params.interviewReportId);
			cleanup();
		} catch (err) {
			console.log(`Error in stopMeeting: ${err}`);
		}
	};
	// Leave meeting
	const exitMeeting = async () => {
		try {
			let response = await fetch(requestPath, {
				method: "POST",
				headers: new Headers(),
				body: JSON.stringify({
					action: "DELETE_ATTENDEE",
					MEETING_ID: `${meetingId}`,
					ATTENDEE_ID: `${attendeeId}`,
					CANDIDATE_NAME: `${props.candidateName}`,
					OPENING_TITLE: `${props.openingTitle}`,
					PREFERRED_NAME: `${props.preferredName}`,
				}),
			});
			const data = await response.json();
			if (data?.Logs) {
				// console.log(`data.Logs: ${JSON.stringify(data.Logs)}`);
				props.clientLogger("Chime Meeting Exited", {
					source: "Chime Meeting Logs - Lambda",
					Logs: data.Logs,
				});
			}
			console.log(`NOTE: DELETE ATTENDEE RESPONSE ${JSON.stringify(data)}`);
			cleanup();
		} catch (error) {
			console.log(`Error in exitMeeting: ${error}`);
		}
	};

	// Toggle Screen Share
	const share = async () => {
		try {
			if (meetingSession) {
				if (isScreenShared) {
					await meetingSession.audioVideo.stopContentShare();
					setIsScreenShared(false);
				} else {
					if (props.screenStream) {
						await meetingSession.audioVideo.startContentShare(props.screenStream);
					} else {
						await meetingSession.audioVideo.startContentShareFromScreenCapture();
					}
					setIsScreenShared(true);
				}
			} else {
				const message = "Please start or join a meeting first!";
				console.log(message);
			}
		} catch (err) {
			console.error("Error: " + err);
		}
	};

	const cleanup = () => {
		props.setChimeStarted?.(false);
		try {
			// meetingSession.audioVideo.stopVideoInput();
			// meetingSession.audioVideo.stopAudioInput();
			meetingSession.audioVideo.stop();
		} catch (error) {
			console.log(`Error in cleanup in meetingSession.audioVideo.stop(): ${error}`);
		}
		try {
			meetingSession.deviceController.destroy();
		} catch (error) {
			console.log(`Error in cleanup in meetingSession.deviceController.destroy(): ${error}`);
		}
		setMeetingSession(null);
		// meetingSession = null;
		setMeetingId("");
		document.getElementById("video-list")?.replaceChildren();
		setAttendeesSet(new Set());
		// Clean up the loggers so they don't keep their `onload` listeners around.
		// if (meetingEventPOSTLogger) {
		// 	meetingEventPOSTLogger
		// 		.destroy()
		// 		.then(() => {
		// 			setMeetingEventPOSTLogger(null); // Reset the logger state after destruction
		// 		})
		// 		.catch((error) => {
		// 			console.error("Failed to destroy the logger:", error);
		// 		});
		// }
		// refreshAttendeesDisplay();
	};

	const captureFrame = () => {
		let videoElement;
		if (props.isSecondaryCameraRequired) {
			videoElement = document.querySelector(`video[${props.preferredName}="true"]`);
		} else {
			videoElement = document.querySelector('video[capture-frame="true"]');
		}
		if (!videoElement) {
			console.log("No video element found");
			return;
		}
		const canvas = document.createElement("canvas");
		canvas.width = videoElement.videoWidth;
		canvas.height = videoElement.videoHeight;
		const ctx = canvas.getContext("2d");

		// Flip the image horizontally
		ctx.translate(canvas.width, 0);
		ctx.scale(-1, 1);

		ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

		// Convert canvas to blob
		canvas.toBlob(
			async (blob) => {
				try {
					const gv = await gptVision(blob, params.interviewReportId, new Date().toISOString());
					const verdict = gv?.verdict === "true" || gv?.verdict === true;
					if (verdict) {
						// alert(
						// 	"Position is accurate, Thank you! Please keep your device like this throughout the interview to avoid disruptions.",
						// );
						await updateSecondDevice("true", "pos", params.interviewReportId);
						setIsPositionAccurate(true);
						setRemSecondsForSd(null);
						clearCaptureTimers();
					} else {
						// alert(
						// 	"Position is not accurate, Please make sure your hands are visible and you are facing the laptop while the back of your laptop is visible.",
						// );
						setRemSecondsForSd(10);
					}
				} catch (error) {
					console.log(`Error in gptVision: ${error}`);
					props.clientLogger(`Error in gptVision: ${error}`);
				}
			},
			"image/jpeg",
			0.5,
		); // JPEG format with 50% quality
	};

	return (
		<Box id="container">
			{props.preferredName === "Admin" && (
				<Box className="flex flex-col justify-center items-center">
					{props.candidateName && props.openingTitle && (
						<Box className="flex flex-col justify-center items-center">
							<Box className="flex flex-row justify-center items-center gap-[5px]">
								<Typography variant="subtitle1" className="text-center  font-semibold">
									Candidate's Name:
								</Typography>
								<Typography
									variant="subtitle1"
									className="text-center font-semibold text-blue-600"
								>
									{props.candidateName}
								</Typography>
							</Box>
							<Box className="flex flex-row justify-center items-center gap-[5px]">
								<Typography variant="subtitle1" className="text-center  font-semibold">
									Role:
								</Typography>
								<Typography
									variant="subtitle1"
									className="text-center font-semibold text-blue-600"
								>
									{props.openingTitle}
								</Typography>
							</Box>
						</Box>
					)}
					<Box id="buttons" className="flex justify-center my-2">
						<Tooltip title={isVideoOn ? "Turn off your video" : "Turn on your video"}>
							<Button
								onClick={handleVideo}
								id="video-btn"
								className={`px-4 py-2 mx-1 rounded-lg ${
									isVideoOn ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
								} hover:bg-blue-500 hover:text-white`}
								type="button"
							>
								{isVideoOn ? (
									<VideocamIcon fontSize="small" />
								) : (
									<VideocamOffIcon fontSize="small" />
								)}
							</Button>
						</Tooltip>
						<Tooltip title={isMicOn ? "Turn off your microphone" : "Turn on your microphone"}>
							<Button
								onClick={handleMic}
								id="audio-btn"
								className={`px-4 py-2 mx-1 rounded-lg ${
									isMicOn ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
								} hover:bg-blue-500 hover:text-white`}
								type="button"
							>
								{isMicOn ? <MicIcon fontSize="small" /> : <MicOffIcon fontSize="small" />}
							</Button>
						</Tooltip>
						<Tooltip title={isScreenShared ? "Stop Screen Share" : "Start Screen Share"}>
							<Button
								onClick={share}
								id="share-btn"
								className={`px-4 py-2 mx-1 rounded-lg ${
									isScreenShared ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
								} hover:bg-blue-500 hover:text-white`}
								type="button"
							>
								{isScreenShared ? (
									<StopScreenShareIcon fontSize="small" />
								) : (
									<ScreenShareIcon fontSize="small" />
								)}
							</Button>
						</Tooltip>
						<Tooltip
							title={
								isTabAudioMuted
									? "Unmute all audio from this tab. This refers to audio coming from other attendees and the candidate."
									: "Mute all audio from this tab. This refers to audio coming from other attendees and the candidate."
							}
						>
							<Button
								onClick={toggleMute}
								id="mute-btn"
								className={`px-4 py-2 mx-1 rounded-lg ${
									isTabAudioMuted ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-800"
								} hover:bg-blue-500 hover:text-white`}
								type="button"
							>
								{isTabAudioMuted ? (
									<VolumeOffIcon fontSize="small" />
								) : (
									<VolumeUpIcon fontSize="small" />
								)}
							</Button>
						</Tooltip>
					</Box>
				</Box>
			)}
			{props.isMobile && props.isSecondaryCameraRequired && props.preferredName === "secondDevice" && (
				<Box className="flex flex-col justify-center items-center gap-2">
					{isPositionAccurate ? (
						<Typography variant="subtitle1" className="text-center font-semibold">
							Position is accurate, Thank you! Please keep your device like this throughout the
							interview to avoid disruptions.
						</Typography>
					) : (
						<Typography variant="subtitle1" className="text-center font-semibold">
							Position is not accurate. Ensure that you are facing your laptop and your hands
							and the back of the laptop are visible.
						</Typography>
					)}
					{remSecondsForSd === 0 ? (
						<Typography variant="subtitle2" className="text-center font-semibold">
							Checking...{<i id="" className="fas fa-circle-notch fa-spin"></i>}
						</Typography>
					) : (
						remSecondsForSd > 0 && (
							<Typography variant="subtitle2" className="text-center font-semibold">
								Checking your device position in {remSecondsForSd} seconds
							</Typography>
						)
					)}
				</Box>
			)}
			<audio id="audio"></audio>
			<Box id="video-list"></Box>
			{props.preferredName !== "Admin" && showModal && (
				<NetworkModal showModal={showModal} setShowModal={setShowModal} />
			)}
		</Box>
	);
};

export default VideoTiles;
