import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	openingDetails: {},
	selectedOpening: {},
};

const createOpeningSlice = createSlice({
	name: "opening",
	initialState,
	reducers: {
		createOpeningAction: (state, action) => {
			state.openingDetails = action.payload;
		},
		resetOpeningDetails: (state, action) => {
			return initialState;
		},
		setSelectedOpening: (state, action) => {
			state.selectedOpening = action.payload;
		},
	},
});

export const createOpeningSelector = (state) => state.openingDetails.openingDetails;

const createOpeningReducer = createOpeningSlice.reducer;
export default createOpeningReducer;
export const { createOpeningAction, resetOpeningDetails, setSelectedOpening } = createOpeningSlice.actions;
