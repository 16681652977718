import React, { useRef } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { nanoid } from "@reduxjs/toolkit";
import { availableColors, availableCustomOptions } from "../../utilities/constants";
import CustomFieldType from "./CustomFieldTypes";
import OpeningButton from "./Button";

const CustomFields = () => {
	const {
		control,
		register,
		formState: { errors },
		watch,
	} = useFormContext();
	const {
		fields: customFields,
		append,
		remove,
	} = useFieldArray({
		control: control,
		name: "customFields",
	});

	const newCustomFieldType = useRef(null);

	function addNewType(type) {
		if (type === 0) {
			append({
				type: 0,
				fieldName: "",
				config: {
					defaultValue: "",
				},
			});
		} else if (type === 1) {
			const dropDownValueId = nanoid();
			append({
				type: 1,
				fieldName: "",
				config: {
					defaultValue: "",
					dropDownValues: [
						{
							id: dropDownValueId,
							value: "",
							color: availableColors[0],
						},
					],
				},
			});
		}
	}

	return (
		<>
			<div className="mb-6 ">
				<div className="flex flex-col gap-3 rounded-lg bg-blue-50 p-4 shadow-md">
					<h3 className=" text-lg font-semibold">Custom Fields</h3>

					<div className="flex flex-col gap-3">
						{customFields.map((field, customFieldIndex) => {
							const { id } = field;
							return (
								<CustomFieldType
									key={id}
									field={field}
									errors={errors}
									register={register}
									remove={remove}
									watch={watch}
									control={control}
									customFieldIndex={customFieldIndex}
								/>
							);
						})}
					</div>
					<div className="flex flex-row gap-2 items-center">
						<label
							htmlFor={`customFieldType`}
							className="block text-sm font-medium text-gray-600"
						>
							Add Type
						</label>
						<select id={`customFieldType`} className="p-1" ref={newCustomFieldType}>
							{Object.entries(availableCustomOptions).map(([key, value]) => {
								return (
									<option key={key} value={key}>
										{value}
									</option>
								);
							})}
						</select>
						<OpeningButton
							text="Add Field"
							handleClick={() => {
								try {
									const newTypeToAdd = +newCustomFieldType.current?.value;
									addNewType(newTypeToAdd);
								} catch (e) {
									console.log("Error while adding a new type");
									console.log(e);
								}
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomFields;

const VerticalDivider = () => {
	return <span className="h-8 w-[2px] bg-slate-300"></span>;
};
