import { SELECT_ALL_CANDIDATE, SELECT_CANDIDATE, UNSELECT_ALL_CANDIDATE, UNSELECT_CANDIDATE } from "../actions/types";

const initialState = {
    selectedCandidate: [],
    count: 0,
};

export default function candidatesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SELECT_CANDIDATE:
            return {
                ...state,
                selectedCandidate: [...state.selectedCandidate, payload],
                count: state.count + 1,
            };
        case UNSELECT_CANDIDATE:
            return {
                ...state,
                selectedCandidate: state.selectedCandidate.filter(
                    (candidate) => candidate !== payload
                ),
                count: state.count - 1,
            };
        case SELECT_ALL_CANDIDATE:
            return {
                ...state,
                selectedCandidate: payload,
                count: payload.length
            };
        case UNSELECT_ALL_CANDIDATE:
            return {
                ...state,
                selectedCandidate: [],
                count: 0,
            };
        default:
            return state;
    }
}