import axios from "axios"
import { toast } from "react-toastify";

const createTrigger = async (body = {}, config = {}, params = {}) => {
    try {
        let response = await axios.post("/api/triggers/add-trigger", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const getTriggers = async (body = {}, config = {}, params = {}) => {
    try {
        let response = await axios.post("/api/triggers/get-triggers", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const getTrigger = async (id) => { }

const updateTrigger = async (body = {}, config = {}, params = {}) => {
    try {
        let response = await axios.post("/api/triggers/update-trigger", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const deleteTrigger = async (body = {}, config = {}, params = {}) => {
    try {
        let response = await axios.post("/api/triggers/delete-trigger", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const pauseTrigger = async (body = {}, config = {}, params = {}) => {
    try {
        let response = await axios.post("/api/triggers/pause-trigger", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const getEmailPreview = async (body = {}, config = {}, params = {}) => {
    try {
        let response = await axios.post("/api/triggers/get-email-preview", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const getEventsAndActions = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/events/get-events-and-actions", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const getCustomEventsAndActions = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/events/get-custom-events-and-actions", body, config)
        return response
    } catch (error) {
        throw new Error("Internal Server Error")
    }
}

const saveCustomEvent = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/events/add-custom-event", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

const updateCustomEvent = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/events/update-custom-event", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

const deleteCustomEvent = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/events/delete-custom-event", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

// CRUD from custom triggers

const getCustomTriggers = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/triggers/get-custom-triggers", body, config)
        return response
    } catch (error) {
        console.log(error)
        throw new Error("Internal Server Error")
    }
}

const saveCustomTrigger = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/triggers/add-custom-trigger", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

const updateCustomTrigger = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/triggers/update-custom-trigger", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

const deleteCustomTrigger = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/triggers/delete-custom-trigger", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

const executeCustomEvent = async (
    body = {}, config = {}, params = {}
) => {
    try {
        let response = await axios.post("/api/events/execute-event", body, config)
        return response
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response.data.message)
        }
        throw new Error("Internal Server Error")
    }
}

export {
    createTrigger,
    getTriggers,
    getTrigger,
    updateTrigger,
    deleteTrigger,
    pauseTrigger,
    getEmailPreview,
    getEventsAndActions,
    getCustomEventsAndActions,
    saveCustomEvent,
    updateCustomEvent,
    deleteCustomEvent,
    getCustomTriggers,
    saveCustomTrigger,
    updateCustomTrigger,
    deleteCustomTrigger,
    executeCustomEvent
};