import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/zinterview-logo-white.png";
import SideDrawer from "../pages/SuperAdmin/SideDrawer";
import "./NavbarStyles.css";

function Navbar() {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	return (
		<div>
			<Box
				className="fixed top-0 w-full bg-blue-500 p-4 flex justify-between items-center"
				id="nav-bar"
				sx={{ zIndex: "1000" }}
			>
				<Box className="flex items-center w-full">
					<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
						<MenuIcon color="white" />
					</IconButton>
					<div className="relative flex items-center justify-center w-full h-full flex-1">
						<img src={logo} alt="Zinterview Logo" className="w-44 absolute left-0" />
					</div>
				</Box>
				<SideDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
			</Box>
		</div>
	);
}

export default Navbar;
